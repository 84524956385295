<template>
  <Teleport to="#popup-target">
    <AlertInvalidNetwork
      v-if="doLoadContract && web3Api.isProviderLoaded && !canConnectToContract"
      @change-network="changeNetwork"
    />
  </Teleport>

  <b-container
    :toast="{ root: true }"
    fluid="sm"
    position="position-fixed"
  ></b-container>

  <div class="network-container" v-if="!hideNetwork">
    <div class="network-icon">
      <img
        src="~@/assets/image/ico/icon-ether-white.svg"
        alt="ehtereum logo"
        class="ethereum-logo"
      />
    </div>
    <span> {{ getChainNameById(chainId) }} </span>
  </div>

  <div v-if="web3Api.isProviderLoaded">
    <div v-if="!web3Api.provider" class="no-wallet">
      Wallet is not detected!
      <a href="https://metamask.io/download/" target="_blank">
        Install Metamask
      </a>
    </div>

    <div v-else-if="!address" class="connect-container">
      <slot name="connect" :onConnect="onClickConnect"> </slot>
    </div>
  </div>
  <div v-else class="looking-web3">Looking for Web3...</div>

  <div
    v-if="web3Store.address"
    type="button"
    :class="['wallet-address', props.iconSmall && 'icon-small']"
    @click="onClickAddress"
  >
    <Jazzicon
      :address="web3Store.address"
      :diameter="props.iconSmall ? 20 : 28"
    />{{ web3Store.shortenedAddress }}
  </div>
</template>

<script setup>
import {
  watch,
  watchEffect,
  reactive,
  ref,
  computed,
  onBeforeUnmount,
} from "vue";
import Web3 from "web3";
import { storeToRefs } from "pinia";
import { useWeb3Store } from "@/store/web3Store";
import detectEthereumProvider from "@metamask/detect-provider";
import { loadContract } from "@/utils/loadContract";
import AlertInvalidNetwork from "@/components/web3/AlertInvalidNetwork.vue";
import getChainNameById from "@/utils/chainIdNameMapper.js";
import { useClipboard } from "@vueuse/core";
import { useToast } from "vue-toastification";

const toast = useToast();

const props = defineProps({
  doLoadContract: {
    type: Boolean,
    default: true,
  },
  hideNetwork: {
    type: Boolean,
    default: false,
  },
  iconSmall: {
    type: Boolean,
    default: false,
  },
  contractAddress: {
    type: String,
    required: true,
  },
});

const balance = ref(null);
const chainId = ref("");

const web3Store = useWeb3Store();

const { address } = storeToRefs(web3Store);
const { text, copy, copied, isSupported } = useClipboard({
  source: address,
});

const canConnectToContract = computed(() => {
  return !!(address && web3Api.contract);
});

const isNetworkCorrect = computed(() => {
  return process.env.VUE_APP_TARGET_NETWORK_CHANNEL_ID === chainId.value;
});

const web3Api = reactive({
  provider: null,
  isProviderLoaded: false,
  web3: null,
  contract: null,
});

const setAccountListener = (provider) => {
  provider.on("accountsChanged", (_) => {
    window.location.reload();
  });
  provider.on("chainChanged", (_) => {
    window.location.reload();
  });
};

const onClickConnect = web3Store.onClickConnect;

const changeNetwork = async () => {
  if (web3Api.provider) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: Web3.utils.toHex(
              process.env.VUE_APP_TARGET_NETWORK_CHANNEL_ID
            ),
          },
        ],
      });
    } catch {}
  }
};

const stopWatchingProvider = watchEffect(() => {
  const loadProvider = async () => {
    const provider = await detectEthereumProvider();

    if (provider) {
      setAccountListener(provider);

      web3Api.web3 = new Web3(provider);
      const chainIdInDecimal = await web3Api.web3.eth.net.getId();

      chainId.value =
        "0x" + Math.abs(Number.parseInt(chainIdInDecimal)).toString(16);

      web3Api.provider = provider;
      try {
        const contract = await loadContract(
          "CanvasN",
          provider,
          props.contractAddress
        );
        web3Api.contract = contract;
      } catch {}
      web3Api.isProviderLoaded = true;

      web3Store.setWeb3Api(web3Api);
    } else {
      web3Api.isProviderLoaded = true;
      web3Store.setWeb3Api(web3Api);
      console.error("Please, install metamask");
    }
  };

  if (props.contractAddress === undefined) {
    loadProvider();
  } else if (props.contractAddress) loadProvider();
});

/* const stopWatchingContract2 = watchEffect(() => {
  const getNumberOfNFTUserOwns = async () => {
    const { contract } = web3Api;

    if (address.value) {
      const result = await contract.balanceOf(address.value);
      web3Store.setNumberOfMyOwnNfts(result.toNumber());
    }
  };

  web3Api.contract && getNumberOfNFTUserOwns();
}); */

const stopWatchingContract = watchEffect(() => {
  const getContractInfo = async () => {
    await web3Store.refreshContractInfo();
  };

  web3Api.contract && getContractInfo();
});

const stopWatchingWeb3 = watchEffect(() => {
  const getAccount = async () => {
    const accounts = await web3Api.web3.eth.getAccounts();
    web3Store.setAddress(accounts[0]);
    web3Store.isChecked = true;

    if (accounts[0]) {
      const balance = await web3Api.web3.eth.getBalance(accounts[0]);
      web3Store.setBalance(Number.parseInt(balance));
    }
  };

  web3Api.web3 && getAccount();
});

watchEffect(() => {
  if (web3Api.provider) {
    stopWatchingProvider();
  }

  if (web3Store.address) {
    stopWatchingWeb3();
  }
});

const onClickAddress = () => {
  copy();
  toast.info(`${web3Store.address}\n를 클립보드로 복사하였습니다.`);
  // toast.show(
  //   { body: `${web3Store.address}를 클립보드로 복사하였습니다` },
  //   { pos: "top-center", variant: "success" }
  // );
};
</script>

<style lang="scss" scoped>
.btn-connect {
  margin: 0 10px;
  width: 135px;
  height: 35px;
  overflow: Hidden;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #28e3d1 url("@/assets/image/bg/bg-connect.png") no-repeat left
    center;
  border: 0;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  transition: all 0.5s;
}
.btn-connect span {
  width: 135px;
  height: 35px;
  overflow: Hidden;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  background: transparent;
  transition: all 0.5s;
}
.btn-connect:hover span {
  background: #28e3d1;
}

.network-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 3px solid #fff;
  text-align: center;
  color: #fff;
  padding: 10px 20px;
  margin-left: 15px;
  margin-right: 15px;
}

.connect-container {
  margin-right: 15px;
}

.no-wallet {
  color: #fff;
}

.looking-web3 {
  color: #fff;
}
.ethereum-logo {
  width: 12px;
  margin-right: 8px;
}

.wallet-address {
  border-radius: 50px;
  border: 3px solid #ffffff;
  text-align: center;
  color: #ffffff;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
}

.icon-small {
  padding: 4px 8px;
  margin-left: 10px;
}
</style>

/**
 * 로그인 아이디 저장
 */
export const storageIdInit = (isChecked, email) => {
  deleteId()
  if (isChecked) {
    saveId(email)
  }
}
export const saveId = (id) => {
  localStorage.setItem('ID', id)
}

export const deleteId = () => {
  localStorage.removeItem('ID')
}

export const getId = () => {
  return localStorage.getItem('ID')
}

export const getAddress = () => {
  return sessionStorage.getItem('ADDRESS')
}

export const changeUnixTimestamp = (timestamp) => {
  const date = new Date(timestamp)

  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const time = date.getDate().toString().padStart(2, 0)

  return `${year}-${month}-${time}`
}

const nftRoutes = [
    {
      path: 'nfts/catalogue',
      name: 'Catalogue',
      component: /* webpackChunkName: "login" */ () =>
        import('@/views/nfts/CatalogueView'),
    },
    {
      path: 'nfts/celebrity',
      name: 'Celebrity',
      component: /* webpackChunkName: "join" */ () =>
        import('@/views/nfts/CelebrityView'),
    },
    // {
    //   path: 'nfts/collection',
    //   name: 'Collection',
    //   component: /* webpackChunkName: "findId" */ () =>
    //     import('@/views/nfts/CollectionView'),
    // },
    {
      path: 'nfts/introduceteam',
      name: 'IntroduceTeam',
      component: /* webpackChunkName: "findPassword" */ () =>
        import('@/views/nfts/IntroduceTeamView'),
    },
  ];
  
  export default nftRoutes;
  
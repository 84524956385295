<template>
  <div id="pop-webt01" class="dialog type01">
    <div class="pop-item-wrap webt-type">
      <button
        type="button"
        class="btn-pop-close"
        onclick="common.popClose('#pop-webt01')"
      >
        <img src="@/assets/image/ico/ico-pop-close.svg" />
      </button>
      <div class="webt-page-wrap">
        <div class="ptit">‘새로운 별#1 : 엔터네이너 지수’</div>
        <div class="webt-scrolls-wrap">
          <img src="@/assets/image/webtoon/01.jpg" />
        </div>
        <router-link :to="{ name: 'FullStory' }">
          <button>풀스토리 보러가기 →</button>
        </router-link>
        <div class="tip">
          <img src="@/assets/image/ico/ico-mouse.svg" />
          <span>스크롤을 내리며 <br />스토리를 감상하세요!</span>
        </div>
      </div>
    </div>
  </div>
  <div id="pop-webt02" class="dialog type01">
    <div class="pop-item-wrap webt-type">
      <button
        type="button"
        class="btn-pop-close"
        onclick="common.popClose('#pop-webt02')"
      >
        <img src="@/assets/image/ico/ico-pop-close.svg" />
      </button>
      <div class="webt-page-wrap">
        <div class="ptit">‘탐사#2 : 힐러 탄’</div>
        <div class="webt-scrolls-wrap">
          <img src="@/assets/image/webtoon/02.jpg" />
        </div>
        <router-link :to="{ name: 'FullStory' }">
          <button>풀스토리 보러가기 →</button>
        </router-link>
      </div>
    </div>
  </div>
  <div id="pop-webt03" class="dialog type01">
    <div class="pop-item-wrap webt-type">
      <button
        type="button"
        class="btn-pop-close"
        onclick="common.popClose('#pop-webt03')"
      >
        <img src="@/assets/image/ico/ico-pop-close.svg" />
      </button>
      <div class="webt-page-wrap">
        <div class="ptit">‘씨앗#3 : 이노베이터 스티브’</div>
        <div class="webt-scrolls-wrap">
          <img src="@/assets/image/webtoon/03.jpg" />
        </div>
        <router-link :to="{ name: 'FullStory' }">
          <button>풀스토리 보러가기 →</button>
        </router-link>
      </div>
    </div>
  </div>
  <div id="pop-webt04" class="dialog type01">
    <div class="pop-item-wrap webt-type">
      <button
        type="button"
        class="btn-pop-close"
        onclick="common.popClose('#pop-webt04')"
      >
        <img src="@/assets/image/ico/ico-pop-close.svg" />
      </button>
      <div class="webt-page-wrap">
        <div class="ptit">‘작품#4 : 크리에이터’</div>
        <div class="webt-scrolls-wrap">
          <img src="@/assets/image/webtoon/04.jpg" />
        </div>
        <router-link :to="{ name: 'FullStory' }">
          <button>풀스토리 보러가기 →</button>
        </router-link>
      </div>
    </div>
  </div>
  <div id="pop-webt05" class="dialog type01">
    <div class="pop-item-wrap webt-type">
      <button
        type="button"
        class="btn-pop-close"
        onclick="common.popClose('#pop-webt05')"
      >
        <img src="@/assets/image/ico/ico-pop-close.svg" />
      </button>
      <div class="webt-page-wrap">
        <div class="ptit">‘통신#5 : 아티스트 앤디’</div>
        <div class="webt-scrolls-wrap">
          <img src="@/assets/image/webtoon/05.jpg" />
        </div>
        <router-link :to="{ name: 'FullStory' }">
          <button>풀스토리 보러가기 →</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.pop-item-wrap .webt-page-wrap .webt-scrolls-wrap {
  height: 70vh;
}
</style>
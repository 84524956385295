const getChainNameById = (chainId) => {
  switch (chainId) {
    case '0x1':
      return 'Ehtereum'
    case '0x4':
      return 'Rinkeby'
    case '0x5':
      return 'Goerli'
    case '0x1691':
      return 'Ganache'
    default:
      return 'Unknown channel name'
  }
}

export default getChainNameById

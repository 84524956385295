import { changeUnixTimestamp } from '@/utils/storage'
import { defineStore } from 'pinia'

/**
 * todo : 로그인 상태 관리에 대해서 더 고민 해봐야 함
 */
export const useAuthStore = defineStore('auth', {
  state: () => ({
    EMAIL_ADDR: null, // 알림 받을 이메일 주소
    MEMBER_SEQ: null, // 사용자 고유 번호
    REG_NM: null, // DB 상 등록한 사람의 Login ID
    REG_DTM: null,
    MMB_NM: null, // 사용자 이름
    ACCESS_TOKEN: null, // 액세스 토큰
    REFRESH_TOKEN: null, // 갱신 토큰
    avatarImageUrl: null,
    processing : false // api 통신 요청중 인가
  }),
  getters: {
    isLoggedIn(state) {
      return !!state.EMAIL_ADDR && !!state.MEMBER_SEQ
    },
    userRegDtm(state) {
      return state.REG_DTM
    },
    getAccessToken(state) {
      return state.ACCESS_TOKEN
    },
    getRefreshToken(state) {
      return state.REFRESH_TOKEN
    },
  },
  actions: {
    setUserAuth(userData) {
      this.EMAIL_ADDR = userData.EML_ADDR
      this.MEMBER_SEQ = userData.MEMBER_SEQ
      this.REG_NM = userData.REG_NM
      this.REG_DTM = changeUnixTimestamp(userData.REG_DTM)
      this.MMB_NM = userData.MMB_NM
      this.ACCESS_TOKEN = userData.ACCESS_TOKEN
      this.REFRESH_TOKEN = userData.REFRESH_TOKEN
    },
    removeUserAuth() {
      this.EMAIL_ADDR = null
      this.MEMBER_SEQ = null
      this.REG_NM = null
      this.REG_DTM = null
      this.MMB_NM = null
      this.ACCESS_TOKEN = null
      this.REFRESH_TOKEN = null
      sessionStorage.clear()
    },
    setAvatarImageUrl(url) {
      this.avatarImageUrl = url
    },
  },
  persist: {
    enabled: true,
  },
})

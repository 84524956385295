const staticFullStoryData_1 = {
		id : 1,
		title: "밀코메다-밀코메다N",
		body: `<div class=WordSection1>

<p class=MsoNormal><span lang=EN-US style='line-height:107%;color:white'><img
width=567 height=401 id="그림 12" src="/image/story1/image001.jpg"
alt="텍스트이(가) 표시된 사진&#10;&#10;자동 생성된 설명"></span></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:160%;vertical-align:baseline;word-break:keep-all'><b><span
lang=EN-US style='font-size:12.0pt;line-height:160%;color:white'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:160%;vertical-align:baseline;word-break:keep-all'><b><span
lang=EN-US style='font-size:12.0pt;line-height:160%;color:white'>&nbsp;</span></b></p>

<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
line-height:160%;vertical-align:baseline;word-break:keep-all'><span
lang=EN-US style='font-size:12.0pt;line-height:160%;color:white;font-weight:bold'>1화. 밀코메다-밀코메다N</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>2500</span><span
style='line-height:160%;color:white'>년</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>현재의 지구는 황홀할
정도로 아름답다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>과거의 사람들은 지구의 수명이 얼마 가지 못할 거라고
했고</span><span lang=EN-US style='line-height:160%;color:white'>,</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>실제로 환경이 돌이킬 수 없을 정도로 파괴된 적도
있다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>국지적 전쟁과 테러가 끊이지 않았으며</span><span
lang=EN-US style='line-height:160%;color:white'>, 2~3</span><span
style='line-height:160%;color:white'>년마다 새로운 전염병이 창궐했고</span><span lang=EN-US
style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>빈부의 갈등은 격화되어 비참한 삶을 이어갔으며</span><span
lang=EN-US style='line-height:160%;color:white'>,</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>곳곳에서 서로를 증오하는 감정이 이중 삼중으로 지구를
뒤덮었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'><img width=272
height=193 id="그림 11" src="/image/story1/image002.jpg"
alt="텍스트, 도로이(가) 표시된 사진&#10;&#10;자동 생성된 설명"></span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>곪을 대로 곪은 지구와 병들어가는 인류</span><span
lang=EN-US style='line-height:160%;color:white'>... </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>종말을 지켜보고만 있을 수 없던 거버넌스는 방법을
강구해야만 했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>비록 겉모습뿐일지라도</span><span
lang=EN-US style='line-height:160%;color:white'>.. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>은행</span><span lang=EN-US
style='line-height:160%;color:white'>, IT, </span><span style='line-height:
160%;color:white'>무기 산업 등으로 막대한 자본을 벌어들였던 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>거버넌스는 썩어 가는 지구 위에 철제와 시멘트를
쌓고</span><span lang=EN-US style='line-height:160%;color:white'>,</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>최첨단 기술을 집약하여 환상적으로 아름다운 모습을
다시금 구현해냈다</span><span lang=EN-US style='line-height:160%;color:white'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'><img width=267
height=189 id="그림 10" src="/image/story1/image003.jpg"></span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>천문학적인 자금과 노력이 들어간 세상에 사람들은
홀리듯이 빠져들었고</span><span lang=EN-US style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>어두운 현실은 뒤로한 채</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>안정적인 생활이 이어가는 듯 했다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 이는 인류의 심리를 안정시키는 것을 중심으로
한 임시방편일 뿐</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>거버넌스는 더 나은 방법을 마련하기 위해 남몰래
끊임없이 노력했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그리고 마침내 우리 은하와 안드로메다 은하가 충돌하여
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>생성될 새로운 은하를 발견하기에 이른다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>밀코메다 은하에 있는 수만 개의 각기 다른 행성은
지구와 비슷한 부분이 많았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>만일 다른 행성으로 인류가 이주한다면 이보다 더
좋을 수는 없을 정도로</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>거버넌스는 더 이상 고민하지 않고 낯선 행성의 탐사를
위해</span><span lang=EN-US style='line-height:160%;color:white'>,</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>1</span><span
style='line-height:160%;color:white'>만 명의 인류를 선발할 크립토 공고문을 내었으며 </span><span
lang=EN-US style='line-height:160%;color:white'>1</span><span style='line-height:
160%;color:white'>만 명의 선발대를 꾸렸다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>[</span><span
style='line-height:160%;color:white'>새로운 세상을 만들어갈 자질과 능력을 갖춘 예술가를 모집합니다</span><span
lang=EN-US style='line-height:160%;color:white'>!] </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>예술가라는 직업을 선택한 이유는 홀로 낯선 행성에서
적응하며 살아가기 위해서는 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>작품을 창작하는 예술 활동이 가장 좋을 거라는 행동
연구 결과가 있었기 때문이다</span><span lang=EN-US style='line-height:160%;color:white'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>또한 피드백 수용이 능한 사람을 우선으로 선발한
이유는</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>거버넌스 본부와의 소통을 원활하게 하기 위함이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>본 프로젝트는 이제껏 상상해보지도 못한 크기의 도전이기에
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>무엇 하나 허투루 할 수 없었다</span><span
lang=EN-US style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그렇게 선발된 밀코메다</span><span
lang=EN-US style='line-height:160%;color:white'>N</span><span style='line-height:
160%;color:white'>은 거버넌스의 연구실에서 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>우주학</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>창조력 테스트</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>생존 훈련까지 철저한 관리를 받았고</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>5</span><span
style='line-height:160%;color:white'>년이 지난 지금</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>드디어 인류 이주 프로젝트를 위해 블루버진 스페이스호에 탑승한다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'><img width=267
height=189 id="그림 9" src="/image/story1/image004.jpg"></span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>인류를 구하기 위해 더 넓은 우주로 나아가라</span><span
lang=EN-US style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이번에야말로 지구와 같은 실수를 반복하지 않을 것이다</span><span
lang=EN-US style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>사랑하는 지구와 인류를 위해서</span><span
lang=EN-US style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지나온 시간을 바꿀 수는 없지만</span><span
lang=EN-US style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>현재의 가능성은 과거로 돌아가 기적을 만들어낼 것이니</span><span
lang=EN-US style='line-height:160%;color:white'>... </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>`
}
export default staticFullStoryData_1

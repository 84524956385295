const staticFullStoryData_6 = 		{
		id : 6,
		title: "힐러-탄 (60일차)",
		body: `<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white;font-weight:bold'>6화 #. 힐러 – 탄 (60일 차)</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탄은 오두막집 근처에 자리를 잡고 심어 놓은 벼를
베었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지구였다면 몇 달은 걸렸겠지만</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>아레테 행성의 일조량은 지구의 두 배 이상이라 곡물이 자라는 속도가 남달랐다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이 행성에서 따로 얻은 씨앗이기에 가능했던 일이
아니라</span><span lang=EN-US style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지구의 네팔에서 가져온 씨앗이었지만 아레테 행성의
일조량이 남달라서 가능했던 거였다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>씨앗은 환경 운동가로 활동하던 시기에 네팔에서 받았던
정제된 씨앗으로 조금 특이하긴 하지만 지구에서 난 씨앗임은 틀림없었다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이 정제된 씨앗은 탄이 아끼고 아끼던 보물 중 하나였는데</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아레테 행성에 오자마자 이 행성에 심으려고 그동안
아껴왔다는 것을 탄은 단번에 깨달았다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>한눈에 봐도 반할 만큼 황홀했지만</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>탐사 기간에 주위를 둘러본 행성의 모습은 말로 표현할 수 없었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>넓은 바다와 아름다운 해변이 가득한 모습은 지구의
하와이나 사모이섬을 생각나게 했으며</span><span lang=EN-US style='line-height:160%;color:white'>,
</span><span style='line-height:160%;color:white'>자연이 어우러져 있는 행성을 보면 한국어로 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>덕분에</span><span lang=EN-US style='line-height:160%;
color:white'>’</span><span style='line-height:160%;color:white'>라는 단어가 떠올랐기에 고대어로
</span><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>덕</span><span lang=EN-US style='line-height:
160%;color:white'>’</span><span style='line-height:160%;color:white'>이라는 뜻의 아레테라
불렀다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아직은 캡슐 근처의 땅만 농사가 가능한 걸 확인하였지만
점차 범위를 넓혀가 볼 예정이다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span><span style='line-height:160%;color:white'>예로부터 어떤 지역이든 완벽한 정착을 결론짓는 건 자급자족이
가능한 지에 달렸으니까</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이건 행성을 탐사하기 위한 시작이자</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>과정이자</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>가장 중요한 단계였기에 탄은 벌써 기대감이 부풀어 올라 기쁨을 주체할 수 없었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그때 비가 조금씩 내리며 음률이 점점 커졌다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이제는 익숙한 듯 소리를 알아챈 탄은 지금까지 수확한
벼만 들고 서둘러 캡슐로 향했다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>물론 비가 내릴 때 부풀어 오르는 거대한 나무 개체로부터
보호받을 수 있도록 반경을 지정해서 농사를 지은 것이었지만</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>데이터가 더 쌓이기 전까진 최대한 조심하는 것이
좋았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>다행히 탄이 캡슐이 도착해서 문을 닫자 우수수</span><span
lang=EN-US style='line-height:160%;color:white'>- </span><span
style='line-height:160%;color:white'>쏟아지는 빗소리가 들렸다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>한시름 놓은 탄은 테이블 위에 벼를 올려놓고 주위를
둘러보았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러자 어느새 돌아온 로봇 새가 벽난로를 앞에서
날개를 부르르 떨고 있는 게 보였다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>자기가 진짜 새인 줄 아나</span><span lang=EN-US
style='line-height:160%;color:white'>...’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>로봇 새의 귀여운 행동에 탄은 작게 웃음을 지으며
바라보다가 냉장고 문을 열었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>며칠 전에 먼저 수확한 벼로 시범 삼아 만들어놓은
막걸리가 지금 딱 알맞게 발효되었을 테니</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>오늘 뚜껑을 열어볼까 한다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>밤이 길 예정이었기에 시간을 달래기 딱 좋은 친구였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>밀코메다</span><span
lang=EN-US style='line-height:160%;color:white'>N</span><span style='line-height:
160%;color:white'>에게는 행성에 도착한 날짜별로 해야만 하는 매뉴얼이 몇 개 존재했는데 </span><span
lang=EN-US style='line-height:160%;color:white'>50</span><span
style='line-height:160%;color:white'>일에는 씨앗을 심어보는 것이고 </span><span lang=EN-US
style='line-height:160%;color:white'>75</span><span style='line-height:160%;
color:white'>일에는 각자 작품을 만드는 일이었다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지금은 </span><span
lang=EN-US style='line-height:160%;color:white'>60</span><span
style='line-height:160%;color:white'>일 차로 그 중간</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>심어야 하는 씨앗은 벼를 심었던 곳 옆에 자리를
마련해 성장을 기다리는 중이고</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>사실상 잠시 쉬어도 되는 날짜였지만</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탄은 원래 계획했던 작품을 할 수 없어서 작품의
계획 단계부터 다시 만들어야만 했기에 여유가 없었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>2500</span><span
style='line-height:160%;color:white'>년대 지구에서 환경 운동가가 하는 일은 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>오염을 최소화할 방법 찾기</span><span lang=EN-US style='line-height:
160%;color:white'>’ </span><span style='line-height:160%;color:white'>등이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 현재 아레테 행성은 이미 너무 깨끗하고 오염된
공간이 없었으며</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>환경 운동가로서 너무나 사랑하는 세상이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>이렇게 완벽한 행성에서 매뉴얼대로 작품을 만든다면 오히려 행성의 자연을 망치는
일이 아닐까</span><span lang=EN-US style='line-height:160%;color:white'>.’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탄은 아레테 행성을 보자마자 첫눈에 반했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>그러나 그렇기에 마음 한편으로는 </span><span lang=EN-US
style='line-height:160%;color:white'>75</span><span style='line-height:160%;
color:white'>일째 있을 </span><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>작품 만들기</span><span lang=EN-US
style='line-height:160%;color:white'>’</span><span style='line-height:160%;
color:white'>에 대해 압박감을 느끼고 있었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그런데 며칠 전에 우연히 물가에서 발견한 생명체를
보고 생각이 달라졌다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그것은 지구로 따지자면 따개비 같은 생물이었는데
놀라운 번식력을 가지고 있는 한편 개체수가 늘어나긴커녕 나날이 줄어만 갔다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탐사하는 와중에 신경 써서 살펴본 결과 녀석들이
하루에 한 번씩 잉태와 죽음을 번갈아 가며 하고 있다는 것을 알게 되었고</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>우연히 삼일 정도 살게 된 녀석을 통해 육지에서 서식해야 하는 종족인 것을 알아냈다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>비록 삼일이었지만 그 개체는 손과 발을 가지고 있었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하지만 물가에서 태어난 것 때문에 손과 발을 갖기
전에 죽게 된 거고</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>도망가볼 겨를도 없이 죽음을 맞이했던 안타까운 개체들</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>더구나 아레테 행성의 비의 강수량은 상상을 초월하고
물가 근처에는 자신의 몸을 </span><span lang=EN-US style='line-height:160%;color:white'>2~3</span><span
style='line-height:160%;color:white'>배로 부풀리는 나무가 없었기에 보호받을 수조차 없던 상황</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이러한 사실을 알게 된 탄은 해당 개체에 배기라는
이름을 붙여주고 한 마리만 육지로 옮겨줬었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>며칠 뒤</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>예상대로 배기는 잘 자라났으며 지구의 사슴과 너구리를 뒤섞은 모습으로 변해갔다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이까지 확인 하고나자 탄은 아레테 행성에서 자신이
만들 작품 주제를 확신했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>내가 해야 하는 일</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>나의 작품</span><span lang=EN-US style='line-height:160%;color:white'>!’
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아주 최소한의 손길로 새로운 개체를 늘려가는 것이다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>어딘가에는 배기처럼 육지에서 서식하지만 태생이 물가에서
자라난 동물이 있을 수 있고 그 반대의 경우도 있을 수 있다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탄은 환경운동가로서 자신이 할 수 있는 최고의 방법을
생각해냈다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>위와 같은 동물들을 도와주면서 도감을 만드는 것이다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>물론 도감을 작성하는 동안에는</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>종군기자가 된 마음가짐으로 작업에 임할 것이다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>기자들이 눈앞에서 어떠한 상황이 벌어지더라도 자신의
위치를 지키고 사람들에게 진실을 알리는 존재만이 된 것처럼</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>동물의 약육강식의 세계에 너무 깊숙하게 파고들지
않고 개체가 살아갈 수 있을 만큼의 도움과 도감 기록에만 집중하기로 결심했다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>프로젝트 제목은 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>사람의 손을 타고 비로소 태어난 생명체</span><span lang=EN-US
style='line-height:160%;color:white'>’</span><span style='line-height:160%;
color:white'>이다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이 도감에는 생물의 이름</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>특징</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>사람과의 친밀도</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>각 종족의 천적과 약육강식의
피라미드 등등 작고 사소한 것부터 거의 모든 것을 기록했다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아레테 행성 생태계에 있어 아주 중요한 자료가 될
것이기에 작은 것 하나 놓쳐서는 안 된다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탄은 언젠가 이 행성을 떠나기 전에 가능한 모든
생물과 개체의 기록을 담는 것을 목표하고</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>매일 다른 시간과 다른 타이밍에
행성 탐사를 이어가고 있었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그때 그들을 발견했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>두 다리로 이족보행을 하고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>두 개의 팔이 있으며</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>얼굴이 우주 헬멧을 쓴 것처럼 둥글고 아이의 몸을 가진 것처럼 </span><span lang=EN-US
style='line-height:160%;color:white'>3</span><span style='line-height:160%;
color:white'>등신 정도의 크기인</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>우리 밀코인과 비슷한 체형인데 노란색인 우리와 다르게
화려한 색감으로 뒤덮인</span><span lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>묘하게 눈이 매서운 존재를</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탄은 잠시 고민했지만 아레테 행성에 있는 모든 생명을
도감에 기록하겠다고 다짐했던 목표를 떠올리며</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>낯선 존재에게 한 발짝 다가가
물었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>넌 대체 누구니</span><span lang=EN-US
style='line-height:160%;color:white'>?” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>`
}
export default staticFullStoryData_6

const authRoutes = [
  {
    path: 'login',
    name: 'Login',
    component: /* webpackChunkName: "login" */ () =>
      import('@/views/auth/LoginView.vue'),
    meta: { authRequired: false },
  },
  {
    path: 'join',
    name: 'Join',
    component: /* webpackChunkName: "join" */ () =>
      import('@/views/auth/SignUpView'),
    meta: { authRequired: false },
  },
  {
    path: 'find-id',
    name: 'FindId',
    component: /* webpackChunkName: "findId" */ () =>
      import('@/views/auth/FindIdView'),
    meta: { authRequired: false },
  },
  {
    path: 'find-password',
    name: 'FindPassword',
    component: /* webpackChunkName: "findPassword" */ () =>
      import('@/views/auth/FindPasswordView'),
    meta: { authRequired: false },
  },
];

export default authRoutes;

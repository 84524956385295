<template>
  <div id="popup-target"></div>
  <router-view/>
</template>

<script setup>
import {onMounted, watchEffect} from "vue";
import {getEpisodeList} from "@/api/artwork.js";
import {useMintingStore} from "@/store/mintingStore.js";
import {getMemberInfo} from "@/api/member";
import {useAuthStore} from "@/store/authStore";
import { useRouter } from "vue-router";

const router = useRouter();

const mintingStore = useMintingStore();
const authStore = useAuthStore();

onMounted(async () => {
  try {
    const resp = await getEpisodeList();

    const filteredEpisodes = resp.data.data
                                 .filter((ep) => {
                                   // filter 조건은 고객으로 부터 confirm 후 filter 함.
                                   return ep.SALE_TYPE_CD !== "10";
                                 })
                                 .map((ep, index) => ({
                                   ...ep,
                                   episodeIndex: index + 1
                                 }));

    mintingStore.setEpisodeList(filteredEpisodes);
  } catch (error) {
    console.error(error);
  }
});

watchEffect(async () => {
  if (authStore.MEMBER_SEQ) {
    let config = {
      headers: {
        Authorization: 'Bearer ' + authStore.getAccessToken
      }
    }

    const resp = await getMemberInfo({memberSeq: authStore.MEMBER_SEQ}, config, authStore, router);
    if (resp.data.resultCode === 200) {
      if (resp.data.data.profileMap && resp.data.data.profileMap.imgPath) {
        authStore.setAvatarImageUrl(resp.data.data.profileMap.imgPath);
      }
    }
  }
});
</script>

<style lang="scss"></style>

<!--template>
  <div class="fixed-count-item-wrap" :class="isNowMintable && 'mintable'">
    <div
        class="countdown"
        v-if="!isNowMintable && mintingStore.upcomingEpisode"
    >
      < !--
        <div class="days"><strong>2</strong>DAYS</div>
        <div class="hrs"><strong>2</strong>Hrs</div>
        <div class="min"><strong>2</strong>Min</div>
        <div class="sec"><strong>2</strong>Sec</div>
        -- >
    </div>

    <div class="mico" :class="isNowMintable && 'mintable'">
      <img src="~@/assets/image/ico/ico-begins.svg"/>
      <div style="margin-top: 1.2em">
        < !-- v-if="!isNowMintable" -- >
        <span>JOURNEY<br/>BEGINS</span>
        < !-- <span v-else>{{ episodeTitle }}</span> -- >
      </div>
    </div>

    <div
        :class="['link-item-wrap', !isNowMintable && 'disabled']"
        type="button"
        @click="onClickMint"
    >
      <a href="javascript:void(0)">민팅바로가기</a>
    </div>

    <ul class="hs-item-wrap">
      <li
          v-for="(ep, i) in filterEpisodeList"
          :key="'ep-' + i"
          :class="
          // !!currentEpisode &&
          // hasActiveEpisode(ep.EPISODE_SEQ, currentEpisode.EPISODE_SEQ) &&
          // 'active'
          hasActiveEpisode(ep, currentEpisode, upcomingEpisode)
        "
      >
        <strong> {{ i + 1 }} </strong>
        {{ indicator(i + 1) }}
      </li>
    </ul>
  </div>
</template-->

<!-- eslint-disable no-undef -->
<script setup>
import {storeToRefs} from "pinia";
import {onMounted, computed, nextTick, ref, watch} from "vue";
import {useRouter} from "vue-router";
import {useMintingStore} from "@/store/mintingStore.js";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import indicator from "ordinal/indicator";
import {getEpisodeList} from "@/api/artwork";

dayjs.extend(customParseFormat);

const mintingStore = useMintingStore();

const {
  episodeList,
  currentEpisode,
  upcomingEpisode
} =
    storeToRefs(mintingStore);

const router = useRouter();

const episodeTitle = computed(() => {
  if (currentEpisode.value) {
    return currentEpisode.value.EPISODE_TITLE;
  }

  if (upcomingEpisode.value) {
    return upcomingEpisode.value.EPISODE_TITLE;
  }

  return "";
});

// 0 -> 0
// 1 -> 0
// 2 -> 1
// 3 -> 1

let filterEpisodeList = ref();

// watch(episodeList, (newValue) => {
//   console.log(newValue);
//   console.log(currentEpisode);
//
//   filterEpisodeList.value = [];

//   let EPISODE_START_DTM;
//   let EPISODE_END_DTM;
//   let EPISODE_SEQ = Array();
//
//   $.each(newValue, function (index, item) {
//     if (item.SALE_TYPE_CD === "20") { // 각 회차의 시작
//       EPISODE_SEQ = Array();
//       EPISODE_START_DTM = item.EPISODE_START_DTM;
//       EPISODE_SEQ.push(item.EPISODE_SEQ);
//
//     } else if (item.SALE_TYPE_CD === "30") { // 각 회차의 종료
//       EPISODE_END_DTM = item.EPISODE_END_DTM;
//       EPISODE_SEQ.push(item.EPISODE_SEQ);
//       filterEpisodeList.value.push({
//         EPISODE_START_DTM,
//         EPISODE_SEQ,
//         EPISODE_END_DTM
//       });
//     }
//   });
//
// });

const hasActiveEpisode = (episode, cur, upcoming) => {
  if (cur || !upcoming || !upcoming.EPISODE_SEQ) {
    return "";
  }

  let isActive = false;
  $.each(episode.EPISODE_SEQ, function (index, item) {
    if (item === upcoming.EPISODE_SEQ) {
      isActive = true;
    }
  });


  return isActive ? "active" : "";
};

const onClickMint = () => {
  if (isNowMintable.value) {
    router.push({
      name: "Minting",
      query: {
        EPISODE_SEQ: mintingStore.currentEpisode.EPISODE_SEQ,
        NFT_SEQ: mintingStore.currentEpisode.NFT_SEQ,
        EPISODE_INDEX: mintingStore.currentEpisode.episodeIndex,
      },
    });
  }
};

const isNowMintable = computed(() => {
  if (
      mintingStore.currentEpisode &&
      mintingStore.currentEpisode.EVENT_YN === "Y"
  ) {
    return !!mintingStore.currentEpisode;
  }

  return false;
});

onMounted(() => {
  /* 데이터 갱신이 안될때 있어서 여기서 한번더 처리 */
  apiGetEpisodeList();
});

async function apiGetEpisodeList() {
  try {
    const resp = await getEpisodeList();

    const filteredEpisodes = resp.data.data
                                 .filter((ep) => {
                                   return ep.SALE_TYPE_CD !== "10";
                                 })
                                 .map((ep, index) => ({
                                   ...ep,
                                   episodeIndex: index + 1,
                                 }));
    mintingStore.setEpisodeList(filteredEpisodes);

    setTimer();

    let EPISODE_SEQ = Array();
    let EPISODE_START_DTM;
    let EPISODE_END_DTM;

    filterEpisodeList.value = [];
    $.each(filteredEpisodes, function (index, item) {
      if (item.SALE_TYPE_CD === "20") {
        // 각 회차의 시작
        EPISODE_SEQ = Array();
        EPISODE_START_DTM = item.EPISODE_START_DTM;
        EPISODE_SEQ.push(item.EPISODE_SEQ);
      } else if (item.SALE_TYPE_CD === "30") {
        // 각 회차의 종료
        EPISODE_END_DTM = item.EPISODE_END_DTM;
        EPISODE_SEQ.push(item.EPISODE_SEQ);
        filterEpisodeList.value.push({
          EPISODE_START_DTM,
          EPISODE_SEQ,
          EPISODE_END_DTM,
        });
      }
    });
  } catch (error) {
    console.error(error);
  }
}

function setTimer() {
  const {
    currentEpisode,
    upcomingEpisode
  } = mintingStore;

  if (upcomingEpisode === undefined) {
    return;
  }

  const countDownTargetDate = dayjs(
      (currentEpisode && currentEpisode.EPISODE_START_DTM) ||
      (upcomingEpisode && upcomingEpisode.EPISODE_START_DTM),
      "YYYY-MM-DD HH:mm"
  );

  $.fn.extend({
    countdown: function (props) {
      props = jQuery.extend(
          {
            //Default props
            until: getNewDate(),
          },
          props
      );
      const state = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
      let render = (props) => {
        const {
          days,
          hours,
          minutes,
          seconds
        } = props;
        this.empty();
        this.append(`
          <div class="days" date-count="${
            days == 1 ? "day" : "days"
        }"><strong>${days}</strong>DAYS</div>
          <div class="hrs" date-count="${
            hours == 1 ? "hour" : "hours"
        }"><strong>${hours < 10 ? "0" + hours : hours}</strong>Hrs</div>
          <div class="min" date-count="${
            minutes == 1 ? "minute" : "minutes"
        }"><strong>${minutes < 10 ? "0" + minutes : minutes}</strong>Min</div>
          <div class="sec" date-count="${
            seconds == 1 ? "second" : "seconds"
        }"><strong>${seconds < 10 ? "0" + seconds : seconds}</strong>Sec</div>
          `);
      };
      render(state);
      let update = setInterval(function () {
        let counter = props.until - getNewDate().getTime();
        if (counter <= 0) {
          clearInterval(update);
          return false;
        }
        state.days = Math.floor(counter / (1000 * 60 * 60 * 24));
        state.hours = Math.floor(
            (counter % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        state.minutes = Math.floor((counter % (1000 * 60 * 60)) / (1000 * 60));
        state.seconds = Math.floor((counter % (1000 * 60)) / 1000);
        render(state);
      }, 1000);
      return this;
    },
  });

  $(".countdown").countdown({
    // until: new Date(`Dec, 14, ${new Date().getFullYear()}`), // you can add time optionally ('Dec, 25, 2020 00:00:00')
    until: countDownTargetDate.toDate(),
  });
}

function getNewDate() {
  // 1. 현재 시간(Locale)
  const curr = new Date();

// 2. UTC 시간 계산
  const utc =
      curr.getTime() +
      (curr.getTimezoneOffset() * 60 * 1000);

// 3. UTC to KST (UTC + 9시간)
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const kr_curr = new Date(utc + (KR_TIME_DIFF));

  return kr_curr;
}


</script>

<style lang="scss" scoped>
/* 
.fixed-count-item-wrap {
  transform: scale(0.6) translateY(-240px) translateX(-80px);

  &.mintable {
    transform: scale(0.6);
  }

  & li {
    transform: scale(1.4);
    margin-top: 40px;
    margin-bottom: 40px;
  }

  & .link-item-wrap a {
    margin-top: 32px;
    transform: scale(1.4);
  }
}

.mico {
  &.mintable {
    height: 300px;
  }
}
*/
.hs-item-wrap li.active {
  transform: scale(1.5) translateX(1px) translateY(-6px);
  padding-top: 4px;
  color: #ffffff;
  background-color: #2f2f48;
}

.hs-item-wrap li.active:before {
  background-color: #ffffff;
}

.hs-item-wrap li.active strong {
  color: #ffffff;
  background-color: #2f2f48;
}
</style>

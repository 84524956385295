const staticFullStoryData_2 = {
		id : 2,
		title: "엔터테이너-지수 (1일차)",
		body: `<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'><img width=372
height=288 id="그림 8" src="/image/story2/image001.jpg"></span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white;font-weight:bold'>2화 #. 엔터테이너 – 지수 (1일 차)</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 두 개의 해와 거대한 호수가 있는 행성의
주위를 둘러보았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>이곳이 나의 행성이라니</span><span lang=EN-US
style='line-height:160%;color:white'>...’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>형광색으로 빛나는 식물들은 별이나 조명처럼 화려하게
존재감을 뿜어냈고</span><span lang=EN-US style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그 모습에 홀린 지수는 자연스럽게 로봇 고양이의
카메라 기능을 작동시켰다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>애완 로봇은 원래 사람들의 쓸쓸함을 달래는 용도였지만
점차 기능이 다양해지면서 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>고성능 카메라와</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>자외선 탐사</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>간단한 숫자 통계</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>홀로그램 형상화까지 가능하게
됐다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>카메라의 렌즈는 애완 로봇의 눈에 각각 달렸고 화질
또한 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>보통의 카메라보다 훨씬 뛰어났다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>나중에 지구로 돌아가면 바로 업로드 해야지</span><span
lang=EN-US style='line-height:160%;color:white'>!’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>마음 같아서는 찍자마자 올리고 싶었지만</span><span
lang=EN-US style='line-height:160%;color:white'>,</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>밀코메다 은하에서 지구와 통신이 가능한 건 거버넌스와
소통하는 채널밖에 없었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 일단 기록을 남겨놓는 것에 집중하기로 했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>로봇 고양이의 카메라 기능을 켜고 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>코에 빨간 불빛이 들어온 것을 확인한 후 멘트를
시작했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>여러분</span><span lang=EN-US
style='line-height:160%;color:white'>! </span><span style='line-height:160%;
color:white'>제가 이 행성에 발을 디딘 첫 번째 사람입니다</span><span lang=EN-US style='line-height:
160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이곳은 제 소유의 행성이고 이름은 바다의 보석이라는
뜻의 코델리아로 지으려고요</span><span lang=EN-US style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>호수가 너무 아름답지 않습니까</span><span
lang=EN-US style='line-height:160%;color:white'>?!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>뒤에 펼쳐진 아름다운 호수를 화면에 담기 위해 로봇
고양이는 드론처럼 날아올랐다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지금은 비록 영상으로밖에 소식을 전할 수 없지만</span><span
lang=EN-US style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하루라도 빨리 밀코메다의 행성이 안전하다는 걸 증명해서
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>팬들을 초대하려는 게 지수의 목적이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러기 위해서 가장 처음 할 일은</span><span
lang=EN-US style='line-height:160%;color:white'>? </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>밀코메다</span><span
lang=EN-US style='line-height:160%;color:white'>N</span><span style='line-height:
160%;color:white'>의 매뉴얼대로라면 행성에 캡슐이 안전하게 착륙하면 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>정착</span><span lang=EN-US
style='line-height:160%;color:white'>’</span><span style='line-height:160%;
color:white'>이라는 것을 해야 한다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>손에 든 매뉴얼을 읽은 지수는 찬찬히 생각하며 주위를
둘러보았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>최고의 엔터테이너인 지수의 정착은 남들과 달라야
하니까</span><span lang=EN-US style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>나중에 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>최초의 밀코인</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>월드클래스 지수가 탔던 캡슐</span><span
lang=EN-US style='line-height:160%;color:white'>’</span><span style='line-height:
160%;color:white'>이라는 기사가 날 것을 대비해서</span><span lang=EN-US style='line-height:
160%;color:white'>,</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>예술적인 면모를 잘 표현해줄 만한 디자인이 필요했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 지구에서부터 미리 가져온 페인트 통과 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>여러 장식품을 통해 캡슐을 꾸미기 시작했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>컨셉은 팝아트로</span><span
lang=EN-US style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>통통 튀고 발랄한 느낌이 나랑 가장 잘 표현해줄
수 있을 거라 생각했기 때문이었다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>무대에서 춤추고 노래하는 것처럼</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>신이 나서 꾸미다 보니 캡슐의 디자인은 금세 완성됐다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>백 미터 전방에서 봐도 한눈에 띠일 만큼 화려한
모습과 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>당장이라도 하늘로 치솟아 오를 것 같은 생동감을
보니 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>처음 기획했던 의도와 맞게 지수라는 엔터네이너를
아주 잘 표현한 것 같아 만족스럽다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>훗날 코델리아 행성에서 공연할 때 캡슐을 타고 등장해도
좋을 것 같다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>행성을 가득 채우는 팬들의 함성과 그에 보답하듯
열정을 다해 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>공연을 올리는 지수의 모습</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>그리고 마무리 피날레</span><span lang=EN-US
style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>감사합니다</span><span lang=EN-US
style='line-height:160%;color:white'>!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 공연을 마친 사람처럼 힘찬 목소리로 허리를
숙여 인사했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 주위가 이상하리만큼 고요했고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>숙였던 허리를 서서히 들어 주위를 둘러보았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>방금까지는 거대한 공연이 이루어진 모습은 지수의
상상이었다</span><span lang=EN-US style='line-height:160%;color:white'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>분명 아름답긴 하지만 뭔가 적막한 코델리아 행성에
로봇 고양이와 함께 있을 뿐이었다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>이럴 줄 알았으면 박수 소리라도 녹음해올걸</span><span
lang=EN-US style='line-height:160%;color:white'>...” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>자신도 모르게 나지막이 내뱉은 소리에 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수의 품에 안겨 있던 로봇 고양이가 그의 볼을
핥았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>위로를 해주는 듯한 로봇 고양이의 모습에 지수는
실없이 피식 웃었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그때</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>펑</span><span lang=EN-US style='line-height:160%;color:white'>! </span><span
style='line-height:160%;color:white'>갑자기 뒤에서 큰 굉음이 들렸다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>뒤를 돌아본 지수가 소리의 정체를 알기까진 오래
걸리지 않았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하늘 위로 튀어 오르고 있는 빨간 열매는 좀 전까지만
해도 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>커다란 나무 위에 달려 있던 거였는데</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>지금은 하늘 가장 높은 곳에서 불꽃처럼 터졌다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'><img width=408
height=288 id="그림 7" src="/image/story2/image002.jpg"></span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>펑펑</span><span lang=EN-US
style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>붉은 과육은 반경 </span><span
lang=EN-US style='line-height:160%;color:white'>20</span><span
style='line-height:160%;color:white'>미터 이내로 넓게 퍼졌고</span><span lang=EN-US
style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>과육의 액체를 맞은 다른 식물은 급속도로 줄기가
자라거나 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>성장이 촉진되면서 또 다른 변화를 가져왔다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>서로가 서로의 거름이 되는 모습에 지수는 입을 떡
벌었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이곳</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>코델리아 행성의 무한한 가능성을 보게 된 순간이었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>서로가 서로의 거름이 되며 성장을 도와주는 이런
행성에선 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>자원이 고갈될 일이 없을뿐더러 사람들은 인공적으로
만든 음식이 아닌 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>신선한 식량으로 늘 배를 채울 것이다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>물론 새로 정착하는 행성에서는 인류가 똑같은 실수를
반복하지 않는다는 전제하에</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 불꽃놀이처럼 터지는 붉은 과육을 바라보면서
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>목 부근에 붙어있는 통신기를 작동시켰다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>코델리아 행성의 상냥한 특성을 알게 된다면 거버넌스도
기뻐할 테니</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>도착하자마자 행성의 특성을 알아차린 운 좋은 사람은
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>자신밖에 없을 거란 생각에 금세 기분이 좋아지며
통신 연결을 서두르기도 했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그런데</span><span
lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>지지직</span><span lang=EN-US
style='line-height:160%;color:white'>- </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>이게 왜 이러지</span><span lang=EN-US
style='line-height:160%;color:white'>?’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>통신기를 사용하는 방법은 본부에서 </span><span
lang=EN-US style='line-height:160%;color:white'>5</span><span style='line-height:
160%;color:white'>년 간의 훈련 중에 가장 먼저 배운 것이었고</span><span lang=EN-US
style='line-height:160%;color:white'>,</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>가장 기초적인 지식인만큼 작동 방법을 잊어버렸을
리 없다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하지만 버튼을 아무리 눌러보아도 지지직거릴 뿐 목소리가
들리지 않았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>여</span><span lang=EN-US style='line-height:
160%;color:white'>...</span><span style='line-height:160%;color:white'>기</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>지지직</span><span lang=EN-US
style='line-height:160%;color:white'>- </span><span style='line-height:160%;
color:white'>보</span><span lang=EN-US style='line-height:160%;color:white'>..</span><span
style='line-height:160%;color:white'>부</span><span lang=EN-US style='line-height:
160%;color:white'>.. </span><span style='line-height:160%;color:white'>어디</span><span
lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>퓨슈우우</span><span lang=EN-US
style='line-height:160%;color:white'>!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>오디오를 최대치로 켜니 본부인 것 같은 사람의 목소리가
겨우 들리긴 했지만</span><span lang=EN-US style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>대화다운 대화는 한마디도 해 보지 못한 채 통신기가
검은 연기를 내면서 터졌다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 사색이 된 얼굴로 광활한 코델리아 행성의
풍경을 바라봤다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아까 전까지만 해도 분명 아름답게만 느껴졌는데</span><span
lang=EN-US style='line-height:160%;color:white'>,</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>일말의 소통마저 끊긴 지금은 살이 떨리도록 무섭다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>아무도 없다니</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>누구와도 소통할 수 없다니</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>나 혼자뿐이라니</span><span
lang=EN-US style='line-height:160%;color:white'>...!’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>늘 사람들의 관심에 둘러싸여 사랑받던 지수였기에
두려움은 배로 커졌다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 결국 혼자라는 압박감을 견디지 못하고 볼품없는
목소리로 소리쳤다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>누구라도 날 찾아줘</span><span lang=EN-US
style='line-height:160%;color:white'>! </span><span style='line-height:160%;
color:white'>나 여기 있어</span><span lang=EN-US style='line-height:160%;color:white'>!!!!!”
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>성량이 좋은 그의 목소리는 코델리아 행성 아주 멀리까지도
퍼졌지만</span><span lang=EN-US style='line-height:160%;color:white'>,</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>돌아오는 대답은 없었다고 한다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><b><span lang=EN-US style='line-height:107%;color:white'>&nbsp;</span></b></p>

</div>`
}
export default staticFullStoryData_2

const staticFullStoryData_3 = 		{
		id : 3,
		title: "엔터테이너-지수 (45일차)",
		body: `<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white;font-weight:bold'>3화 #. 엔터테이너 – 지수 (45일 차)</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 생각보다 코델리아 행성에서 알찬 시간을 보냈다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>통신기가 끊겨서 홀로 낯선 행성에 고립되었다는 사실을
알게 됐을 때는</span><span lang=EN-US style='line-height:160%;color:white'>,</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>몇 날 며칠이고 꼼짝없이 넋을 놓고만 있었는데 어느
순간 깨달았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>자신은 지구에서 선발되어 우주로 온 남다른 존재라는
걸</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>사실을 깨우친 지수는 드라이버와 팬지를 들고 통신기
앞에 섰다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>고장 난 통신기 정도야 단숨에 고쳐주지</span><span
lang=EN-US style='line-height:160%;color:white'>!’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>호기로운 웃음을 지은 지수는 망설임 없는 손길로
통신기를 분해했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>해야 하는 일이 생기니 침대에 정처 없이 누워있는
시간보다 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>통신기 앞에서 뭔가라도 해보는 시간이 늘었고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>통신기를 해부하고 고칠 시간을 벌기 위해서라도 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>본부에서 지시한 매뉴얼은 기간대로 빠르게 이행했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>전동 드라이버가 없어서 단단하게 조여진 나사를 손힘으로만
풀어야 한다던가</span><span lang=EN-US style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>막상 뚜껑을 열고 보니 자신은 엔터 쪽 지원자로
뽑힌 거라서 공기계엔 아는 것이 없다던가 등등</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>중간에 위기는 닥쳤지만 한번
시작한 지수는 멈추지 않았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>칼을 들었으면 무라도 썰어야지</span><span lang=EN-US
style='line-height:160%;color:white'>!’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>본부에서 모든 기계의 상태를 점검할 수 있는 조작도를
애완 로봇의 안에 심어 두었다고 들었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이대로만 잘 따라가다 보면 분명 성공할 수 있으리라</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 이마에서 뚝뚝 흐르는 땀이 통신기 내부 안으로
들어가지 않게 조심하며 </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>로봇 고양이가 있는 쪽으로 고개를 돌렸다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러자 로봇 고양이는 수술실에서 의사를 어시스트
해주는 간호 보조처럼 빠르고 섬세한 손길로 지수의 땀을 닦아 주었다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>로봇 고양이는 밤낮이고 지수의 곁을 떠나가지 않았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>평소에 말을 잘 듣는 성정이 아닌데도 불구하고 제
주인이라 챙겨주는 모습에 감동한 지수는 더더욱 통신기 내부를 살피는 손길을 바삐 했다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>하지만</span><span lang=EN-US style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>퓨슈우욱</span><span
lang=EN-US style='line-height:160%;color:white'>... </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>뭔가 시원하게 고쳐지는 소리 대신 불안한 느낌이
들었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>코 밑에 기름 범벅이 된 지수는 그대로 멈춰서 미동조차
하지 못하다가 스리슬쩍 엉덩이를 뒤로 빼냈다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그리고 열댓 시간 동안 앉아있던 자리에서 벗어나
산산이 분해 된 통신기계를 끌어안았다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>너까지 떠나지마</span><span lang=EN-US
style='line-height:160%;color:white'>! </span><span style='line-height:160%;
color:white'>내가 미안해</span><span lang=EN-US style='line-height:160%;color:white'>,
</span><span style='line-height:160%;color:white'>앞으로는 네 위에서 과자나 콜라도 안 마시고 매일매일
깨끗하게 청소해줄게</span><span lang=EN-US style='line-height:160%;color:white'>!! </span><span
style='line-height:160%;color:white'>제발 돌아와</span><span lang=EN-US
style='line-height:160%;color:white'>!!!!!!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이날의 충격은 꽤나 길었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 이전보다 심하게 캡슐 밖으로 나가지 않았으며</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>분해된 통신기에 </span><span lang=EN-US
style='line-height:160%;color:white'>‘</span><span style='line-height:160%;
color:white'>배키</span><span lang=EN-US style='line-height:160%;color:white'>’</span><span
style='line-height:160%;color:white'>라는 이름을 붙여주곤 때로는 친구처럼 애인처럼 다정하게 대했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>-</span><span
style='line-height:160%;color:white'>배키</span><span lang=EN-US
style='line-height:160%;color:white'>...? </span><span style='line-height:160%;
color:white'>왜 아직도 안 자고 있어</span><span lang=EN-US style='line-height:160%;
color:white'>. </span><span style='line-height:160%;color:white'>그래 너도 오늘 하루가 아쉬웠구나</span><span
lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>나랑 함께 코델리아 행성의 푸르른 밤을 만끽하자</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>-</span><span
style='line-height:160%;color:white'>언젠가 너의 병이 낫는다면 우리 함께 꼭 손잡고 세계 여행을 하자</span><span
lang=EN-US style='line-height:160%;color:white'>! </span><span
style='line-height:160%;color:white'>지구에는 내가 좋아하는 장소가 아주 많아</span><span
lang=EN-US style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>-</span><span
style='line-height:160%;color:white'>네가</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>나와 같은 사람이면 좋겠다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span><span style='line-height:160%;color:white'>응</span><span
lang=EN-US style='line-height:160%;color:white'>? </span><span
style='line-height:160%;color:white'>뭐라고</span><span lang=EN-US
style='line-height:160%;color:white'>? </span><span style='line-height:160%;
color:white'>아하하</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>그래</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>지금의 너여도 당연히 좋지</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>-...</span><span
style='line-height:160%;color:white'>배키야</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>네가 갑자기 나와 같은 모습으로 나타나도 모른 척해줄게</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>제발 부탁이야</span><span lang=EN-US style='line-height:160%;color:white'>.
</span><span style='line-height:160%;color:white'>날 혼자 두지 마</span><span
lang=EN-US style='line-height:160%;color:white'>... </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수의 상태는 나날이 심해져만 갔다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>거버넌스의 본부에 있을 때 이러한 상태였다면 공황장애와
심신미약으로 우주 프로젝트에서 제외됐을 거고</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>팬들이 지수의 상태를 알게 된다면 큰 충격에 빠져
함께 우울함을 겪을 수 있을 정도로</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수의 불안정한 우울함은 자신을 넘어서 주위에까지
넓게 퍼져가고 있었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러던 중</span><span
lang=EN-US style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>결국 끝을 보았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>-@$#&amp;^*&amp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“...</span><span
style='line-height:160%;color:white'>응</span><span lang=EN-US style='line-height:
160%;color:white'>? </span><span style='line-height:160%;color:white'>뭐라고</span><span
lang=EN-US style='line-height:160%;color:white'>?” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>통신기가 지수에게 말을 건 것이다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>분명 들린 목소리에 지수는 얼굴을 통신기에 바짝
붙여 온 신경을 집중했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>발전된 기술</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>몇 날 며칠을 바라고 바랬던 통신기의 인간화</span><span lang=EN-US style='line-height:
160%;color:white'>. </span><span style='line-height:160%;color:white'>나의 눈물이 찔끔
섞여서 영혼을 갖게 되었나</span><span lang=EN-US style='line-height:160%;color:white'>...?’</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>말도 안 되는 생각이라는 걸 깨닫기에 지수는 제정신이
아니었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>배키야</span><span lang=EN-US
style='line-height:160%;color:white'>...! </span><span style='line-height:160%;
color:white'>뭐라도 말해봐</span><span lang=EN-US style='line-height:160%;color:white'>.
</span><span style='line-height:160%;color:white'>내가 널 찾아줄게</span><span
lang=EN-US style='line-height:160%;color:white'>!!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>-@$%&amp;)(*)*^$%^&amp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이번에도 역시나 대답은 돌아왔고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>이를 들은 지수는 통신기를 들고 바닥에서 일어났다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>처음으로 수만 명의 팬 앞에서 공연하고 환호 받았을
때보다 두근거렸으며</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>무려 </span><span lang=EN-US
style='line-height:160%;color:white'>45</span><span style='line-height:160%;
color:white'>일 만에 들어본 타인의 목소리에 첫눈에 반하기라도 한 듯 순식간에 빠져들었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>조금 높은 미성에 애절하게 누군가를 찾는 것 같은
목소리</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 배키 또한 자신을 간절히 찾은 거로 생각하며
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>목소리가 이끄는 방향으로 걸음을 돌렸다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>처음에는 해부된 통신기 내부에서 들리는 듯했지만
두 번째로 이어진 목소리부터는 아주 조금 멀고 단단하며 두꺼운 무언가가 사이를 가로막는 느낌이었기에</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 캡슐의 문을 열고 밖으로 나갔으며 맨발로
잔디밭을 빠르게 밟아 나아갔다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>목소리가 지수를 이끄는 곳은 탐사 기간 때엔 한
번도 발을 딛은 적이 없던 곳이었다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>본부에서 지시한 행성 탐사 매뉴얼은 밀코메다 이주
프로젝트의 데이터가 되는 만큼 매우 중요했지만</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>세상과 단절되어 충격에 휩싸여
반쯤 미쳐있던 지수에겐 소용없는 말이었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그래도 마지막 남은 책임감을 쥐어 짜내서 겨우 주변을
둘러보았지만 겉핥기식이라 봐도 무방할 정도였다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>오히려 누군가의 목소리에 이끌려 행성 이곳저곳을
쏘다니고 있는 지금이 가장 제대로 된 탐사라고 생각해도 될 만큼</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 언덕을 내려가 호수를 지나고 그 너머에 만들어진
작은 길로 들어갔다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>자연적으로 만들어졌다하기엔 너무 올곧게 나 있었지만
지수는 파악하지 못했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그리고 마침내</span><span
lang=EN-US style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>환청의 속사임과 의문의 발자국</span><span
lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>그리고 낯선 이의 그림자를 발견했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>정말</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>배키가 사람이 돼서 나타난 건가</span><span lang=EN-US style='line-height:160%;
color:white'>?!’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>해가 진 방향이 다르니 자신의 그림자는 아닐 거고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>거버넌스는 한 행성에 한 명의 밀코인만을 보낸다고 강조했었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러니까 지금 이 행성에 있는 낯선 사람은 배키일
수밖에 없다는 결론이었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 조명처럼 빛나는 나무 열매가 비춰주는 낯선
이의 얼굴을 떨리는 시선으로 바라봤다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>3</span><span
style='line-height:160%;color:white'>등신 정도 되는 비율에 노란 신체</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>각자 팔이나 다리에 이따금 화려한 색감을 지닌 것을 제외하고는 노란 색상으로 온몸이 뒤덮인 밀코인</span><span
lang=EN-US style='line-height:160%;color:white'>...? </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>배키가 왜 밀코인의 모습으로 나타난 건지</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>배키가 맞긴 한 건지</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>아니라면 어째서 본인 이외의 밀코인이 코델리아 행성에 존재하는지</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>그들의 색깔이 다른 이유는 무엇인지</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>그들이 안전한 존재인지
등등 여러 생각이 들만했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 이런 이성적인 생각은 지수의 절실했던 상황에
발끝도 못 미쳤다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>더구나 모습을 드러낸 낯선 밀코인 또한 양 팔을
활짝 벌리며 지수의 품으로 달려왔다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수도 이에 반응했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>신이시여</span><span lang=EN-US
style='line-height:160%;color:white'>! </span><span style='line-height:160%;
color:white'>신이 응답하셨다</span><span lang=EN-US style='line-height:160%;
color:white'>!!!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>배키야</span><span lang=EN-US
style='line-height:160%;color:white'>!!!!!! </span><span style='line-height:
160%;color:white'>나타나줘서 고마워</span><span lang=EN-US style='line-height:160%;
color:white'>!!!!!!!!” </span></p>

</div>`
}
export default staticFullStoryData_3

<template>
  <div class="main-container">
    <section id="home-sec01" class="home-milkn-wrap">
      <div class="mtit">밀코메다 N</div>
      <div class="mdesc">
        영앤리치 소사이어티를 지향하는 문화 예술 NFT 프로젝트
      </div>

      <div class="cta-wrapper">
        <button
          class="btn milkn-btn milkn-btn-blue btn-minting"
          onclick="window.open('https://opensea.io/collection/milkomeda-n', 'opensea')"
        >오픈씨 바로가기</button>
        <a class="btn-download-pdf"
          href="/files/milkomedaN_Character_Book.pdf"
          download="밀코메다N_캐릭터_북.pdf">캐릭터 북 보기 →</a>
      </div>
      <!-- <div class="cta-wrapper">
        <router-link
          class="btn milkn-btn milkn-btn-blue btn-cta"
          :to="{ name: 'Minting' }"
        >
          민팅 바로가기
        </router-link>
      </div> -->

      <div id="pop01" class="dialog">
        <div class="pop-img-wrap">
          <a href="javascript:window.open('https://canvasn.co.kr', '_blank');"><img src="@/assets/image/etc/etc-pop-img-0106.png" /></a>
        </div>
        <button
          type="button"
          class="btn-pop-close"
          style="
            position: fixed;
            top: 32px;
            right: 32px;
            background-color: transparent;
          "
          onclick="couponClose();common.popClose('#pop01')"
        >
          <img src="@/assets/image/ico/ico-pop-close.svg" />
        </button>
      </div>

      <div class="home-features-list">
        <ul>
          <li>
            <div class="sico">
              <img src="~@/assets/image/ico/ico-features-list01.svg" />
            </div>
            <div class="sdesc">세계적인 유행! <br />SNS 계정을 바꿔 보세요</div>
          </li>
          <li>
            <div class="sico">
              <img src="~@/assets/image/ico/ico-features-list02.svg" />
            </div>
            <div class="sdesc">5종의 시리즈를 <br />모아보세요</div>
          </li>
          <li>
            <div class="sico">
              <img src="~@/assets/image/ico/ico-features-list03.svg" />
            </div>
            <div class="sdesc">
              온오프라인에 자신만의 <br />컬랙션을 구축해보세요
            </div>
          </li>
        </ul>
      </div>
      <div class="home-char-list">
        <ul>
          <li>
            <div class="spic">
              <img src="~@/assets/image/etc/etc-card-list01.svg" />
            </div>
            <div class="sname">ENTERTAINER</div>
          </li>
          <li>
            <div class="spic">
              <img src="~@/assets/image/etc/etc-card-list02.svg" />
            </div>
            <div class="sname">HEALER</div>
          </li>
          <li>
            <div class="spic">
              <img src="~@/assets/image/etc/etc-card-list03.svg" />
            </div>
            <div class="sname">INNOVATOR</div>
          </li>
          <li>
            <div class="spic">
              <img src="~@/assets/image/etc/etc-card-list04.svg" />
            </div>
            <div class="sname">CREATOR</div>
          </li>
          <li>
            <div class="spic">
              <img src="~@/assets/image/etc/etc-card-list05.svg" />
            </div>
            <div class="sname">ARTIST</div>
          </li>
        </ul>
      </div>
    </section>

    <section id="home-sec02" class="home-nft-wrap">
      <!--
            <div class="side-pic-wrap"></div>
            <div class="cont-item-wrap">
                <div class="mpic"></div>
                <ul>
                    <li><img src="" /></li>
                    <li><img src="" /></li>
                    <li><img src="" /></li>
                    <li><img src="" /></li>
                    <li><img src="" /></li>
                </ul>
            </div>-->
      <video
        width="100%"
        height="100%"
        id="v_player"
        ref="v_player"
        playsinline=""
        allowfullscreen="true"
        src="~@/assets/video/video-home.mp4"
        poster="~@/assets/image/etc/etc-home-section02.jpg"
        @ended="() => (isPlayingVideo = false)"
      ></video>
      <button
        type="button"
        :class="['btn-video', isPlayingVideo ? 'on' : 'off']"
        @click="handleClickVideo"
      >
        <img src="~@/assets/image/ico/ico-play.svg" />
        <!-- <span>Coming soon</span>-->
      </button>
    </section>

    <section id="home-sec03" class="home-sequence-wrap">
      <div class="space-about">
        <div class="mtit">Space Artists: After Collision</div>
        <div class="mdesc">
          우주 개발에 성공한 슈퍼리치들은 블랙홀을 통해 시공간을 자유롭게 이동할
          수 있게 된다 <br />은하 이주 프로젝트에 의해 Creativity DNA를 인증
          받은 만명의 수퍼리치들은 30억년 후 우리 은하수 은하와
          <br />안드로메다와 충돌하여 생긴 ‘밀코메다 은하’로 가서 각자 새로운
          행성에 터를 잡고 자신의 행성을 개발하게 된다
        </div>
      </div>
      <dl>
        <dt>
          <span class="sequence-title">sequence</span>
          <div class="btn-item-wrap">
            <a
              class="btn-sequence-pdf"
              href="/files/milkomeda_full_story.pdf"
              download="밀코메다 풀스토리.pdf"
              >풀스토리 PDF다운로드 →</a
            >
          </div>
        </dt>
        <dd>
          <ul>
            <li>
              <div class="num">#1</div>
              <div class="scont">
                <div class="dtit">
                  <strong>2500년, 지구</strong>
                  <img src="~@/assets/image/ico/ico-seq-list01.svg" />
                </div>
                <div class="ddesc01">
                  환경은 더 이상 돌이킬 수 없을 정도로 파괴되고, 국지적 전쟁과
                  테러가 끊이지 않으며, 2-3년마다 새로운 전염병이 창궐하는 지구.
                </div>
                <div class="ddesc02">
                  빈부의 갈등은 점점 더 격화되어 저소득계층은 희망 없이 비참한
                  삶을 이어가고, 지역간/종교간/성별간 반목도 심화되어 보이지
                  않는 증오의 감정이 이중 삼중으로 지구를 뒤덮고 있다.
                </div>
              </div>
            </li>
            <li>
              <div class="num">#2</div>
              <div class="scont">
                <div class="dtit">
                  <strong>은하이주 프로젝트</strong>
                  <img src="~@/assets/image/ico/ico-seq-list02.svg" />
                </div>
                <div class="ddesc01">
                  우주 개발에 이어 블랙홀의 비밀을 풀고 시공간을 이동할 수 있게
                  된 슈퍼리치들.
                </div>
                <div class="ddesc02">
                  24억년 후 우리 은하와 안드로메다 은하의 충돌 후 새로운 하나의
                  은하로 모습을 갖추게 될 30억 년 후의 밀코메다 은하에서 증오와
                  전쟁이 없는 평화롭고 아름다운 새로운 세상을 만들고자 은하 이주
                  프로젝트를 론칭한다. 예술 애호가이자 후원가인 슈퍼리치들은
                  밀코메다 은하에 새로 생성되는 행성 중 10,000개의 행성에
                  10,000명을 파견하여 행성 개발 임무를 맡기기로 하고, 새로운
                  세상을 만드는데 필요한 자질과 능력을 가진 사람들을 선발한다
                </div>
              </div>
            </li>
            <li>
              <div class="num">#3</div>
              <div class="scont">
                <div class="dtit">
                  <strong>밀코메다 은하로 간 밀코인</strong>
                  <img src="~@/assets/image/ico/ico-seq-list03.svg" />
                </div>
                <div class="ddesc01">
                  아티스트/엔터테이너/이노베이터/디자이너/힐러의 5개 분야별
                  전문가들
                </div>
                <div class="ddesc02">
                  아티스트/엔터테이너/이노베이터/디자이너/힐러의 5개 분야별
                  전문가들로 구성된 10,000명의 밀코인들은 행성 개발의 임무를
                  띠고 은하 이주 프로젝트에서 준비한 ‘블루버진 스페이스’호를
                  타고 30억년 후 밀코메다 은하로 이동한다. 최종적으로 각자 분양
                  받은 행성에 도착한 밀코인은 자신의 행성을 개발하면서 다른
                  행성의 밀코인들과도 통신하며 서로를 도와 행성을 개발해 나간다
                </div>
              </div>
            </li>
            <li>
              <div class="num">#4</div>
              <div class="scont">
                <div class="dtit">
                  <strong>행성 개발</strong>
                  <img src="~@/assets/image/ico/ico-seq-list04.svg" />
                </div>
                <div class="ddesc01">
                  밀코인들은 은하 이주 프로젝트 헤드쿼터에 개발 진행 상황을
                  공유하고,필요한 물품과 장비를 보급받으며 개발이 어느 정도
                  진행되면 1차로 행성으로 이주할 지구인들을 받게 된다.
                </div>
                <div class="ddesc02">
                  또한, 이들은 각자 하나씩 식물의 씨앗을 가져와 행성에 심게
                  되는데 가장 인기 있는 작물은 단연코 와인을 만들 수 있는
                  포도나무이다. 하지만, 같은 품종이지만 전혀 다른 토양과
                  기후에서 자란 포도나무는 예상치 못한 결실을 맺게 된다.
                </div>
              </div>
            </li>
            <li>
              <div class="num">#5</div>
              <div class="scont">
                <div class="dtit">
                  <strong>다시 지구로</strong>
                </div>
                <div class="ddesc01">
                  모든 지구인이 이주해 올 수는 없기에 은하 이주 프로젝트는
                  과학적/문화적으로 번성한 밀코메다 은하에서 다시 뛰어난 몇 명을
                  뽑아 과거의 지구로 보내 파국을 막고자 한다.
                </div>
                <div class="ddesc02">
                  그렇게 다시 지구로 돌아가게 된 밀코인들은 지구 인류 역사상
                  위대한 인물로 기억되었으니, 바로 레오나르도 다빈치, 모차르트,
                  뉴턴, 스티브 잡스 등이다.
                </div>
              </div>
            </li>
          </ul>
        </dd>
      </dl>
    </section>

    <section id="home-sec04" class="home-concept-wrap">
      <div class="mdesc">5종족의 컨셉</div>
      <div class="mtit">CONCEPT TRIBES</div>
      <div class="oncept-slide-wrap">
        <!-- Swiper -->
        <Swiper
          id="concept-slide-wrap"
          class="swiper-container"
          :modules="[Navigation]"
          :slides-per-view="1"
          :space-between="0"
          :navigation="true"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <SwiperSlide class="swiper-slide">
            <div class="char-item-wrap">
              <div class="tbox type01">
                <div class="ttit">역할</div>
                <div class="tdesc">
                  창조, 행성 개발의 심미적 아름다움을 담당하고 <br />우주에서
                  영감을 받아 작품을 창조하여 공유
                </div>
              </div>
              <div class="tbox type02">
                <div class="ttit">악세서리</div>
                <div class="tdesc">붓, 스타일러스, 카메라 등</div>
              </div>
              <div class="tbox type03">
                <div class="ttit">종족특성</div>
                <div class="tdesc">바디페인팅</div>
              </div>

              <div class="tbox type05">
                <div class="ttit">뮤즈</div>
                <div class="tdesc">
                  미켈란젤로,피카소,고흐,마르셸 뒤샹, <br />모차르트, 비틀즈,
                  마이클 잭슨, 데이빗 보위, <br />메튜 본, 앤디 워홀, 백남준,
                  뱅크시, 라바랩스..
                </div>
              </div>
            </div>
            <div class="ctit">ARTIST</div>
            <div class="cdesc">
              화가, 조각가, 설치 미술가, 작곡가, 안무가, 사진가, 미디어 및
              디지털 아티스트
            </div>
          </SwiperSlide>

          <SwiperSlide class="swiper-slide t02">
            <div class="char-item-wrap">
              <div class="tbox type01">
                <div class="ttit">역할</div>
                <div class="tdesc">
                  화합, 아름다움을 표현함으로써 <br />다른 밀코인에게 감정의
                  고양과 일치의 <br />경험을 주어 서로 화합하도록 함
                </div>
              </div>
              <div class="tbox type02">
                <div class="ttit">악세서리</div>
                <div class="tdesc">
                  인이어, 헤드폰, <br />악기(플루트/기타), 토슈즈 등
                </div>
              </div>
              <div class="tbox type03">
                <div class="ttit">종족특성</div>
                <div class="tdesc">땋은 머리</div>
              </div>

              <div class="tbox type05">
                <div class="ttit">뮤즈</div>
                <div class="tdesc">
                  마리아 칼라스, 파가니니, 호로비츠, BTS, <br />블랙핑크. 조던,
                  메시, 페더러, 나달, <br />김연아, 찰리채플린
                </div>
              </div>
            </div>
            <div class="ctit">ENTERTAINER</div>
            <div class="cdesc">
              가수,연주가,무용가,연기자,디제이,프로듀서, 운동선수,게이머,
              인플루언서
            </div>
          </SwiperSlide>
          <SwiperSlide class="swiper-slide t03">
            <div class="char-item-wrap">
              <div class="tbox type01">
                <div class="ttit">역할</div>
                <div class="tdesc">
                  혁신, 새로운 발견을 통해 깨우침을 주고 <br />혁신을 주도하여
                  밀코메다에서의 삶의 방식을 <br />한 차원 끌어올림
                </div>
              </div>
              <div class="tbox type02">
                <div class="ttit">악세서리</div>
                <div class="tdesc">안경, 천체망원경,나침반, 지도 등</div>
              </div>
              <div class="tbox type03">
                <div class="ttit">종족특성</div>
                <div class="tdesc">수염</div>
              </div>

              <div class="tbox type05">
                <div class="ttit">뮤즈</div>
                <div class="tdesc">
                  코페르니쿠스, 갈릴레이, 레오나르도 다빈치, <br />다윈, 뉴턴,
                  스티븐 호킹, 라이트형제, <br />에디슨, 스티브 잡스, 일론머스크
                </div>
              </div>
            </div>
            <div class="ctit">INNOVATOR</div>
            <div class="cdesc">
              선구자, 혁신가, 과학자, 탐험가, 발명가, 우주인
            </div>
          </SwiperSlide>
          <SwiperSlide class="swiper-slide t04">
            <div class="char-item-wrap">
              <div class="tbox type01">
                <div class="ttit">역할</div>
                <div class="tdesc">
                  설계, 밀코메다에서의 도시, 환경 등 유형의 <br />인프라와 법,
                  제도 등 무형의 시스템을 설계
                </div>
              </div>
              <div class="tbox type02">
                <div class="ttit">악세서리</div>
                <div class="tdesc">펜, 컴퍼스, 메가폰, 자, 마우스 등</div>
              </div>
              <div class="tbox type03">
                <div class="ttit">종족특성</div>
                <div class="tdesc">단발 웨이브</div>
              </div>

              <div class="tbox type05">
                <div class="ttit">뮤즈</div>
                <div class="tdesc">
                  가우디, 프랭크게리, 쿨하스, 아우돌프, <br />셰익스피어,
                  헤밍웨이, 라거펠트, 샤넬, <br />크리스토퍼 놀란, 봉준호, 마크
                  저커버그
                </div>
              </div>
            </div>
            <div class="ctit">CREATOR</div>
            <div class="cdesc">
              건축가, 소설가, 영화감독, 패션 및 각종 디자이너, 개발자, 법률가
            </div>
          </SwiperSlide>
          <SwiperSlide class="swiper-slide t05">
            <div class="char-item-wrap">
              <div class="tbox type01">
                <div class="ttit">역할</div>
                <div class="tdesc">
                  치유, 뛰어난 통찰력과 치유력으로 <br />밀코인들의 정신적
                  풍요와 안정을 돕고 <br />평화롭고 평등한 세상을 만드는데 기여
                </div>
              </div>
              <div class="tbox type02">
                <div class="ttit">악세서리</div>
                <div class="tdesc">책 , 지팡이, 수정구슬, 카드 등</div>
              </div>
              <div class="tbox type03">
                <div class="ttit">종족특성</div>
                <div class="tdesc">아우라</div>
              </div>

              <div class="tbox type05">
                <div class="ttit">뮤즈</div>
                <div class="tdesc">
                  슈바이처, 간디, 만델라, 마틴 루터 킹, <br />레이첼 카슨,
                  그레타 툰베리, 프로스트
                </div>
              </div>
            </div>
            <div class="ctit">HEALER</div>
            <div class="cdesc">
              종교인, 초능력자, 의사, 환경운동가, 매지션, 예언가, 시인
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>

    <section class="home-style-wrap">
      <div class="mdesc">스타일</div>
      <div class="mtit">style</div>
      <div class="style-slide-wrap">
        <Swiper
          id="style-slide-wrap"
          class="swiper-container swiper-wrapper"
          :centeredSlides="true"
          slidesPerView="auto"
          :slideToClickedSlide="true"
          :initialSlide="2"
          :modules="[EffectCoverflow, Navigation, Controller]"
          :controller="{ control: secondSwiper }"
          :navigation="{
            nextEl: '#style-slide-wrap .swiper-button-next',
            prevEl: '#style-slide-wrap .swiper-button-prev',
          }"
          effect="coverflow"
          :coverflowEffect="{
            rotate: 0,
            stretch: 800,
            slideShadows: false,
          }"
          :breakpoints="{
            320: {
              coverflowEffect: {
                stretch: 320,
              },
            },
            // when window width is >= 640px
            1024: {
              coverflowEffect: {
                stretch: 800,
              },
            },
          }"
          @swiper="setFirstSwiper"
        >
          <SwiperSlide class="swiper-slide t05">
            <div class="slide-item-wrap">
              <img src="~@/assets/image/etc/etc-style-list05.svg" />
            </div>
          </SwiperSlide>
          <SwiperSlide class="swiper-slide t01">
            <div class="slide-item-wrap">
              <img src="~@/assets/image/etc/etc-style-list01.svg" />
            </div>
          </SwiperSlide>
          <SwiperSlide div class="swiper-slide t04">
            <div class="slide-item-wrap">
              <img src="~@/assets/image/etc/etc-style-list04.svg" />
            </div>
          </SwiperSlide>
          <SwiperSlide class="swiper-slide t02">
            <div class="slide-item-wrap">
              <img src="~@/assets/image/etc/etc-style-list02.svg" />
            </div>
          </SwiperSlide>
          <SwiperSlide class="swiper-slide t03">
            <div class="slide-item-wrap">
              <img src="~@/assets/image/etc/etc-style-list03.svg" />
            </div>
          </SwiperSlide>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </Swiper>
        <!-- <script>
          styleSlideWrap = new Swiper("#style-slide-wrap", {
            //loop: true,
            centeredSlides: true,
            slidesPerView: "auto",
            slideToClickedSlide: true,
            effect: "coverflow",
            initialSlide: 2,
            coverflowEffect: {
              rotate: 0,
              stretch: 800,
              slideShadows: false,
            },
            breakpoints: {
              320: {
                coverflowEffect: {
                  stretch: 320,
                },
              },
              // when window width is >= 640px
              1024: {
                coverflowEffect: {
                  stretch: 800,
                },
              },
            },
            navigation: {
              nextEl: "#style-slide-wrap .swiper-button-next",
              prevEl: "#style-slide-wrap .swiper-button-prev",
            },
            on: {
              slideChangeTransitionEnd: function () {
                switch (this.activeIndex) {
                  case 0:
                    if (storyMenuSlide) storyMenuSlide.slideTo(0);
                    break;
                  case 1:
                    if (storyMenuSlide) storyMenuSlide.slideTo(1);
                    break;
                  case 2:
                    if (storyMenuSlide) storyMenuSlide.slideTo(2);
                    break;
                  case 3:
                    if (storyMenuSlide) storyMenuSlide.slideTo(3);
                    break;
                  case 4:
                    if (storyMenuSlide) storyMenuSlide.slideTo(4);
                    break;
                  default:
                  // code block
                }
              },
            },
          });
        </script> -->
      </div>
      <div class="menu-slide-wrap">
        <!-- Swiper -->
        <Swiper
          id="story-menu-slide"
          class="swiper-container"
          :slides-per-view="1"
          :spaceBetween="0"
          :modules="[Controller]"
          :controller="{ control: firstSwiper }"
          @swiper="setSecondSwiper"
        >
          <SwiperSlide class="swiper-slide t01">
            <a href="javascript:void(0)" data-cnt="0">
              <img src="~@/assets/image/ico/ico-artist.svg" />
              <div class="stxt">Artist</div>
            </a>
          </SwiperSlide>
          <SwiperSlide class="swiper-slide t02">
            <a href="javascript:void(0)" data-cnt="1">
              <img src="~@/assets/image/ico/ico-innovator.svg" />
              <div class="stxt">Innovator</div>
            </a>
          </SwiperSlide>
          <SwiperSlide class="swiper-slide t03">
            <a href="javascript:void(0)" data-cnt="2">
              <img src="~@/assets/image/ico/ico-entertainer.svg" />
              <div class="stxt">Entertainer</div>
            </a>
          </SwiperSlide>
          <SwiperSlide class="swiper-slide t04">
            <a href="javascript:void(0)" data-cnt="3">
              <img src="~@/assets/image/ico/ico-creator.svg" />
              <div class="stxt">Creator</div>
            </a>
          </SwiperSlide>
          <SwiperSlide class="swiper-slide t05">
            <a href="javascript:void(0)" data-cnt="4">
              <img src="~@/assets/image/ico/ico-healer.svg" />
              <div class="stxt">Healer</div>
            </a>
          </SwiperSlide>
        </Swiper>
        <!-- <script>
          storyMenuSlide = new Swiper("#story-menu-slide", {
            slidesPerView: "1",
            //loop:true,
            spaceBetween: 0,
            initialSlide: 2,
            on: {
              slideChangeTransitionEnd: function () {
                $(".character-slide-wrap").removeClass("on");

                switch (this.activeIndex) {
                  case 0:
                    if (styleSlideWrap) styleSlideWrap.slideTo(0);
                    break;
                  case 1:
                    if (styleSlideWrap) styleSlideWrap.slideTo(1);
                    break;
                  case 2:
                    if (styleSlideWrap) styleSlideWrap.slideTo(2);
                    break;
                  case 3:
                    if (styleSlideWrap) styleSlideWrap.slideTo(3);
                    break;
                  case 4:
                    if (styleSlideWrap) styleSlideWrap.slideTo(4);
                    break;
                  default:
                  // code block
                }
              },
            },
            //centerMode:true
          });
        </script> -->
      </div>
    </section>

    <section id="home-sec05" class="home-story-wrap">
      <div class="mdesc">웹툰</div>
      <div class="mtit">STORY</div>
      <div class="story-list-wrap">
        <ul>
          <li>
            <a href="javascript:void(0)" class="on" data-id="webt-cont01">
              <img src="~@/assets/image/ico/ico-story-char-list01.svg" />
              <span>Jisu</span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" data-id="webt-cont02">
              <img src="~@/assets/image/ico/ico-story-char-list02.svg" />
              <span>Tan</span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" data-id="webt-cont03">
              <img src="~@/assets/image/ico/ico-story-char-list03.svg" />
              <span>Steve</span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" data-id="webt-cont04">
              <img src="~@/assets/image/ico/ico-story-char-list05.svg" />
              <span>Carrie</span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" data-id="webt-cont05">
              <img src="~@/assets/image/ico/ico-story-char-list04.svg" />
              <span>Andy</span>
            </a>
          </li>
        </ul>
      </div>
      <div id="webt-cont01" class="webt-item-wrap webt-area on">
        <div
          class="spic"
          :style="{
            background: `url(${require('@/assets/image/etc/etc-stroy-pic01.jpg')})`,
          }"
        >
          <button type="button" onclick="common.popOpen('#pop-webt01')">
            웹툰 보기 →
          </button>
        </div>
        <div class="stit">'새로운 별#1 : 엔터테이너 지수'</div>
        <div class="sdesc">
          까마득한 어둠 속에 떠있는 별들의 바다. 눈뜬 지수는 눈앞에 펼져진 깊은
          어둠에 추락할 듯 한 현기증을 느끼며 흠칫 놀랐다. <br />‘아 여기는 내
          행성이지…’ <br />밀코 1에 도착한 행성 시간으로10일 지구 시간으로
          30일이 지났지만 <br />앤디는 아직도 깨어날 때 마다 자신이 어디에
          있는지를 인지하는 것으로 한나절을 시작해야 했다.
        </div>
      </div>
      <div id="webt-cont02" class="webt-item-wrap webt-area">
        <div
          class="spic"
          :style="{
            background: `url(${require('@/assets/image/etc/etc-stroy-pic02.jpg')}`,
          }"
        >
          <button type="button" onclick="common.popOpen('#pop-webt02')">
            웹툰 보기 →
          </button>
        </div>
        <div class="stit">'탐사#2 : 힐러 탄'</div>
        <div class="sdesc">
          수박크기만한 빗방울이 떨어진다. <br />사람크기만큼 커지는 잎사귀가
          빗방울을 막아주는 완충역할을 했고, 태어나 처음 들어보는 아름다운
          소리가 터져나왔다. <br />이건 빗방울이 만들어내는 장엄한
          오케스트라였다. 최초 지구의 자연도 이런 완벽한 조화를 이루는 연주를
          뿜어냈겠지. <br />이 놀라운 광경을 본 탄의 표정은 아름다운 충격을 받은
          그 모습이었다.
        </div>
      </div>
      <div id="webt-cont03" class="webt-item-wrap webt-area">
        <div
          class="spic"
          :style="{
            background: `url(${require('@/assets/image/etc/etc-stroy-pic03.jpg')}`,
          }"
        >
          <button type="button" onclick="common.popOpen('#pop-webt03')">
            웹툰 보기 →
          </button>
        </div>
        <div class="stit">'씨앗#3 : 이노베이터 스티브'</div>
        <div class="sdesc">
          다른 행성에서 표류하며 거버넌스와 통신이 끊긴지 50일 째,
          <br />이노베이터 스티브는 식물재배를 하며 나름 자유로운 생활을
          이어나간다. <br />하지만 스티브가 있는 행성에서는 토양이 비옥하지 않아
          식물재배에 어려움을 겪고 있다. <br />수많은 도전 속 알록달록한 포도를
          보며 희망을 본 스티브. <br />하지만 기쁨도 잠시, 기다리던 비가 아닌
          갑작스러운 모래폭풍을 만나게 되는데..
        </div>
      </div>
      <div id="webt-cont04" class="webt-item-wrap webt-area">
        <div
          class="spic"
          :style="{
            background: `url(${require('@/assets/image/etc/etc-stroy-pic04.jpg')}`,
          }"
        >
          <!-- src/assets/image/etc/etc-stroy-pic04.jpg -->
          <button type="button" onclick="common.popOpen('#pop-webt04')">
            웹툰 보기 →
          </button>
        </div>
        <div class="stit">'작품#4 : 크리에이터'</div>
        <div class="sdesc">밀코 행성 크리에이터의 sequence를 기대해주세요!</div>
      </div>
      <div id="webt-cont05" class="webt-item-wrap webt-area">
        <div
          class="spic"
          :style="{
            background: `url(${require('@/assets/image/etc/etc-stroy-pic05.jpg')}`,
          }"
        >
          <button type="button" onclick="common.popOpen('#pop-webt05')">
            웹툰 보기 →
          </button>
        </div>
        <div class="stit">'통신#5 : 아티스트 앤디'</div>
        <div class="sdesc">밀코 행성 크리에이터의 sequence를 기대해주세요!</div>
      </div>
    </section>

    <section id="home-sec06" class="home-minting-wrap">
      <div class="mdesc">민팅</div>
      <div class="mtit">MINTING</div>
      <div class="mnt-item-wrap">
        <div class="mn-tit">
          <strong>서울 옥션 강남 센터</strong>
          <span>밀코메다 N 오프라인 전시회!</span>
        </div>
        <div class="mn-desc">
          밀코메다 NFT를 오프라인에서 <br />먼저 만나 볼 수 있는 기회!
        </div>
        <div class="mn-cont-wrap">
          <div class="mn-pic-wrap">
            <div class="mn-lt-wrap">
              <img src="~@/assets/image/etc/etc-mn-left.png" />
            </div>
            <div class="mn-rt-wrap">
              <img src="~@/assets/image/etc/etc-mn-right.png" />
            </div>
          </div>
          <ul class="mn-date-list">
            <li>
              <div class="key">일시</div>
              <div class="value">2022년 9월 21일~25일</div>
            </li>
            <li>
              <div class="key">장소</div>
              <div class="value">
                서울특별시 강남구 언주로 864
                <strong>(서울 옥션 강남 센터)</strong>
              </div>
            </li>
          </ul>
          <dl>
            <dt>오프라인 전시 <strong>원화 판매</strong></dt>
            <dd>
              <div class="dtit">2022년 9월 21일</div>
              <div class="ddesc">VIP 판매가 원화 <strong>100</strong>만원</div>
              <div class="dtit t01">2022년 9월 22일~25일</div>
              <div class="ddesc t01">판매가 원화 <strong>110</strong>만원</div>
            </dd>
          </dl>
        </div>
      </div>

      <div class="mn-count-list-wrap">
        <ul>
          <li>
            <div class="mn-lt-wrap">
              <div class="mn-header">
                <img src="~@/assets/image/ico/ico-entertainer.svg" />
                <div class="ocate">민팅종족</div>
                <div class="otit">ENTERTAINER</div>
              </div>
              <div class="mn-pic">
                <img src="~@/assets/image/etc/etc-mn-count-list01.svg" />
              </div>
            </div>
            <div class="mn-rt-wrap">
              <div class="snum">1차</div>
              <div class="sdate">00월 00일 00시</div>
              <div class="skey">민팅 가격</div>
              <div class="svalue">
                <strong class="s01">원화 000만원</strong> 기준
                <strong>이더리움</strong>
              </div>
              <div class="setc">(당일 아침 9시 기준)</div>
            </div>
            <div class="lock-item-wrap">
              <div class="scont">
                <img src="~@/assets/image/ico/ico-check.svg" />
                <div class="scnt">1차</div>
                <div class="sdate">민팅 완료</div>
              </div>
            </div>            
          </li>
          <li>
            <div class="mn-lt-wrap">
              <div class="mn-header">
                <img src="~@/assets/image/ico/ico-healer.svg" />
                <div class="ocate">민팅종족</div>
                <div class="otit">HEALER</div>
              </div>
              <div class="mn-pic">
                <img src="~@/assets/image/etc/etc-mn-count-list02.svg" />
              </div>
            </div>
            <div class="mn-rt-wrap">
              <div class="snum">2차</div>
              <div class="sdate">00월 00일 00시</div>
              <div class="skey">민팅 가격</div>
              <div class="svalue">
                <strong class="s01">원화 000만원</strong> 기준
                <strong>이더리움</strong>
              </div>
              <div class="setc">(당일 아침 9시 기준)</div>
            </div>
            <div class="lock-item-wrap">
              <div class="scont">
                <img src="~@/assets/image/ico/ico-check.svg" />
                <div class="scnt">2차</div>
                <div class="sdate">민팅 완료</div>
              </div>
            </div>
          </li>
          <li>
            <div class="mn-lt-wrap">
              <div class="mn-header">
                <img src="~@/assets/image/ico/ico-innovator.svg" />
                <div class="ocate">민팅종족</div>
                <div class="otit">INNOVATOR</div>
              </div>
              <div class="mn-pic">
                <img src="~@/assets/image/etc/etc-mn-count-list03.svg" />
              </div>
            </div>
            <div class="mn-rt-wrap">
              <div class="snum">3차</div>
              <div class="sdate">00월 00일 00시</div>
              <div class="skey">민팅 가격</div>
              <div class="svalue">
                <strong class="s01">원화 000만원</strong> 기준
                <strong>이더리움</strong>
              </div>
              <div class="setc">(당일 아침 9시 기준)</div>
            </div>
            <div class="lock-item-wrap">
                <div class="scont">
                    <img src="~@/assets/image/ico/ico-check.svg" />
                    <div class="scnt">3차</div>
                    <div class="sdate">민팅 완료</div>
                </div>
            </div>    
          </li>
          <li>
            <div class="mn-lt-wrap">
              <div class="mn-header">
                <img src="~@/assets/image/ico/ico-creator.svg" />
                <div class="ocate">민팅종족</div>
                <div class="otit">CREATOR</div>
              </div>
              <div class="mn-pic">
                <img src="~@/assets/image/etc/etc-mn-count-list05.svg" />
              </div>
            </div>
            <div class="mn-rt-wrap">
              <div class="snum">4차</div>
              <div class="sdate">00월 00일 00시</div>
              <div class="skey">민팅 가격</div>
              <div class="svalue">
                <strong class="s01">원화 000만원</strong> 기준
                <strong>이더리움</strong>
              </div>
              <div class="setc">(당일 아침 9시 기준)</div>
            </div>
            <div class="lock-item-wrap">
              <div class="scont">
                <img src="~@/assets/image/ico/ico-check.svg" />
                <div class="scnt">4차</div>
                <div class="sdate">민팅 완료</div>
              </div>
            </div>
          </li>
          <li>
            <div class="mn-lt-wrap">
              <div class="mn-header">
                <img src="~@/assets/image/ico/ico-artist.svg" />
                <div class="ocate">민팅종족</div>
                <div class="otit">ARTIST</div>
              </div>
              <div class="mn-pic">
                <img src="~@/assets/image/etc/etc-mn-count-list04.svg" />
              </div>
            </div>
            <div class="mn-rt-wrap">
              <div class="snum">5차</div>
              <div class="sdate">00월 00일 00시</div>
              <div class="skey">민팅 가격</div>
              <div class="svalue">
                <strong class="s01">원화 000만원</strong> 기준
                <strong>이더리움</strong>
              </div>
              <div class="setc">(당일 아침 9시 기준)</div>
            </div>
            <div class="lock-item-wrap">
              <div class="scont">
                <img src="~@/assets/image/ico/ico-check.svg" />
                <div class="scnt">5차</div>
                <div class="sdate">민팅 완료</div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="help-item-wrap">
        <div class="htit">안내 사항</div>
        <div class="hdesc01">
          <strong>예술에 영향력을 가진 셀럽들과의 콜라보 이벤트는 계속됩니다.</strong>
          <!-- <strong>팀물량은 1,000</strong> 개입니다 -->
          <!-- <span>(레전더리 100개,셀럽 홀더 물량 포함)</span> -->
        </div>
        <!-- <div class="hdesc04"> -->
          <!-- (밀코메다 홈페이지 내 회원가입을 반드시 해주셔야 됩니다) -->
        <!-- </div> -->
      </div>
      
      <!-- <div class="cta-wrapper"> -->
        <!-- <button -->
          <!-- class="btn milkn-btn milkn-btn-blue btn-minting" -->
          <!-- @click="onClickMinting" -->
          <!-- :disabled="!currentEpisode" -->
        <!-- > -->
          <!-- 민팅 바로가기 -->
        <!-- </button> -->

        <!-- <div class="pc-only-text">PC 환경에서만 가능합니다</div> -->
      <!-- </div> -->
    </section>

    <Footer />

    <CountDown />
    <HomeQuickMenus />
  </div>

  <DialogsWebtoons />
</template>


<!-- eslint-disable no-undef -->
<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import Footer from "@/components/Footer";
import { EffectCoverflow, Navigation, Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/scss/navigation";
import CountDown from "@/components/FabButtons/CountDown.vue";
import HomeQuickMenus from "@/components/FabButtons/HomeQuickMenus.vue";
import DialogsWebtoons from "@/components/Dialogs/DialogsWebtoons.vue";
import { useMintingStore } from "@/store/mintingStore.js";

const router = useRouter();
const mintingStore = useMintingStore();
const { episodeList, currentEpisode, upcomingEpisode } =
  storeToRefs(mintingStore);

// const activeIndex = ref(0);
const isMounted = ref(false);
const v_player = ref(null);
const isPlayingVideo = ref(false);
const firstSwiper = ref(null);
const secondSwiper = ref(null);
const onSwiper = (swiper) => {
  console.log(swiper);
};
const onSlideChange = () => {
  console.log("slide change");
};
const setFirstSwiper = (swiper) => {
  firstSwiper.value = swiper;
};
const setSecondSwiper = (swiper) => {
  secondSwiper.value = swiper;
};

const handleClickVideo = () => {
  if (isPlayingVideo.value) {
    v_player.value.pause();
  } else {
    v_player.value.play();
  }

  isPlayingVideo.value = !isPlayingVideo.value;
};

onMounted(() => {
  isMounted.value = true;

  $(window).scroll(
    $.throttle(100, function () {
      if (isMounted.value) {
        const homeSec01 = $("#home-sec01").offset();
        if (!homeSec01) {
          return;
        }

        var sec01 = $("#home-sec01").offset().top - 70;
        var sec02 = $("#home-sec02").offset().top - 70;
        var sec03 = $("#home-sec03").offset().top - 70;
        var sec04 = $("#home-sec04").offset().top - 70;
        var sec05 = $("#home-sec05").offset().top - 70;
        var sec06 = $("#home-sec06").offset().top - 70;
        var _st = $(window).scrollTop();

        if (_st > sec01) {
          $(".fixed-menu-wrap a").removeClass("on");
          $(".fixed-menu-wrap a").eq(0).addClass("on");
        }
        if (_st > sec02) {
          $(".fixed-menu-wrap a").removeClass("on");
          $(".fixed-menu-wrap a").eq(1).addClass("on");
        }
        if (_st > sec03) {
          $(".fixed-menu-wrap a").removeClass("on");
          $(".fixed-menu-wrap a").eq(2).addClass("on");
        }
        if (_st > sec04) {
          $(".fixed-menu-wrap a").removeClass("on");
          $(".fixed-menu-wrap a").eq(3).addClass("on");
        }
        if (_st > sec05) {
          $(".fixed-menu-wrap a").removeClass("on");
          $(".fixed-menu-wrap a").eq(4).addClass("on");
        }
        if (_st > sec06) {
          $(".fixed-menu-wrap a").removeClass("on");
          $(".fixed-menu-wrap a").eq(5).addClass("on");
        }
      }
    })
  );
});

onBeforeUnmount(() => {
  isMounted.value = false;
});

const onClickMinting = () => {


  router.push({
    name: "Minting",
    query: {
      EPISODE_SEQ: mintingStore.currentEpisode.EPISODE_SEQ,
      NFT_SEQ: mintingStore.currentEpisode.NFT_SEQ,
      EPISODE_INDEX: mintingStore.currentEpisode.episodeIndex,
    },
  });
};
</script>

<style lang="scss" scoped>
.cta-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3em 0;

  & .btn-cta {
    width: 20rem;
    margin-bottom: 1em;
  }
}

*,
*::before,
*::after {
  box-sizing: unset;
}

.home-milkn-wrap .home-char-list ul li .sname {
  margin-top: 14px;
}

.home-sequence-wrap dl dt .sequence-title {
  font-family: "ethnocentric-rg";
}

.btn-minting {
  width: 16em;
  margin: 0 auto;
  margin-bottom: 0.4em;
  padding: 1em;
  font-size: 1.4em;
  font-family: "Cafe24Dangdanghae";

  &.btn:disabled {
    background-color: #28e3d1 !important;
    color: rgb(155, 155, 155) !important;
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    border: none;
    cursor: not-allowed;
  }
}

.home-minting-wrap {
  display: flex;
  flex-direction: column;
}

.pc-only-text {
  display: none;
}

@media (max-width: 1280px) {
  .pc-only-text {
    display: block;
  }
}
</style>

<template>
  <div class="fixed-menu-wrap">
    <ul>
      <li>
        <a href="javascript:void(0)" class="on" data-id="home-sec01">
          <span>Intro</span>
          <img class="bg" src="@/assets/image/etc/etc-fixed-menu-on.svg" />
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" data-id="home-sec02">
          <span>3D<br />Teaser</span>
          <img class="bg" src="@/assets/image/etc/etc-fixed-menu-on.svg" />
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" data-id="home-sec03">
          <span>Sequence</span>
          <img class="bg" src="@/assets/image/etc/etc-fixed-menu-on.svg" />
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" data-id="home-sec04">
          <span>Concept</span>
          <img class="bg" src="@/assets/image/etc/etc-fixed-menu-on.svg" />
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" data-id="home-sec05">
          <span>Story</span>
          <img class="bg" src="@/assets/image/etc/etc-fixed-menu-on.svg" />
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" data-id="home-sec06">
          <span>Minting</span>
          <img class="bg" src="@/assets/image/etc/etc-fixed-menu-on.svg" />
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" class="btn-topbtn" data-id="home-top">
          <span>Top ▲</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
</style>
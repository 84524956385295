import { markRaw } from 'vue'
import { defineStore } from 'pinia'
import { shortenAddress } from '@/utils/wallet.js'
import { add } from 'mathjs'

export const useWeb3Store = defineStore('web3', {
  state: () => ({
    web3Api: {
      provider: null,
      web3: null,
      contract: null,
      isProviderLoaded: false,
    },
    chainId: null,
    address: null,
    account: { balance: 0 },
    numberOfMyOwnNfts: 0,
    contract: {
      maxSupply: 0,
      lockNft: false,
      maxMintAmount: 0,
      maxMintPerSale: null,
      baseUri: null,
      notRevealedUri: null,
      price: null,
      royaltyFee: null,
      tokenId: 0,
    },
    isChecked: false,
  }),
  getters: {
    isWalletConnected(state) {
      return (state.address && state.address.length > 0) || false
    },
    shortenedAddress(state) {
      if (!state.address) {
        return ''
      }

      return shortenAddress(state.address)
    },
    myBalanceInEth(state) {
      if (!state.web3Api.web3) return '0'

      return state.web3Api.web3.utils
        .fromWei(state.account.balance.toString(), 'ether')
        .toString()
    },
  },
  actions: {
    async onClickConnect() {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })
      this.setAddress(accounts[0])
    },
    setWeb3Api(web3Api) {
      this.web3Api = markRaw(web3Api)
    },

    setAddress(address) {
      if (address) {
        this.address = address.toLowerCase()
        sessionStorage.setItem('ADDRESS', address)
      } else {
        sessionStorage.removeItem('ADDRESS')
      }
    },
    setChainId(chainIdInDecimal) {
      this.chainId = Math.abs(chainIdInDecimal).toString(16)
    },
    setBalance(balance) {
      this.account.balance = balance
    },

    setContract(contract) {
      this.contract = contract
    },

    setNumberOfMyOwnNfts(num) {
      this.numberOfMyOwnNfts = num
    },

    async refreshContractInfo() {
      const { contract } = this.web3Api

      const result = await contract.getContractInfo()

      const tokenId = result[4].toNumber()
      const {
        maxSupply,
        lockNft,
        maxMintPerSale,
        maxMintAmount,
        baseUri,
        notRevealedUri,
        price,
        publicMintEnabled,
        royaltyFee,
      } = result[0]

      this.setContract({
        maxSupply: Number.parseInt(maxSupply),
        lockNft,
        maxMintPerSale: Number.parseInt(maxMintPerSale),
        maxMintAmount: Number.parseInt(maxMintAmount),
        baseUri,
        notRevealedUri,
        price: Number.parseFloat(price),
        publicMintEnabled: publicMintEnabled,
        royaltyFee: Number.parseFloat(royaltyFee),
        tokenId,
      })
    },

    async getUserWalletOfOwner(walletAddress) {
      const { contract } = this.web3Api
      const result = await contract.walletOfOwner(walletAddress)
      return result
    },

    async getTotalSupply(){ // 총 발행 수, 민팅시 해당 번호까지 S3파일 public 전환
      const { contract } = this.web3Api
      const result = await contract.totalSupply()
      return result
    },
  },
  // persist: {
  //   enabled: true,
  //   strategies: [{ storage: sessionStorage, paths: ['address'] }],
  // },
})

import staticFullStoryData_1 from './staticFullStoryData_1';
import staticFullStoryData_2 from './staticFullStoryData_2';
import staticFullStoryData_3 from './staticFullStoryData_3';
import staticFullStoryData_4 from './staticFullStoryData_4';
import staticFullStoryData_5 from './staticFullStoryData_5';
import staticFullStoryData_6 from './staticFullStoryData_6';
import staticFullStoryData_7 from './staticFullStoryData_7';
import staticFullStoryData_8 from './staticFullStoryData_8';
import staticFullStoryData_9 from './staticFullStoryData_9';
import staticFullStoryData_10 from './staticFullStoryData_10';
import staticFullStoryData_11 from './staticFullStoryData_11';
import staticFullStoryData_12 from './staticFullStoryData_12';
import staticFullStoryData_13 from './staticFullStoryData_13';
import staticFullStoryData_14 from './staticFullStoryData_14';
import staticFullStoryData_15 from './staticFullStoryData_15';
import staticFullStoryData_16 from './staticFullStoryData_16';

const staticFullStoryData = [
		staticFullStoryData_1,
		staticFullStoryData_2,
		staticFullStoryData_3,
		staticFullStoryData_4,
		staticFullStoryData_5,
		staticFullStoryData_6,
		staticFullStoryData_7,
		staticFullStoryData_8,
		staticFullStoryData_9,
		staticFullStoryData_10,
		staticFullStoryData_11,
		staticFullStoryData_12,
		staticFullStoryData_13,
		staticFullStoryData_14,
		staticFullStoryData_15,
		staticFullStoryData_16,
]
export default staticFullStoryData

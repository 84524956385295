const staticFullStoryData_11 = 		{
		id : 11,
		title: "크리에이터-캐리 (1일차)",
		body: `<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'><img width=373
height=290 id="그림 3" src="/image/story11/image001.jpg"></span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white;font-weight:bold'>11화 #. 크리에이터 - 캐리 (1일 차)</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>5</span><span
style='line-height:160%;color:white'>년 전</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>밀코메다 이주 프로젝트의 일원으로 선발되어 연구소에서 훈련받기 시작할 때부터 각자 행성으로 가면 해야 하는 큰 맥락은 정해져
있었다</span><span lang=EN-US style='line-height:160%;color:white'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>정착하기</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>탐사하기</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>연구소에서 지급한 금빛 씨앗을 심기</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>작품을 만들기</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>모든 일이 마무리되면 본부에 보고하기 등</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캐리는 매뉴얼을 받은 첫날부터 밥을 먹을 때나 잠자기
직전에도 단 한 번 눈을 떼지 않았다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span><span style='line-height:160%;color:white'>만약 훈련하는 등 볼 수 없는 상황이라면 머릿속으로 끊임없이
되새기며 생각을 멈추는 일이 없었다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>남들은 모르지만 자신의 성격에는 유독 눈에 띄는
두 단어가 있었기에</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>정착과 탐사라</span><span lang=EN-US
style='line-height:160%;color:white'>...’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>두 과정은 바로 잇달아 있지만 거버넌스는 굳이 분리를
시켜 놓았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>건축가인 캐리 입장에선 새로운 곳에 정착하기 위해선
주변을 돌아다녀서 위험 요소를 살펴보아야 하고</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>그러한 행동은 탐사라 볼 수
있었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그런데 그 두 가지를 구분해서 하라니 이보다 어려운
일이 있을까 싶은 심정이다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하지만 그렇게 끊임없이 고민해본 덕분에</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>캐리는 블루버진 스페이스호를 타고 밀코웨이를 지나 행성에 도착하기 훨씬 전에
이미 할 일에 대해 정리할 수 있었고</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>계획대로 행성에 도착한 첫날부터 망설임 없이 로버를
타고 주변을 둘러보기 시작했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>정착</span><span lang=EN-US
style='line-height:160%;color:white'>’ </span><span style='line-height:160%;
color:white'>하기 위해 낯선 행성의 주위를 둘러보는 건 당연한 과정</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>탐사</span><span lang=EN-US
style='line-height:160%;color:white'>’</span><span style='line-height:160%;
color:white'>는 주변 식물의 성분을 분석해보거나 미리 지정해 놓은 의문의 구역에 깊숙하게 들어가 보는 것 등이라고 구분한 것이다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>만약 연구소부터 확실하게 생각을 정리하지 않았다면
시간을 낭비했을지도 모른다 생각하며 캐리는 스스로가 감탄스러웠다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하루라도 늦게 움직였다면 이 멋진 풍경을 놓쳤을
테니</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>안 그래</span><span lang=EN-US
style='line-height:160%;color:white'>? </span><span style='line-height:160%;
color:white'>가우디</span><span lang=EN-US style='line-height:160%;color:white'>?”
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캐리는 로버의 옆자리에 앉아있는 애완 로봇 카멜레온에게
물었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>가우디 역시 시원한 바람에 기분이 좋은지 숨을 크게
들이시는 게 보였다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지구로 따지면 가을과도 같은 날씨</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>은은하게 비추는 주황색 불빛은 하얀색으로 덮인 행성 전체를 안락하게 만들었고
어두운 하늘에 자리한 오향색의 오로라는 황홀했다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캐리는 행성을 조금 더 자세하게 느끼기 위해 자율
주행으로 되어 있는 로버를 오프로드로 바꾸고 핸들을 양손으로 잡았다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러자 얇은 틈을 두고 허공에 떠 있던 로버의 바퀴가
땅에 닿았고 바퀴로 고스란히 전해지는 행성의 거친 지면의 느낌이 퍽 괜찮았다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>대학 졸업을 앞두고 북미 대륙을 횡단하던 때만큼</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그 시기는 캐리의 인생에서 손꼽을 수 있을 정도로
행복한 때였다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>신선한 감각의 건축 디자이너로 크게 인정받기도 했고
점차 입지를 다져갔으며 건축을 통해 번 돈으로 전 세계를 여행하고 시야를 넓힐 수 있었으니까</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>또한 친구로 지내던 같은 업종의 피터와 결혼도 성공했는데</span><span
lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>행복이 길진 않았다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>같은 건축가였던 피터와 건축에 대한 열정을 공유하기도
했지만 생각하는 건축의 방향이 너무 달라서 프로젝트를 할 때마다 싸웠고 결국 </span><span lang=EN-US
style='line-height:160%;color:white'>3</span><span style='line-height:160%;
color:white'>년이라는 짧은 결혼 생활을 끝으로 이혼하게 됐다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>더구나 그 시기부터 메타버스가 실제 하나의 삶의
양식으로 받아들여지며 수많은 직업이 메타버스 세상 속에서 새로 탄생했기에</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>실물 건축가로서 기껏 이루어놓은 캐리의 입지가 불안정해졌다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 캐리는 행복과 불행이 번갈아 오는 것처럼</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>변해가는 시대의 흐름을 바꿀 수 없다는 사실을 잘 알고 있었다</span><span
lang=EN-US style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>억지로 시대의 흐름을 따라가려 하지 않고 실물 건축가로서 내 자리를 지미고 있다면
언젠가 다시 빛을 바라는 순간이 찾아오겠지</span><span lang=EN-US style='line-height:160%;
color:white'>.’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>신선한 감각을 지닌 실물 건축 디자이너라는 자리에서
언젠가 다시 기회가 찾아오길 기다렸다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러던 때</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>캐리는 은하 이주 프로젝트의 크립토 공고문을 받게 되면서 새로운 문이 열렸다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>나의 행성에서</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>나의 건축물을 짓고</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>나의 세상을 만든다</span><span
lang=EN-US style='line-height:160%;color:white'>?’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이전까지의 메타버스는 가상의 세계처럼 느껴졌지만
크립토 공고문의 밀코메다 행성은 현실이고</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>그곳이라면 옛날의 위대한 건축가들의
계보를 이어 나갈 수 있을 거라는 확신이 들었기 때문에 반가웠다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>꿈만 같은 기회에 캐리는 망설이지 않고 지원했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그 이후로 거버넌스의 연구실에서 밀코메다</span><span
lang=EN-US style='line-height:160%;color:white'>N </span><span
style='line-height:160%;color:white'>프로젝트를 위해 훈련을 받은 </span><span lang=EN-US
style='line-height:160%;color:white'>5</span><span style='line-height:160%;
color:white'>년이라는 기간이 있었기에</span><span lang=EN-US style='line-height:160%;
color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>마침내 행성에 도착한 캐리는 한시라도 빨리 이곳에서
실물 건축을 짓고 싶어 마음이 들떴다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>저 다리 옆에는 물가에 사는 동물들이 가까이에서 치료받을 수 있도록</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>어류 동물 병원을 지어야지</span><span lang=EN-US
style='line-height:160%;color:white'>! </span><span style='line-height:160%;
color:white'>그리고 대지가 넓은 저 평야에는 한옥 마을을 지을 거야</span><span lang=EN-US
style='line-height:160%;color:white'>! </span><span style='line-height:160%;
color:white'>나무로 된 집들은 전부 사라져서 사진으로밖에 느껴보지 못했으니까</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>추억복구</span><span lang=EN-US style='line-height:160%;color:white'>!
</span><span style='line-height:160%;color:white'>레트로</span><span lang=EN-US
style='line-height:160%;color:white'>!’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>로버를 타고 행성을 넓게 돌아다니던 캐리는 한군데씩
원하는 건축물을 생각해보았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>짓고 싶은 건축물이 너무 많아서 머리에서 생각나는
아이디어를 전부 기억할 수 있을까 걱정될 만큼</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>가슴이 뛰고 흥분될 때에<span
lang=EN-US>,</span></span><span lang=EN-US style='line-height:160%;color:white'>
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>갑자기 하얀 안개가 펼쳐지며 묘하게 온도가 내려갔다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>이게</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>어떻게 된 거지</span><span lang=EN-US style='line-height:160%;
color:white'>? </span><span style='line-height:160%;color:white'>지형이 새하얀 것과 연관이
있나</span><span lang=EN-US style='line-height:160%;color:white'>?” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캐리는 급하게 로버를 세우고 내리며 주위를 둘러보았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>안개가 점점 짙어졌기에 운전을 하는 것은 위험하다고
판단했기 때문이다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>자율 주행으로 하더라도 행성에 사는 다른 생물이
있을 수 있고</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>부딪힐 수 있다는 요소를 제외할 순 없으니까</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>심지어 지금보다 더 짙어져서 앞을 보기 어려울 것 같다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그렇게 캐리의 예상은 하나둘씩 들어맞았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>첫 번째로 노천탕을 온 것처럼 옅게 퍼져있던 안개가
급격히 짙어져서 바로 눈앞에 둔 손바닥도 보이지 않았고</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>등 뒤에선 인기척이 느껴졌다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>휙</span><span lang=EN-US
style='line-height:160%;color:white'>- </span><span style='line-height:160%;
color:white'>휙</span><span lang=EN-US style='line-height:160%;color:white'>- </span><span
style='line-height:160%;color:white'>휙</span><span lang=EN-US style='line-height:
160%;color:white'>- </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아주 빠르고 날렵한 느낌</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>크기는 크지 않았지만 벌이나 뱀처럼 맹독을 가진
존재일 수도 있었기에 안심할 순 없었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그때</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>캐리의 주위를 맴돌기만 했던 인기척이 그녀의 얼굴을 향해 달려들었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>퍽</span><span lang=EN-US
style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>으악</span><span lang=EN-US
style='line-height:160%;color:white'>!!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>놀란 캐리는 뒤로 자빠졌고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>뒤에 있던 로버에 걸려서 엎어졌다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>다행히 로버의 푹신한 안장에 엎어졌기에 망정이지
반대였다면 우주복 헬멧이 작은 실금이 났을 수도 있다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아직 탐사를 마친 행성이 아니라 우주복을 벗어도
되는지 확실하지도 않은데</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>그야말로 위험천만한 상황이었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캐리는 자기 얼굴에 붙어있는 카멜레온 가우디를 떼어내며
안도했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>너였냐</span><span lang=EN-US
style='line-height:160%;color:white'>...” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>갑자기 달려들었던 묘한 인기척은 그녀의 애완 로봇인
가우디였다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>위험한 존재를 만난 것은 아니라 다행이지만 그래도
쉽사리 마음을 놓을 수가 없다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>눈앞도 전혀 보이지 않을 정도로 하얀 안개가 퍼졌던
게 조금 전인데</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>지금은 놀라울 정도로 깨끗해져 있었기에</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이런 일이 일어날 걸 예상하고 시간 체크를 미리
했던 건 아니었기에 확실하진 않지만 로버에 붙어 있는 시계를 보니 </span><span lang=EN-US style='line-height:
160%;color:white'>5</span><span style='line-height:160%;color:white'>분에서 </span><span
lang=EN-US style='line-height:160%;color:white'>10</span><span
style='line-height:160%;color:white'>분 정도가 지나 있었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캐리는 다시 로버에 올라타서 캡슐로 돌아가는 길에
생각했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>방금 일어난 현상이 무엇이고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>왜 일어나며</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>한 번 일어날 때마다 얼마나 지속되는지</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>알아봐야 할 게 산더미라 바삐 움직였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>`
}
export default staticFullStoryData_11

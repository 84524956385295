import contract from '@truffle/contract'
import { nextTick } from 'vue'

export const loadContract = async (name, provider, contractAddress) => {
  let res = null,
    _contract = null
  if (process.env.VUE_APP_IS_LOCAL === 'true') {
    res = await fetch(`/contracts/${name}__local.json`)
  } else {
    res = await fetch(`/contracts/${name}.json`)
  }
  const Artifact = await res.json()
  const deployedAddress =
    // contractAddress ||
    process.env.VUE_APP_DEPLOYED_CONTRACT_ADDRESS

  const channelIdInDecimal = parseInt(
    process.env.VUE_APP_TARGET_NETWORK_CHANNEL_ID,
    16,
  )

  if (process.env.VUE_APP_IS_LOCAL === 'true') {
    _contract = contract(Artifact)
  } else {
    _contract = contract({
      byteCode: Artifact.byteCode,
      abi: Artifact.abi,
      address: deployedAddress,
      deployedBytecode: Artifact.deployedBytecode,
      networks: {
        [channelIdInDecimal]: {
          address: deployedAddress,
        },
      },
    })
  }

  _contract.setProvider(provider)

  let deployedContract = null

  try {
    deployedContract = await _contract.deployed()
  } catch (error) {
    console.error('You are connected to wrong network!')
  }

  return deployedContract
}

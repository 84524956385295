const staticFullStoryData_15 = 		{
		id : 15,
		title: "아티스트-앤디 (75일차)",
		body: `<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white ;font-weight:bold'>15화 #. 아티스트 - 앤디 (75일 차)</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 오늘로 다섯 번째 작품을 만들기 위해 캡슐
밖의 날씨를 살폈다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>날씨는 캡슐 위로 설치해 둔 모니터를 통해 간편하게
확인할 수 있었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그걸 넘어서 행성 주변에 자리한 다른 행성들 또한
천체로 볼 수 있었는데</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>처음에 안개와 돌산만 가득한 우중충한 행성이라 생각했던 것과 달리 은하수조차
아름답게 수놓아진 곳이었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이를 발견할 수 있었던 건 웜홀의 도움이 컸다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>잃어버린 금빛 씨앗을 되찾기 위해 우연히 들어갔던
웜홀</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>생사를 놓고 시도한 모험이었는데 금빛 씨앗을 줍자마자
웜홀이 모습을 감추었고 아무런 장비도 없이 캡슐과 동떨어진 앤디의 죽음이 확정되는 순간이었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 주위를 둘러보니 말로 형용할 수 없이 아름다운
풍경이 자리하고 있었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>처음부터 캡슐이 이 행성으로 착륙했다면 얼마나 좋았을까</span><span
lang=EN-US style='line-height:160%;color:white'>...’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그랬다면 조금 더 활기차게 이주 프로젝트를 이행할
수 있었을 거라 생각하며 아쉬워했다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 앤디의 우주복에서 캡슐이 근처에 있다는 신호가
울렸고</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>수신호가 가리킨 곳은 거대한 폭포 그 밑이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>안개만이 가득하던 행성</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>웜홀을 타고 넘어온 곳에 가득한 폭포</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>폭포의 밑에 캡슐이 있다고 가리키는 수신호</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이를 찬찬히 되짚어 보던 앤디는 크게 한 방 맞은
듯 멍해졌다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>웜홀을 타고 다른 행성으로 온 것이 아니었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>한 치의 앞만 봐서 넓게 파악하지 못했을 뿐</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>앤디의 캡슐이 처음 착륙했던 곳은 지대의 밑이고 웜홀을 타고 온 공간은 그 위였던
것</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그 순간 다리에 힘이 풀렸다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이 넓은 우주에서 완전히 동떨어진 공간으로 온 건
아니라는 생각과</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>통신기의 오작동이 고쳐진 건 아니었지만 살았다는 생각</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>그리고 나의 행성이 이렇게 아름다운 곳이었다는 안도감이 몰려들었기 때문이다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 잘 들어가지 않는 힘을 억지로 내어서 </span><span
lang=EN-US style='line-height:160%;color:white'>A1</span><span
style='line-height:160%;color:white'>과 함께 캡슐이 있는 위치로 돌아갔다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그리고 캡슐에서 필요한 장비를 꺼내어 다시 지대
위로 올라왔고 그곳에 새로이 터를 잡았다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그렇게 우중충한 탐사를 기록하던 노트도 대대적으로
수정하였고</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>다른 매뉴얼도 찬찬히 이행하면서 하루하루를 보냈다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그때마다 아직 통신기는 오작동이지만 습관처럼 전송
버튼을 한 번씩 눌러보곤 했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>다른 친구들은 잘 지내고 있을까</span><span lang=EN-US
style='line-height:160%;color:white'>?’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>생각을 쉽게 떨쳐버릴 수 없었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 매뉴얼을 이행하는 것과 불안해하는 것을 연신
반복하다가 매뉴얼과 상관없이 그림을 그리며 시간을 보내기로 결심했다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>직업이기 이전에 가장 좋아하는 행위였고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>이를 하고 있을 때면 마음에 안정이 찾아왔으니까</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>물론 좋아하는 와인을 한 잔씩 하는 것도 좋았지만
그것과는 비교할 수도 없을 만큼 그림 작업이 편했다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>덕분에 앤디는 </span><span
lang=EN-US style='line-height:160%;color:white'>75</span><span
style='line-height:160%;color:white'>일간의 낯선 행성 생활을 큰 문제 없이 이어갈 수 있었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>또한 원래 매뉴얼대로라면 </span><span
lang=EN-US style='line-height:160%;color:white'>75</span><span
style='line-height:160%;color:white'>일에 한 개의 작품이 만들어졌어야만 하지만 앤디는 취미를 겸해서 만들었기에 이미
다섯 점의 그림을 완성하던 차였다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그렇기에 더욱 잘 보이는 특징 중 하나가 가장 마지막에
만들어진 작품일수록 작가의 심정이 편안해진 것이 느껴진다는 사실이다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 기획은 처음 시작할 때부터 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>행성에서 작품 만들기</span><span lang=EN-US style='line-height:160%;
color:white'>’</span><span style='line-height:160%;color:white'>를 염두에 두었었고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>단순히 그리는 걸 넘어서 행성의 자원을 이용해 입체회화 기법을 활용했는데</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>행성에 있던 풀과</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>물과</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>모래와</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>열매 등등</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>모든 것이 앤디의 영감이 되었고 예술적 도구가 되었으며
작품이었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탐사와 겸비하면서 작품에 쓰일 재료를 찾고 다녔으며
장소가 마음에 들면 이미지를 참고할 목적으로 자리를 잡고 앉아 하루를 통째로 날렸다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>평소 지구의 앤디 같았으면 상상도 안 해봤을 일을
너무나 간단히 행했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 그제야 머나먼 우주에서 자신이 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>청춘의 불안한 미래를 대변하는 작가</span><span lang=EN-US style='line-height:
160%;color:white'>’</span><span style='line-height:160%;color:white'>로 크게 인정받은 이유를
알 수 있었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>내가 그런 사람이었으니까</span><span lang=EN-US
style='line-height:160%;color:white'>...’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 천재라고 인정받으면서도 늘 불안해했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그곳에서는 늘 누군가와 비교하고 남들보다 더 뛰어나지기
위해 노력해야만 했으니까</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하지만 의도치 않게 온 우주와 소통이 끊긴 상태로
</span><span lang=EN-US style='line-height:160%;color:white'>75</span><span
style='line-height:160%;color:white'>일을 지내다 보니 혼자 남았다는 두려움을 넘어서 한 번도 느껴보지 못한 안정감을
찾은 것이다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>90</span><span
style='line-height:160%;color:white'>일이 되어 지구로 돌아가기까지 아직 </span><span
lang=EN-US style='line-height:160%;color:white'>15</span><span
style='line-height:160%;color:white'>일이 남은 상태인데</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>이 기간에 더욱 마음을 다잡고 돌아간다면 어떨까</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이전에 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>불안한 청춘의 미래</span><span lang=EN-US style='line-height:160%;
color:white'>’</span><span style='line-height:160%;color:white'>를 대변했던 화풍과는 확연히
달라진 화가 앤디의 자신감 넘치는 작품을 선보일 수 있을 거라 기대해 보기도 하며</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>앤디는 오늘도 다섯 번째 시리즈를 위해 도구를 들었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>오늘 만들 작품의 주제 배경은 별똥별이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이 행성은 낮은 지대에선 안개만 자욱했지만 위로
올라오니 유달리 깨끗하고 맑은 하늘을 맞이할 수 있었는데</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>적지 않은 텀으로 떨어지는
별똥별을 만나볼 수 있었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>죽어가는 행성일지</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>지구와 다르게 떠오르는 행성일지</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>본부 측에서 보내주는 구조대일지는 알 수 없지만 몇 번이고 앤디의 행성을 지나쳐서 사라졌다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>사실 앤디는 높은 확률로 거버넌스가 보내주는 구조대라
믿었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>오늘은</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>어제는</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>비록 저번에는 본인이 아닌 주변 다른 동료의 행성에 먼저 착륙하였지만</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>언젠가 자기 행성에도 구조대를 보내줄 거라며 굳게
믿었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 그런 희망을 담으며 다섯 번째 작품의 주제를
</span><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>별똥별</span><span lang=EN-US
style='line-height:160%;color:white'>’</span><span style='line-height:160%;
color:white'>로 정했고</span><span lang=EN-US style='line-height:160%;color:white'>,
</span><span style='line-height:160%;color:white'>작품의 별똥별 부근에 반짝이는 조개를 붙이며 말했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>당신이 나에게 실망할 기회를 주지 않을 것을 믿습니다</span><span
lang=EN-US style='line-height:160%;color:white'>.” </span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>
`
}
export default staticFullStoryData_15

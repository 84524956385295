import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'
import { getLoginStatus } from '@/utils/storage'
import HomeView from '@/views/HomeView.vue'
import userRoutes from '@/router/userRoutes.js'
import nftRoutes from '@/router/nftRoutes.js'
import authRoutes from './authRoutes'
import communityRoutes from '@/router/communityRoutes'
import memberRoutes from '@/router/memeberRoutes.js'
import MainLayout from '@/layouts/MainLayout.vue'
import userAuthGuard from '@/middleware/userAuthGuard'
import roadmapRoutes from './roadmapRoutes'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: HomeView,
      },
      {
        path: 'nfts',
        name: 'Nfts',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "nfts" */ '@/views/nfts/NFTView.vue'),
      },
      {
        path: 'member',
        name: 'Membership',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "membership" */ '@/views/MembershipView.vue'
          ),
      },

      {
        path: 'roadmap',
        name: 'Roadmap',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "Roadmap" */ '@/views/RoadmapView.vue'),
      },
      {
        path: 'privacy-policy',
        name: 'PrivacyPolicy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "PrivacyPolicy" */ '@/views/PrivacyPolicyView.vue'
          ),
      },
      ...userRoutes,
      ...nftRoutes,
      ...authRoutes,
      ...communityRoutes,
      ...memberRoutes,
      ...roadmapRoutes,
    ],
  },
  {
    path: '/minting',
    name: 'Minting',
    component: () =>
      import(/* webpackChunkName: "Minting" */ '@/views/MintingView.vue'),
    // NOTE: 민팅 당일이 아니어도 민팅사이트로 이동할 수 있게 항상 보여주고, 버튼 활성화 민팅 사이트 이동은 로그인상태가 아니라도 가능, 민팅도 가능
    // meta: { authRequired: true, walletRequired: true },
  },

  {
    path: '/:catchAll(.*)*',
    name: 'NotFound',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to /* from, savedPosition */) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return { top: 0 }
  },
  routes,
})

router.beforeEach(userAuthGuard)

export default router

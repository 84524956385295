const staticFullStoryData_16 = 		{
		id : 16,
		title: "아티스트-앤디 (89일차)",
		body: `<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white;font-weight:bold'>16화 #. 아티스트 - 앤디 (89일 차)</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>벌써 이 행성에 온 지 89일 차.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>원래대로라면 당장 내일 행성을 떠나야 하는 일정이라 준비해야 할 것이 많겠지만 앤디는 언덕 위에 자리 잡고 그림 만들기에 집중했다.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>통신이 끊긴지 89일 차이기도 하니까.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지구로 돌아가는 것을 단념한 것은 아니지만 어수선하게
움직일 필요는 없었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>오히려 그럴 시간에 이전에 만든 여섯 점의 작품의
도슨트를 작성했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>운이 좋아서 </span><span
lang=EN-US style='line-height:160%;color:white'>90</span><span
style='line-height:160%;color:white'>일에 딱 구조대가 오면 다행이었지만 그게 아니라면 언제까지고 구조대를 기다리며
살아있을 여력이 없으니</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>몇백 년 뒤에 구조대가 오더라도 살아있는 동안 우주에서
만든 마지막 작품을 설명하는 글만은 남겨두고 싶었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>90</span><span
style='line-height:160%;color:white'>일간에 많은 일이 있었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>웜홀에 들어가 보기도 하고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>작품을 만들면서 내적으로 성장하기도 하고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>지구의 풀벌레를 닮은 곤충과 식용이 가능한 여러 종류의 물고기들도 발견했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>식용이 가능한 식량이 있으니 </span><span
lang=EN-US style='line-height:160%;color:white'>90</span><span
style='line-height:160%;color:white'>일을 넘어서도 생존을 이어갈 수 있지 않나 싶겠지만 그것과는 다른 여러 문제가
있었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그저</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>지금 할 수 있는 일에 집중할 뿐이다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%'><img width=208 height=295
id="그림 14" src="/image/story16/image001.jpg"></span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그런데 앤디가 풀을 짓이겨서 만든 색감을 선 위로
덧칠할 때</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>어디선가 풀벌레와 다른 소리가 들렸다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그르르릉 울며 단잠을 자는 </span><span
lang=EN-US style='line-height:160%;color:white'>A1 </span><span
style='line-height:160%;color:white'>강아지의 소리도 아니었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>거의 들리지 않을 정도로 높으면서도 일정한 속도로
진행되는</span><span lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>기계 소리</span><span lang=EN-US
style='line-height:160%;color:white'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>놀란 앤디는 들고 있던 붓을 놓고 주위를 둘러보았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그리고 점점 기계 소리가 들리는 쪽으로 신중하게
걸음을 옮겨서 주변을 살폈고</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>몇 시간을 소비한 뒤에야 절벽 밑에 숨겨진 통신기를 발견했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>생김새는 앤디가 사용하던 통신기보다 훨씬 크고 두껍지만
통신기에 부착된 로고는 같은 그림이었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>역시나 처음 보는 모델이었으나 스위치나 작동법은
거의 비슷했으며 기존 통신기의 작동법을 잘 알고 있던 앤디는 어렵지 않게 조작할 수 있었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러자 갑자기 지지직 소리와 함께 뭔가와 연결되는
소리가 났다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 아직 상황을 파악할 수 없었지만 조심스럽게
목소리를 내었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&quot;...hello?&quot;
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>[Law</span><span
style='line-height:160%;color:white'>의 토</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>통신이 연결됐습니다</span><span lang=EN-US style='line-height:160%;
color:white'>!] </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러자 통신 너머의 목소리가 선명히 들렸다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>당황한 목소리 뒤에 들리는 이 음성은 거버넌스의
목소리가 분명하다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>[...</span><span
style='line-height:160%;color:white'>이름과 기수를 말씀 부탁드립니다</span><span lang=EN-US
style='line-height:160%;color:white'>.] </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 말의 의미를 이해할 수 없었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이름이야 그렇다 치고 기수가 뭐란 말인가</span><span
lang=EN-US style='line-height:160%;color:white'>?</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>밀코메다</span><span lang=EN-US
style='line-height:160%;color:white'>N</span><span style='line-height:160%;
color:white'>의 앤디입니다</span><span lang=EN-US style='line-height:160%;color:white'>...”
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>딱히 이름 말고 덧붙일 게 없던 앤디는 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>밀코메다</span><span lang=EN-US style='line-height:160%;
color:white'>N’</span><span style='line-height:160%;color:white'>이라는 칭호까지 붙여 말해
보았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러자 거버넌스의 목소리가 바뀌는 것이 느껴졌다</span><span
lang=EN-US style='line-height:160%;color:white'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>[</span><span
style='line-height:160%;color:white'>지금부터 개인적인 지시를 내리겠습니다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>현재 사용 중인 통신기의 채널을 이용해서 다른 밀코메다</span><span
lang=EN-US style='line-height:160%;color:white'>N</span><span style='line-height:
160%;color:white'>의 통신기 또한 본부와 연결할 방법을 알려드리겠습니다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>지시대로 따라주십시오</span><span lang=EN-US style='line-height:160%;
color:white'>.] </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>거버넌스는 쉴 틈 없이 통신기 조작법에 대해 알려주었고
앤디는 뭔가에 이끌리는 듯 손을 움직였다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>우선은 장작 </span><span
lang=EN-US style='line-height:160%;color:white'>89</span><span
style='line-height:160%;color:white'>일 동안 끊겼던 통신기를 연결해서 다른 동료들의 상태를 확인하는 것이 무엇보다
중요했기에</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>[</span><span
style='line-height:160%;color:white'>수고하셨습니다</span><span lang=EN-US
style='line-height:160%;color:white'>.] </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디가 궁금증에 대해 입을 연 건 거버넌스의 마지막
말이 떨어지고 나서였다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>이 통신기는 제가 사용하던 것과 다른 버전인데 어떻게 된 거죠</span><span
lang=EN-US style='line-height:160%;color:white'>? </span><span
style='line-height:160%;color:white'>행성에 이주한 밀코인은 저희가 최초가 아니었던 겁니까</span><span
lang=EN-US style='line-height:160%;color:white'>? </span><span
style='line-height:160%;color:white'>아까 말한 </span><span lang=EN-US
style='line-height:160%;color:white'>Law</span><span style='line-height:160%;
color:white'>는 또 뭡니까</span><span lang=EN-US style='line-height:160%;color:white'>?”
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>[...</span><span
style='line-height:160%;color:white'>그렇습니다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>사실 밀코메다</span><span lang=EN-US style='line-height:160%;color:white'>N
</span><span style='line-height:160%;color:white'>이전에도 여러 번의 시도가 있었죠</span><span
lang=EN-US style='line-height:160%;color:white'>. Law</span><span
style='line-height:160%;color:white'>는 밀코메다</span><span lang=EN-US
style='line-height:160%;color:white'>N</span><span style='line-height:160%;
color:white'>처럼 그들을 부르던 통칭이었습니다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span><span style='line-height:160%;color:white'>하지만 이처럼 성공에 근접한
것은 밀코메다</span><span lang=EN-US style='line-height:160%;color:white'>N </span><span
style='line-height:160%;color:white'>여러분이 처음이고</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>지구에 돌아온다면 여러분은 자랑스러운 최초의 밀코인이 될 것입니다</span><span lang=EN-US
style='line-height:160%;color:white'>.]</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>그럼</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>이전에 밀코메다은하로 왔던 밀코인</span><span lang=EN-US style='line-height:160%;
color:white'>... Law</span><span style='line-height:160%;color:white'>들은 어떻게 된 거죠</span><span
lang=EN-US style='line-height:160%;color:white'>?” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>통신 기계는 발견했지만 사람이 살았었다는 흔적은
하나도 없었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>자연이 이미 흔적을 지운 만큼 오래전이었다 하더라도</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>지구에 있을 때의 앤디는 누군가가 새로운 은하로 갔다가 돌아왔다는 역사에 대해
들은 적이 없었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이에 대답해줄 수 있는 사람은 오로지 거버넌스뿐인데</span><span
lang=EN-US style='line-height:160%;color:white'>... </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그가 입을 열기 전에 밀코메다</span><span
lang=EN-US style='line-height:160%;color:white'>N</span><span style='line-height:
160%;color:white'>과의 통신이 하나둘씩 연결되었다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>띠링</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>띠링</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>띠링</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>띠링</span><span lang=EN-US style='line-height:160%;color:white'>- </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>-</span><span
style='line-height:160%;color:white'>뭐</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>뭐야</span><span
lang=EN-US style='line-height:160%;color:white'>? </span><span
style='line-height:160%;color:white'>드디어 통신이 연결된 거야</span><span lang=EN-US
style='line-height:160%;color:white'>? </span><span style='line-height:160%;
color:white'>살았다</span><span lang=EN-US style='line-height:160%;color:white'>!!!!!!!!
</span><span style='line-height:160%;color:white'>모두 내 말 좀 들어봐</span><span
lang=EN-US style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>-</span><span
style='line-height:160%;color:white'>마침 연결 시도해 보길 잘했네</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>거버넌스 혹시 듣고 있으면</span><span lang=EN-US style='line-height:160%;
color:white'>,</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>제가 지구로 가기 전에 복원 프로젝트에 후원할 기업
리스트 좀 알아봐 주세요</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>-</span><span
style='line-height:160%;color:white'>그런 고리타분한 것은 나중에 하라고</span><span
lang=EN-US style='line-height:160%;color:white'>! </span><span
style='line-height:160%;color:white'>행성에서 생물 발견한 밀코인 있어</span><span lang=EN-US
style='line-height:160%;color:white'>? </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>난 무려 두더지가 열댓 마리나 된다고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>내 해부 연구가 먼저야</span><span lang=EN-US
style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>통신이 연결되자마자 시끌벅적한 목소리가 높아졌다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>충격과 혼란에 빠진 앤디는 입을 열수가 없었고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>한참 말이 없던 거버넌스가 입을 열었다</span><span
lang=EN-US style='line-height:160%;color:white'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>[</span><span
style='line-height:160%;color:white'>밀코메다</span><span lang=EN-US
style='line-height:160%;color:white'>N </span><span style='line-height:160%;
color:white'>여러분</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>그동안 수고하셨습니다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>갑작스럽게 통신이 끊겨 당황스러웠겠지만 각자의 애완로봇을 통해 여러분의 수고는 지켜보았습니다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>여러분의 노고에 매우 감사드리며</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>통신 연결에 힘써주신 앤디님에겐 특별한 은하의 이동 루트를 알려드리겠습니다</span><span lang=EN-US
style='line-height:160%;color:white'>. 5</span><span style='line-height:160%;
color:white'>시간 먼저 지구에 복귀할 수 있는 루트이고</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>앤디님의 단독 기자회견이
마칠 때쯤 다른 밀코메다</span><span lang=EN-US style='line-height:160%;color:white'>N</span><span
style='line-height:160%;color:white'>의 캡슐이 차례대로 지구로 복귀할 것입니다</span><span
lang=EN-US style='line-height:160%;color:white'>.] </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>거버넌스의 말을 들은 밀코메다</span><span
lang=EN-US style='line-height:160%;color:white'>N</span><span style='line-height:
160%;color:white'>의 반응은 제각각이었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>통신을 연결해 줘서 고맙다는 말과</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>선발대 중에서도 선발대라니 축하한다는 말</span><span
lang=EN-US style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>자신이 단독 기자회견 대상이 아니라는 것에 만족스럽진
않지만 앤디니까 인정한다는 말 등등</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>겨우 정신을 차린 앤디는 고맙다는 말밖에 할 수
없었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그렇게 통신이 끊기고 날을 꼴딱 새웠다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>행성에서 출발하기 위해 준비해야 할 것보다 생각할
것이 많았으니까</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>짐을 모두 캡슐에 싣고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>예정된 시간이 되었을 무렵 캡슐의 문을 닫았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>치이잉</span><span lang=EN-US
style='line-height:160%;color:white'>- </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>3, 2, 1... </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>고민했던 시간이 짧게 느껴질 정도로 캡슐이 우주
너머로 사라지는 순간은 아주 빨랐다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>마치 처음부터 없었던 것처럼</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 순식간에 시야에서 사라진 캡슐을 바라보며
행성의 언덕 위에서 그림을 그렸다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>언제까지 거짓 위에서 살 순 없어</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>나는 최초가 아니라 최고의 아티스트가 되기 위해 이곳에 왔으니까</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>나의 예술로 이 모든 진실을 밝히고 세상에 보여줘야지</span><span lang=EN-US
style='line-height:160%;color:white'>.” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>거버넌스가 앤디에게만 알려준 은하 루트는 확실히
지구로 안전하게 돌아오는 방법이었다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지금은 의문을 느낄 테지만 사람들의 커다란 관심을
받고 아티스트로서 크게 인정받으면 금방 잊게 될 테니 심각하게 생각하지 않았다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>원래 예술가라는 자들이 주변의 평판에 쉽게 휩쓸리기도
하니까</span><span lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>아니 너무나 바빠 생각할 틈도 없을 것이다</span><span
lang=EN-US style='line-height:160%;color:white'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>서로가 윈윈할 수 있는 상황을 그리며</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>거버넌스는 앤디에게 했던 약속대로 기자들을 잔뜩 불러 놓았다</span><span
lang=EN-US style='line-height:160%;color:white'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 밀코메다은하를 넘어 지구로 돌아온 캡슐은
고물이라고 부르는 게 맞을 정도로 부식된 상태였다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>당연하게도 그 안에는 최초의 밀코인이 존재하지 않았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>기자들은 시간을 앞다투어 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>복귀에 실패해서 사라진 밀코인</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>블랙홀을 지나다가
부식된 캡슐</span><span lang=EN-US style='line-height:160%;color:white'>’</span><span
style='line-height:160%;color:white'>에 대해서 다루었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하지만 그건 이 프로젝트에 대해 아무것도 모르는
자들이나 할 수 있는 말이다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>거버넌스는 캡슐을 보자마자 깨달았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이건 밀코메다</span><span
lang=EN-US style='line-height:160%;color:white'>N</span><span style='line-height:
160%;color:white'>이 사용했던 캡슐이 아니라 정말 최초의 밀코인 </span><span lang=EN-US
style='line-height:160%;color:white'>Law</span><span style='line-height:160%;
color:white'>가 탔던 캡슐이고</span><span lang=EN-US style='line-height:160%;
color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디가 이것을 보낸 건 자신의 의도를 확실히 하며
전쟁을 선포한 것과 다름없다고</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>`
}
export default staticFullStoryData_16

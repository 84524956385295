import axios from 'axios'

const instance = axios.create({
		baseURL: `${process.env.VUE_APP_BASE_URL}/api/v3`,
		validateStatus: status => {
				return status < 200;
		}
})

export const getInstance = () => {
		return instance
}


let authStore;
let router;

export const setAuthStore = (_authStore) => {
		authStore = _authStore;
}
export const setRouter = (_router) => {
		router = _router;
}

export const callRenewalToken = () => { // token 갱신 api
		const request = {
				ACCESS_TOKEN: authStore.ACCESS_TOKEN,
				REFRESH_TOKEN: authStore.REFRESH_TOKEN
		}
		return instance.post('/oauth/token', request)
}


instance.interceptors.response.use((config) => config, async (err) => {
		const originalRequest = err.config;
		const response = err.response;

		if (response.status !== 200){ // 200 에러가 아닌 경우 처리 정해진게 없음.
				return response;		// jwt 토큰 만료가 200으로 status가 발생됨
		}
		
		const errorCode = response.data.error;
		const errorMsg = response.data.message;
		
		if (!errorCode || !errorMsg){
				return response; // jwt 갱신 오류가 아님
		}

		console.log(`${errorCode} :: ${errorMsg}`);

		if (errorCode === '401002' && errorMsg === 'Access Token이 만료 되었습니다.' && !originalRequest._retry){
				if (authStore.processing) {
						await sleep(1000);
						return instance(originalRequest);
				} else {
						originalRequest._retry = true;
						authStore.processing = true;
						try {
								const renewalTokenResponse = await callRenewalToken();
								// 토큰 갱신 후 기존 요청 재진행
								authStore.ACCESS_TOKEN = renewalTokenResponse.data.ACCESS_TOKEN;
								authStore.REFRESH_TOKEN = renewalTokenResponse.data.REFRESH_TOKEN;
								return instance(originalRequest);
						} catch (renewalErr) {
								console.error(renewalErr);

								// 1. 로그아웃
								// 2. 갱신 결과가 실패 시 로그인 화면으로 이동
								authStore.removeUserAuth();
								router.push({name: 'Home'});
						} finally {
								authStore.processing = false
						}
				}
				return response;
		} else {
				return response;
		}

})


function sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms))
}

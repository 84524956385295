import {getInstance, setRouter, setAuthStore} from '@/api'

const instance = getInstance()

const memberModule = '/member'

export const signUp = (request) => {
		return instance.post(`${memberModule}/join-member`, request)
}

export const getAgree = (request) => {
		return instance.post(`${memberModule}/agree`, request)
}

export const isDuplicateId = (request) => {
		return instance.post(`${memberModule}/duplicate-check-id`, request)
}

// 등록 가능한 지갑 인지 확인
export const walletCheck = (request, config, authStore, router) => {
		setAuthStore(authStore);
		setRouter(router);
		return instance.post(`${memberModule}/wallet-check`, request, config)
}

// 지갑등록
export const addWallet = (request, config, authStore, router) => {
		setAuthStore(authStore);
		setRouter(router);
		return instance.post(`${memberModule}/add-wallet`, request, config)
}

// 대표 주소 등록/수정
export const addPrimaryAddress = (request) => {
		return instance.post(`${memberModule}/add-address`, request)
}

// 고객정보 조회
export const getMemberInfo = (request, config, authStore, router) => {
		setAuthStore(authStore);
		setRouter(router);
		return instance.post(`${memberModule}/member-info`, request, config);
}

const memberRoutes = [
  {
    path: 'member/benefits',
    name: 'Benefits',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Benefits" */ '@/views/member/BenefitsView.vue'
      ),
  },

  {
    path: 'member/partners',
    name: 'Partners',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Partners" */ '@/views/member/PartnersView.vue'
      ),
  },

  {
    path: 'member/goods-store',
    name: 'GoodsStore',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "GoodsStore" */ '@/views/member/GoodsStoreView.vue'
      ),
  },

  {
    path: 'member/character-book',
    name: 'CharacterBook',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "CharacterBook" */ '@/views/member/CharacterBookView.vue'
      ),
  },

  {
    path: 'member/partnership',
    name: 'Partnership',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
      import(
        /* webpackChunkName: "PartnershipView" */ '@/views/member/PartnershipView.vue'
      ),
  },
]

export default memberRoutes

<template>
  <header>
    <!-- start : header -->
    <div class="rela">
      <h1>
        <router-link to="/">
          <img src="~@/assets/image/etc/etc-logo.svg"/>
        </router-link>
      </h1>

      <div class="gnb-item-wrap">
        <ul>
          <li>
            <router-link :to="{ name: 'Home' }" exact-active-class="on">
              HOME
            </router-link>

            <div class="sub-item-wrap">
              <ul>
                <li>
                  <router-link :to="{ name: 'Home', hash: '#home-sec01' }">
                    Intro
                  </router-link>
                </li>

                <li>
                  <router-link :to="{ name: 'Home', hash: '#home-sec02' }">
                    3D Teaser
                  </router-link>
                </li>

                <li>
                  <router-link :to="{ name: 'Home', hash: '#home-sec03' }">
                    Sequence
                  </router-link>
                </li>

                <li>
                  <router-link :to="{ name: 'Home', hash: '#home-sec04' }">
                    Concept
                  </router-link>
                </li>

                <li>
                  <router-link :to="{ name: 'Home', hash: '#home-sec05' }">
                    Story
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <router-link
                :to="{ name: 'Nfts' }"
                active-class="on"
                :class="activateSubRoutesMatched('/nfts')"
            >
              NFT
            </router-link>

            <div class="sub-item-wrap">
              <ul>
                <li>
                  <router-link :to="{ name: 'Nfts', hash: '#main-model' }">
                    Main Model
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Nfts', hash: '#collection' }">
                    Collection
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Nfts', hash: '#commonrarity' }">
                    Common/Rarity
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Nfts', hash: '#legendary' }">
                    Legendary
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Nfts', hash: '#catalogue' }">
                    Catalogue
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Nfts', hash: '#celebrityholder' }">
                    Celebrity Holder
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Nfts', hash: '#introduceteam' }">
                    Introduce team
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <router-link
                :to="{ name: 'Membership' }"
                active-class="on"
                :class="activateSubRoutesMatched('/member')"
            >
              Membership
            </router-link>

            <div class="sub-item-wrap">
              <ul>
                <li>
                  <router-link
                      :to="{ name: 'Membership', hash: '#home-sec01' }"
                  >
                    Benefit
                  </router-link>
                </li>
                <li>
                  <router-link
                      :to="{ name: 'Membership', hash: '#home-sec02' }"
                  >
                    Partner
                  </router-link>
                </li>
                <li>
                  <router-link
                      :to="{ name: 'Membership', hash: '#home-sec03' }"
                  >
                    Goods Store
                  </router-link>
                </li>
                <li>
                  <router-link
                      :to="{ name: 'Membership', hash: '#home-sec04' }"
                  >
                    Character Book
                  </router-link>
                </li>
                <li>
                  <router-link
                      :to="{ name: 'Membership', hash: '#home-sec05' }"
                  >
                    Partnership Offerings
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li>
            <!--                        <router-link :to="{ name: 'Roadmap' }">Road map</router-link>-->
            <router-link
                :to="{ name: 'RoadmapPlan' }"
                active-class="off"
                :class="activateSubRoutesMatched('/roadmap')"
            >
              Road map
            </router-link>
            <div class="sub-item-wrap">
              <ul>
                <li>
                  <router-link :to="{ name: 'RoadmapPlan' }">
                    Roadmap Plan
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li>
            <router-link
                :to="{ name: 'FullStory' }"
                active-class="on"
                :class="activateSubRoutesMatched('/community')"
            >
              Community
            </router-link>

            <div class="sub-item-wrap">
              <ul>
                <li>
                  <router-link :to="{ name: 'FullStory' }">
                    풀 스토리
                  </router-link>
                </li>

                <li>
                  <router-link :to="{ name: 'MyStory' }">
                    나의 행성 스토리
                  </router-link>
                </li>

                <li>
                  <router-link :to="{ name: 'Notices' }">공지사항</router-link>
                </li>

                <li>
                  <router-link :to="{ name: 'Faq' }">
                    <div>FAQ</div>
                  </router-link>
                </li>

                <li>
                  <router-link :to="{ name: 'News' }">언론/뉴스</router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <div class="header-item">
        <ul class="mem-link-wrap">
          <!--<li><a href="javascript:void(0)"><img src="~@/assets/image/ico/ico-header-list01.svg" /></a></li>-->
          <li>
            <a
                href="https://www.youtube.com/channel/UCbAllU3-srpjDRaSpr1-NQQ"
                target="_blank"
            ><img src="~@/assets/image/ico/ico-header-list02.svg"
            /></a>
          </li>
          <li>
            <a
                href="https://www.instagram.com/canvasn_official"
                target="_blank"
            ><img src="~@/assets/image/ico/ico-header-list03.svg"
            /></a>
          </li>
          <li>
            <a href="https://discord.gg/milkomeda-n" target="_blank"
            ><img src="~@/assets/image/ico/ico-header-list04.svg"
            /></a>
          </li>
          <li>
            <a href="https://twitter.com/CanvasNOfficial" target="_blank"
            ><img src="~@/assets/image/ico/ico-header-list05.svg"
            /></a>
          </li>

          <li>
            <a
                href="https://www.facebook.com/profile.php?id=100084175308287"
                target="_blank"
            ><img src="~@/assets/image/ico/ico-header-list07.svg"
            /></a>
          </li>

          <li v-if="!authStore.isLoggedIn">
            <router-link to="/login" class="txt">로그인</router-link>
          </li>
          <li v-else>
            <!-- 로그인시 프로필 이미지 / hover 메뉴  -->
            <div class="login-profile-img profile-img-dropdown">
              <a href="javascript:void(0)" class="profile-img">
                <UserAvatar/>
                <!-- <img
                  src="@/assets/image/form/profile-sample02.png"
                  alt=""
                  class="nft-img"
                /> -->
              </a>

              <div class="profile-img-dropdown-list">
                <ul class="my-menu">
                  <li class="menu-arrow"></li>
                  <li>
                    <router-link :to="{ name: 'MyCollections' }">
                      <!--                    <router-link :to="{ name: '' }">-->
                      <!--                      <img-->
                      <!--                        src="@/assets/image/ico/ico-coming-soon.png"-->
                      <!--                        class="img-coming-soon"-->
                      <!--                        height="38"-->
                      <!--                      />-->
                      나의 컬렉션
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'MyWallets' }">
                      <!--                    <router-link :to="{ name: '' }">-->
                      <!--                      <img-->
                      <!--                        src="@/assets/image/ico/ico-coming-soon.png"-->
                      <!--                        class="img-coming-soon"-->
                      <!--                        height="38"-->
                      <!--                      />-->
                      나의 지갑
                    </router-link>
                  </li>

                  <li>
                    <router-link :to="{ name: 'MyProfile' }">
                      개인정보 수정
                    </router-link>
                  </li>

                  <li>
                    <a href="javascript:void(0)" @click="handleLogout"
                    >로그아웃</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>

        <template v-if="!isWithoutWalletConnectorPage">
          <WalletConnector
              :hideNetwork="true"
              :iconSmall="true"
              :doLoadContract="false"
          >
            <template #connect="{ onConnect }">
              <b-button type="button" class="btn-connect" @click="onConnect">
                <span>Connect Wallet</span>
              </b-button>
            </template>
          </WalletConnector>
        </template>
        <!-- <WalletConnector />

       <div class="lang-item-wrap">
          <a href="javascript:void(0)"><img src="~@/assets/image/ico/ico-lang.svg" /></a>
          <div class="lang-item-list">
            <ul class="my-menu">
              <li><a href="javascript:void(0)">KOR</a></li>
              <li><a href="javascript:void(0)">ENG</a></li>
            </ul>
          </div>
        </div> -->
      </div>

      <div
          class="btn-total-nav"
          :class="{ on: isActive }"
          @click="handleClickMenuButton"
      >
        <span></span>
      </div>
    </div>

    <!-- 전체 메뉴 -->
    <div class="all-menu-wrap" :class="{ on: isActive }">
      <div class="wrela">
        <div class="menu-header">
          <h1><img src="~@/assets/image/etc/etc-logo.svg"/></h1>
          <a
              href="javascript:void(0)"
              class="btn-menu-close"
              @click="handleClickMenuButton"
          >
            <i class="el-icon-close"></i>
          </a>
        </div>

        <div class="mob-header-wrap">
          <ul class="mem-link-wrap">
            <!--            <li>-->
            <!--              <a href="javascript:void(0)"-->
            <!--                ><img src="~@/assets/image/ico/ico-header-list01.svg"-->
            <!--              /></a>-->
            <!--            </li>-->
            <li>
              <a
                  href="https://www.youtube.com/channel/UCbAllU3-srpjDRaSpr1-NQQ"
                  target="_blank"
              ><img src="~@/assets/image/ico/ico-header-list02.svg"
              /></a>
            </li>
            <li>
              <a
                  href="https://www.instagram.com/milkomedan_official"
                  target="_blank"
              ><img src="~@/assets/image/ico/ico-header-list03.svg"
              /></a>
            </li>
            <li>
              <a href="https://discord.gg/milkomeda-n" target="_blank"
              ><img src="~@/assets/image/ico/ico-header-list04.svg"
              /></a>
            </li>
            <li>
              <a href="https://twitter.com/CanvasNOfficial" target="_blank"
              ><img src="~@/assets/image/ico/ico-header-list05.svg"
              /></a>
            </li>
            <li>
              <a
                  href="https://www.facebook.com/profile.php?id=100084175308287"
                  target="_blank"
              ><img src="~@/assets/image/ico/ico-header-list07.svg"
              /></a>
            </li>
          </ul>
          <div class="func-item-wrap">
            <div class="login-profile-img profile-img-dropdown">
              <div v-if="authStore.isLoggedIn" href="javascript:void(0)" class="profile-img">
                <UserAvatar/>
              </div>
              <div class="mob-wallet-connect-tip">
                PC환경에서 지갑연결을 하세요
              </div>
            </div>
          </div>
        </div>

        <!-- <li v-if="!authStore.isLoggedIn">
            <router-link to="/login" class="txt">로그인</router-link>
          </li>
          <li v-else>
            
            <div class="login-profile-img profile-img-dropdown">
              <a href="javascript:void(0)" class="profile-img">
                <UserAvatar/>
                
              </a> -->

        <ul class="all-menu-list">
          <li>
            <a @click="handleClickNav('Home')">HOME</a>
          </li>

          <li>
            <a @click="handleClickNav('Nfts')">NFT’s</a>
          </li>
          <!-- <NftNavItem
            @click="handleClickActive('nfts')"
            :isActive="isActiveList.nfts"
            :handleClickNav="handleClickNav"
          /> -->

          <li>
            <a @click="handleClickNav('Membership')">Membership</a>
          </li>
          <!-- <MembershipNavItem
            @click="handleClickActive('membership')"
            :isActive="isActiveList.membership"
            :handleClickNav="handleClickNav"
          /> -->

          <RoadmapNavItem
              @click="handleClickActive('roadmap')"
              :isActive="isActiveList.roadmap"
              :handleClickNav="handleClickNav"
          />

          <CommunityNavItem
              @click="handleClickActive('community')"
              :isActive="isActiveList.community"
              :handleClickNav="handleClickNav"
          />

          <li v-if="!authStore.isLoggedIn">
            <a href="javascript:void(0)" @click="handleClickNav('Login')">
              Login
            </a>
          </li>

          <div v-else>
            <MyPageNavItem
                @click="handleClickActive('mypage')"
                :isActive="isActiveList.mypage"
                :handleClickNav="handleClickNav"
            />
            <li>
              <a @click="handleLogout" v-if="authStore.isLoggedIn">Logout</a>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </header>
</template>

<script setup>
import {useRouter, useRoute} from "vue-router";
import {useAuthStore} from "@/store/authStore";
import {useWeb3Store} from "@/store/web3Store";
import WalletConnector from "@/components/web3/WalletConnector.vue";
import UserAvatar from "@/components/common/UserAvatar.vue";
import {computed, reactive, ref} from "vue";
import CommunityNavItem from "./HeaderNavItem/CommunityNavItem.vue";
import NftNavItem from "./HeaderNavItem/NftNavItem.vue";
import MembershipNavItem from "./HeaderNavItem/MembershipNavItem.vue";
import MyPageNavItem from "./HeaderNavItem/MyPageNavItem.vue";
import RoadmapNavItem from "./HeaderNavItem/RoadmapNavItem.vue";

const router = useRouter();
const route = useRoute();
// eslint-disable-next-line no-unused-vars
const authStore = useAuthStore();
const web3Store = useWeb3Store();

const activateSubRoutesMatched = (path) => {
  if (router.currentRoute.value.path.startsWith(path)) {
    return "on";
  }
  return null;
};

const isActive = ref(false);
const isActiveList = reactive({
  nfts: false,
  membership: false,
  community: false,
  mypage: false,
  roadmap: false,
});

// 버튼이 없어야 하는 페이지인 경우
const isWithoutWalletConnectorPage = computed(() => {
  if (route.path === "/join") {
    return true;
  }
  return false;
});

const handleClickActive = (nav) => {
  for (const key in isActiveList) {
    nav === key ? (isActiveList[key] = true) : (isActiveList[key] = false);
  }
};

const handleClickMenuButton = () => {
  isActive.value = !isActive.value;
};

const handleCLickNavMenu = (name) => {
};

const handleClickNav = (routerName) => {
  router.push({name: routerName});
  isActive.value = false;
};

const handleLogout = () => {
  authStore.removeUserAuth();
  router.push({name: "Home"});
  isActive.value = false;
};
</script>

<style lang="scss">
@charset "utf-8";

@media screen and (max-width: 769px) {
  header .rela h1 {
    align-items: flex-end !important;
  }
}

@media screen and (max-width: 1280px) {
  /* .mem-link-wrap {
    display: none !important;
  } */

  .btn-total-nav {
    display: flex !important;
    margin: 0 !important;
    position: absolute !important;
    right: 15px !important;
    top: 15px !important;
  }
}

/*상단영역*/
header {
  position: fixed;
  left: 0;
  top: 0;
  transition: all 0.5s;
  opacity: 1;
  width: 100%;
  height: auto;
  z-index: 300;
  background: rgba(18, 13, 88, 0.7);
}

header .rela {
  margin: 0 auto;
  transition: all 0.5s;
  width: 100%;
  height: 70px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/*
header.on{border-bottom:2px solid #833f9e;}
header.on .rela{height:80px;}
*/
header .rela h1 {
  margin-left: 40px;
  width: 330px;
  overflow: hidden;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

header .rela h1 a {
  height: 50px;
}

header .rela .header-item {
  position: relative;
  z-index: 10;
  width: 100%;
  flex: 1;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 40px;
}

header .mem-link-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

header .mem-link-wrap > li {
  float: left;
  padding: 0 0 0 10px;
  position: relative;
}

header .mem-link-wrap > li > a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.5s;
  font-size: 14px;
  color: #28e3d1;
  font-weight: bold;
  width: 35px;
  height: 35px;
}

header .mem-link-wrap > li > a.txt {
  width: auto;
  padding: 0 10px;
}

header .mem-link-wrap > li > a img {
  transition: all 0.5s;
}

header .mem-link-wrap > li > a:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(210deg) brightness(103%) contrast(101%);
}

header .mem-link-wrap > li > a:hover {
  color: #fff;
}

header .lang-item-wrap {
  position: relative;
}

header .lang-item-wrap > a {
  position: relative;
  background: transparent;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

header .lang-item-wrap:hover > a {
  background: url(~@/assets~@/assets/image/ico/ico-lang-over.svg) no-repeat center center;
}

header .lang-item-wrap > a img {
  opacity: 1;
  transition: all 0.5s;
}

header .lang-item-wrap:hover > a img {
  opacity: 0;
}

header .lang-item-wrap > a span.stxt {
  margin-left: 10px;
  display: flex;
  font-size: 14px;
  color: #fff;
  width: 40px;
}

header .lang-item-wrap > a span.sico {
  width: 12px;
  height: 12px;
  overflow: hidden;
  display: block;
  background: url(~@/assets~@/assets/image/ico/ico-arr-down01.svg) no-repeat;
}

header .lang-item-wrap:hover > a span.stxt {
  color: #fff;
}

header .lang-item-wrap:hover > a span.sico {
  transform: rotate(180deg);
}

header .lang-item-wrap .lang-item-list {
  transition: all 0.5s;
  opacity: 0;
  transform: translateY(10px);
  position: absolute;
  right: 0;
  top: 38px;
  width: 60px;
  overflow: hidden;
  height: 0;
  background: #28e3d1;
  border-radius: 3px;
}

header .lang-item-wrap .lang-item-list ul {
  width: 100%;
  height: auto;
  overflow: Hidden;
}

header .lang-item-wrap .lang-item-list ul li {
  width: 100%;
  height: auto;
  overflow: Hidden;
}

header .lang-item-wrap .lang-item-list ul li:first-child {
  border-top: 0;
}

header .lang-item-wrap .lang-item-list ul li a {
  font-size: 12px;
  color: #fff;
  width: 100%;
  height: 30px;
  overflow: Hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

header .lang-item-wrap .lang-item-list ul li a:hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}

header .lang-item-wrap:hover > a + .lang-item-list {
  height: auto;
  opacity: 1;
  transform: translateY(0px);
}

header .btn-connect {
  margin: 0 10px;
  width: 135px;
  height: 35px;
  overflow: Hidden;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #28e3d1 url(~@/assets~@/assets/image/bg/bg-connect.png) no-repeat left center;
  border: 0;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  transition: all 0.5s;
}

header .btn-connect span {
  width: 135px;
  height: 35px;
  overflow: Hidden;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  background: transparent;
  transition: all 0.5s;
}

header .btn-connect:hover span {
  background: #28e3d1;
}

.gnb-item-wrap ul li .sub-item-wrap {
  transition: all 0.5s;
  opacity: 0;
  transform: translateY(50px);
  width: 100%;
  height: auto;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 52px !important;
  background: #190258;
}

.btn-total-nav {
  display: none;
  margin-left: 40px;
  width: 40px;
  height: 40px;
  text-indent: -9999em;
  outline: none;
  position: relative;
}

.btn-total-nav span {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 20px;
  height: 2px;
  margin-top: -1px;
  border-radius: 1px;
  background: #fff;
  transition: 0.3s ease-in-out;
}

.btn-total-nav span:before {
  content: "";
  position: absolute;
  top: -6px;
  left: 0;
  width: 20px;
  border-radius: 1px;
  height: 2px;
  background: #fff;
  transition: 0.3s ease-in-out;
}

.btn-total-nav span:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  width: 20px;
  height: 2px;
  border-radius: 1px;
  background: #fff;
  transition: 0.3s ease-in-out;
}

.btn-total-nav.on span {
  background: none;
  transform: rotate(180deg);
}

.btn-total-nav.on span:before {
  top: 0;
  width: 25px;
  transform: rotate(-45deg);
}

.btn-total-nav.on span:after {
  top: 0;
  width: 25px;
  transform: rotate(45deg);
}

.all-menu-wrap {
  z-index: 99;
  display: block;
  transition: all 0.5s;
  opacity: 0;
  transform: translateX(100%);
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  background: #16181d;
}

.all-menu-wrap.on {
  opacity: 1;
  transform: translateX(0);
}

.all-menu-wrap .wrela {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}

.all-menu-wrap .wrela .menu-header {
  width: 100%;
  height: 70px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.all-menu-wrap .wrela .menu-header h1 {
  visibility: hidden;
  margin-left: 40px;
  width: 0px;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.all-menu-wrap .wrela .menu-header a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.all-menu-wrap .wrela .menu-header a i {
  font-size: 30px;
  color: #fff;
}

.all-menu-wrap .wrela .all-menu-list {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 20px auto 0 auto;
}

.all-menu-wrap .wrela .all-menu-list li {
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
}

.all-menu-wrap .wrela .all-menu-list li a {
  position: relative;
  transition: all 0.5s;
  line-height: 60px;
  font-size: 30px;
  color: #fff;
  font-family: "zeroes one";
}

.all-menu-wrap .wrela .all-menu-list li a span {
  //position: absolute;
  //top: -10px;
  //line-height: 20px;
  font-size: 20px;
  color: #9fc4ff;
}

.all-menu-wrap .wrela .all-menu-list li a.on {
  color: #5cc776;
}

.all-menu-wrap .wrela .all-menu-list li a:before {
  transition: all 0.5s;
  content: "";
  width: 100%;
  height: 2px;
  overflow: hidden;
  display: block;
  background: #5cc776;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
}

.all-menu-wrap .wrela .all-menu-list li a.on:before {
  opacity: 1;
}

/*
.all-menu-wrap .wrela .all-menu-list li a:hover{color:#5CC776;}
.all-menu-wrap .wrela .all-menu-list li a:hover:before{opacity:1;}
*/
.all-menu-wrap .wrela .all-menu-list li .sub-item-wrap {
  display: none;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.all-menu-wrap .wrela .all-menu-list li a.on + .sub-item-wrap {
  display: block;
}

.all-menu-wrap .wrela .all-menu-list li ul li {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.all-menu-wrap .wrela .all-menu-list li ul li a {
  color: #fff;
  font-size: 16px;
  line-height: 20px;
}

.all-menu-wrap .wrela .all-menu-list li ul li a:active {
  color: #5cc776;
}

.all-menu-wrap .wrela .copy {
  position: absolute;
  left: 0;
  bottom: 50px;
  width: 100%;
  height: auto;
  overflow: hidden;
  text-align: center;
  font-size: 16px;
  color: #a3abb5;
}

.sub-header-wrap {
  width: 100%;
  height: 130px;
  overflow: hidden;
  background: #5cc776;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-header-wrap .mpic {
  width: 200px;
  height: 130px;
  overflow: hidden;
  /* background: url(~@/assets~@/assets/image/bg/bg-sub.png) no-repeat center bottom; */
}

.sub-header-wrap .mtit {
  display: flex;
  font-size: 20px;
  color: #0a2030;
}

header .all-menu-wrap .mob-header-wrap {
  width: calc(100% - 40px);
  padding: 0 20px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

header .all-menu-wrap .mem-link-wrap {
  justify-content: center;
}

header .all-menu-wrap .func-item-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.sub-item-wrap li a,
.profile-img-dropdown li a {
  position: relative;
}

.img-coming-soon {
  position: absolute;
  left: 0;
  top: 0;
}

.mob-wallet-connect-tip {
  margin-left: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>


const staticFullStoryData_7 = 		{
		id : 7,
		title: "힐러-탄 (90일차)",
		body: `<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white;font-weight:bold'>7화 #. 힐러 – 탄 (90일 차)</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>할라아사나</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>다누라아사나</span><span lang=EN-US
style='line-height:160%;color:white'>, V</span><span style='line-height:160%;
color:white'>업 등등</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탄은 요가로 몸을 풀어주며 지구보다 두 배 더 눈부신
아침을 시작했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>오늘은 아레테 행성에 온 지 </span><span
lang=EN-US style='line-height:160%;color:white'>90</span><span
style='line-height:160%;color:white'>일째</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>슬슬 본부로부터 지구로 복귀하라는 지시가 내려올
테니 도감 작업 마무리에 심열을 기울여야만 했다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>행성에 있는 모든 개체와 생물을 전부 기록했다고
확신할 수 있었지만</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>아직 작성하지 못한 가장 중요한 부분이 있어서 이른 아침부터 책상 앞에 앉았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>도감은 총 두 권으로 구성했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하나는 기본 도감과 또 다른 하나는 기밀판</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>기밀 판에 등재된 개체나 생물의 공통점은 사람과의
교류성이 적어 필요 이상의 공격성을 보이고</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>야행성이 높다는 것이다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아는 만큼 보인다는 말이 있지만 한 편으로는 모르는
게 약이라는 속담 또한 존재한다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>사람들에게 알 권리가 있다는 건 탄도 매우 중요하게
생각하는 부분이지만</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>기밀 판에 작성된 개체나 생물에 대해 알게 된다면 괜한 두려움을 심어줄 수 있을
거라는 생각에 사람들의 심리적 안정을 위해 따로 기록하였다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>물론 위험할 수 있다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>공격성이 높은 존재들을 일반인들에게 공개하지 않을 목적으로 따로 기재한 건 잘못된
선택일 수 있다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>다만 이 생물이 본부의 컨트롤 하에 앞으로 변화할
수도 있고</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>아직은 임시로 혼자 파악한 거라 정확하지 않을 수 있다는 것도 간과해선 안 된다고
판단했으며</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이 모든 부분은 본부에서 판단하는 게 옳다고 생각했기에
따로 기재한 것이다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>거버넌스라면 분명 바른 판단을 할 것이라 믿으며</span><span
lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>탄은 기밀 판을 펼치고 마지막으로 가장 중요한 부분에 빨간색으로 표시하기 시작했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이들은 기밀 판에 있는 위험한 존재들 속에서도 상위권에
속하므로 특히 주의해야 한다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>공격성 </span><span
lang=EN-US style='line-height:160%;color:white'>70%, </span><span
style='line-height:160%;color:white'>잔혹성 </span><span lang=EN-US
style='line-height:160%;color:white'>100%, </span><span style='line-height:
160%;color:white'>도구 활용 능력 </span><span lang=EN-US style='line-height:160%;
color:white'>90% </span><span style='line-height:160%;color:white'>등등</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>잔인하면서도 머리가 좋아서 판단 능력이 인간만큼
있고 이족보행을 하므로 나무를 타는 것에도 능숙하다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>공격성이나 잔혹성이 더 높은 존재도 한 개체 존재하지만
탄은 이 녀석들이 도구를 활용하는 능력 때문에 더 위험하다고 판단했다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>얼마 전에 만났던 형형색색의 색감을 가진 밀코인</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지금은 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>변종 밀코인</span><span lang=EN-US style='line-height:160%;
color:white'>’</span><span style='line-height:160%;color:white'>이라 부르겠다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탄은 동물과 소통하는 능력이 뛰어났다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>덕분에 배기처럼 자신의 의지와는 상관없이 자연 때문에
생명을 잃는 존재들이 많다는 것을 파악했고</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>이를 작품으로 삼으며 연구하고
도감을 작성한 것이다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 변종 밀코인의 생각만은 도저히 알 수가 없다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>생김새 하며 느낌이 사람과 같아서</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>우리처럼 지구에서 온 존재일까도 생각해봤지만</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>녀석들은 영어도</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>한자도</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>한글도</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>독일어도</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>고대어도 아닌 다른 언어를 사용했다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span><span style='line-height:160%;color:white'>이따금 괴성도 함께
섞어가며</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>어느 정도 듣다 보니 규칙적인 흐름이 발견되긴 했지만
그들의 언어를 이해하기엔 시간이 너무 짧았고</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>옆에서 더 파악할 수 없을
만큼 공격성을 보였다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>변종 밀코인은 처음부터 달려들지 않고 우선은 상대의
눈을 뚫어져라 쳐다보며 상황을 살폈다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span><span style='line-height:160%;color:white'>그리고는 코를 킁</span><span
lang=EN-US style='line-height:160%;color:white'>- </span><span
style='line-height:160%;color:white'>훌쩍였다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>처음에 그들이 코를 훌쩍이는 행동은 큰 의미가 없다
생각했지만 아니었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>무작정 본능대로 움직이는 다른 동물들과 달리</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>상대를 파악하던 시간이고 판단을 마쳤다는 것을 의미했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>야생 동물 중에서도 포식자가 하는 행동</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그들에게 가까이 다가가던 탄은 묘한 긴장감에 걸음을
멈췄고 녀석의 눈을 주시하며 뒤로 조심스럽게 물러났다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>환경 운동가로서 오지도 마다하지 않고 다니다가 악어라던가
사자 등</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>여러 포식자를 다양하게 만나봤던 탄은 알 수 있었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>런</span><span lang=EN-US
style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탄은 캡슐을 향해 죽을힘을 다해 뛰었고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>다행히 물가가 사이에 있었던 덕에 녀석이 쫓아오지 못하고 물에 휩쓸려 갔다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그날 이후로 며칠간 캡슐에서 나가지 않았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>냄새를 맡은 야생 포식자가 주위를 지키고 있을지도
모르니까</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>끝내 녀석들에 대해서는 알아낸 게 많지 않다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이족보행을 한다는 것과 잡식을 한다는 것</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>그리고 피부색이 화려할수록 잔혹성이 높다는 사실</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탁</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탄은 알고 있는 마지막까지 온 힘을 다해 적고 펜을
내려놓았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>홀로그램에 언어 인식으로 입력할 수도 있지만 탄은
아날로그 방식처럼 손으로 직접 적는 게 편했다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>손은 조금 아프지만 그래서 더더욱 직접 한 기분이랄까</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>의외로 할머니 같은 마인드를 가진 탄은 기지개를
쭉 켜다가 어디선가 들리는 규칙적인 소리에 뒤를 돌았다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>저긴</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>통신기가 있는 위치인데</span><span lang=EN-US style='line-height:160%;
color:white'>?’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그와 동시에 통신기가 소리를 낸 게 처음이라는 걸
깨달았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이제껏 본부에 보고하기 위해 일정한 날짜마다 전송을
보냈을 땐 별다른 소리가 없었고</span><span lang=EN-US style='line-height:160%;color:white'>,
</span><span style='line-height:160%;color:white'>탄은 통신기가 애초에 소리 없는 기계인 줄 알았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그런데 </span><span
lang=EN-US style='line-height:160%;color:white'>90</span><span
style='line-height:160%;color:white'>일 만에 갑자기 내다니</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>탄은 의아한 표정을 지으며 일단 다가가 보았다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그때 다른 밀코인의 목소리까지 한 번에 몰려들었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>-</span><span
style='line-height:160%;color:white'>드</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>드디어 연결된 거야</span><span
lang=EN-US style='line-height:160%;color:white'>?! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>-</span><span
style='line-height:160%;color:white'>영영 지구로 못 돌아가고 죽는 줄 알았다고ㅠㅠ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>5</span><span
style='line-height:160%;color:white'>년 동안 함께 거버넌스의 연구실에서 생활을 보냈을 동료겠지만 친하게 지내던 몇몇
이외에</span><span lang=EN-US style='line-height:160%;color:white'>, 1</span><span
style='line-height:160%;color:white'>만 명의 목소리를 전부 아는 것은 아니었기에 누구인지 파악할 순 없었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>단순히 밀코메다</span><span
lang=EN-US style='line-height:160%;color:white'>N </span><span
style='line-height:160%;color:white'>중 한 명이라는 것밖에</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그보다 중요한 게 있었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탄은 거버넌스의 명칭을 부르며 조금 큰 목소리로
말했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>거버넌스</span><span lang=EN-US
style='line-height:160%;color:white'>! </span><span style='line-height:160%;
color:white'>제가 지구로 돌아가기 전</span><span lang=EN-US style='line-height:160%;
color:white'>...” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 </span><span
lang=EN-US style='line-height:160%;color:white'>90</span><span
style='line-height:160%;color:white'>일 만에 연결된 통신에 마음을 놓은 밀코인은 각자의 말을 하기 바빴고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>그중에 몇은 목 놓아 우는 바람에 모든 소리가 섞였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>탄은 통신기가 망가졌던 것도 모르고 본인이 할 일에
집중할 만큼 이성적인 사람이었다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그래서 특히 우는 밀코인을 이해할 수 없었고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>이번에도 역시 제 할 일을 하기 위해 다른 밀코인들의 음성을 모두 껐다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>자신에게만 안 들리게 한 방법이었으며 전체 음소거하면
되었기에 버튼 하나로 처리할 수 있었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이제야 조용해진 기분을 느끼며 탄은 다시 입을 열었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>거버넌스</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>제가 지구로 돌아가기 전에 </span><span lang=EN-US style='line-height:160%;
color:white'>NGO </span><span style='line-height:160%;color:white'>단체를 도와 지구 복원에
힘쓸 기업인들 리스트 좀 알아봐 주세요</span><span lang=EN-US style='line-height:160%;
color:white'>.” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지구로 돌아가면 하루라도 빨리 아레테 행성의 멋진
모습에 대해서 알리고 수많은 기업인에게 후원받을 생각이다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지금 탄의 머릿속에 한 가지의 생각이 더 있다면</span><span
lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>본인이 다시 아레테 행성으로 돌아오는 날까지 자기 작품과 아이들이 부디 잘 살아있기를</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>`
}
export default staticFullStoryData_7

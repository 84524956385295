import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import BootstrapVue3 from 'bootstrap-vue-3';
import { getInstance } from './api';
import { BToastPlugin } from 'bootstrap-vue-3';
import Jazzicon from 'vue3-jazzicon/src/components';
import PiniaPersist from 'pinia-plugin-persist';
import Toast from 'vue-toastification';
import { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

/* 아래거 말고 index.html 에서 injet 한 것으로 사용 */
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const pinia = createPinia();
pinia.use(PiniaPersist);
const app = createApp(App);
app.config.globalProperties.$axios = { ...getInstance() };
app.use(BToastPlugin);
app.use(Toast, {
  position: POSITION.TOP_CENTER,
  timeout: 3000,
  containerClassName: 'my-container-class',
});
app.use(pinia).use(router).use(BootstrapVue3).mount('#app');

// eslint-disable-next-line vue/multi-word-component-names
app.component('Jazzicon', Jazzicon);

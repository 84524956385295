const roadmapRoutes = [
  {
    path: 'roadmap/plan',
    name: 'RoadmapPlan',
    component: /* webpackChunkName: "RoadmapPlan" */ () =>
      import('@/views/roadmap/RoadmapPlanView'),
  },
]

export default roadmapRoutes

import FullStoryView from '@/views/community/FullStoryView.vue'

const communityRoutes = [
  {
    path: 'community/full-story',
    name: 'FullStory',
    component: FullStoryView,
    meta: { authRequired: true },
  },
  {
    path: 'community/full-story/:id',
    name: 'FullStoryDetail',
    meta: { authRequired: true },
    component: () =>
      import(
        /* webpackChunkName: "FullStoryDetail" */ '@/views/community/FullStoryDetailView.vue'
      ),
  },
  {
    path: 'community/my-story',
    name: 'MyStory',
    meta: { authRequired: true, walletRequired: true },
    component: () =>
      import(
        /* webpackChunkName: "MyStory" */ '@/views/community/MyStoryView.vue'
      ),
  },
  {
    path: 'community/notices',
    name: 'Notices',
    meta: { authRequired: true },
    component: () =>
      import(
        /* webpackChunkName: "Notices" */ '@/views/community/NoticesView.vue'
      ),
  },

  {
    path: 'community/faq',
    name: 'Faq',
    component: () =>
      import(/* webpackChunkName: "Faq" */ '@/views/community/FaqView.vue'),
  },
  {
    path: 'community/faq/:id',
    name: 'FaqDetail',
    component: () =>
      import(
        /* webpackChunkName: "FaqDetail" */ '@/views/community/FaqDetailView.vue'
      ),
  },
  {
    path: 'community/news',
    name: 'News',
    component: () =>
      import(/* webpackChunkName: "News" */ '@/views/community/NewsView.vue'),
  },
]

export default communityRoutes

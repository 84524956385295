const staticFullStoryData_8 = 		{
		id : 8,
		title: "이노베이터-스티브 (30일차)",
		body: `<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'><img width=373
height=289 id="그림 5" src="/image/story8/image001.jpg"></span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white;font-weight:bold'>8화 #. 이노베이터 - 스티브 (30일 차)</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>흠</span><span lang=EN-US style='line-height:
160%;color:white'>... </span><span style='line-height:160%;color:white'>이 탐사경과 보호복으로는
안 되겠어</span><span lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>기능을 좀 개선해야겠군</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>탐사경으로 인식할 수 있는 빛의 영역이 너무 적어</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>그리고 탐사경과 기지에 통신할 수 있는 기능을 넣어서 실시간으로 자료를 백업하고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>탐사한 지역의 지도가 자동으로 만들어지도록 하면 좋겠어</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>우주복은 뭐가 이렇게 화려해</span><span lang=EN-US
style='line-height:160%;color:white'>? </span><span style='line-height:160%;
color:white'>예비 우주복까지 총 </span><span lang=EN-US style='line-height:160%;
color:white'>5</span><span style='line-height:160%;color:white'>개의 우주복 디자인이 왜 다
다른 거지</span><span lang=EN-US style='line-height:160%;color:white'>? </span><span
style='line-height:160%;color:white'>안드로메다인들하고 클럽 파티라도 하는 줄 아나</span><span
lang=EN-US style='line-height:160%;color:white'>? </span><span
style='line-height:160%;color:white'>정신이 다 산만해지는군</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>급한 일을 마치고 나면 전부 검은색으로 바꿔야겠어</span><span lang=EN-US
style='line-height:160%;color:white'>.’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>스티브는 한 시도 쉴 새 없이 바쁘게 움직였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>행성에 도착한 뒤로 자잘한 특이점부터 통신이 끊겨버리는
등의 큰 돌발 상황이 있었지만 무엇 하나 스티브의 관심을 오래 잡지는 못했다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>새로운 행성이라는 곳은 엔지니어인 스티브에게는 놀이동산이나
다름없었으니</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>해야 할 일이 너무 많고 가봐야 할 곳이 넘쳐났다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>다행히도 스티브가 도착한 행성의 자전 속도는 지구보다 </span><span
lang=EN-US style='line-height:160%;color:white'>3</span><span style='line-height:
160%;color:white'>시간 정도가 긴 편이었고 더 많은 시간을 연구에 쏟을 수 있었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>오늘은 그동안 설치해둔 관측 장비에서 수집한 데이터를
분석하고 통신기를 점검해볼 계획이라 평소보다 빠르게 아침 준비를 마쳤다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>새로이 생성된 밀코메다 은하는 아직 미지의 세계이다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>수천 년 동안 지구의 과학자들이 알아냈던 우주에 대한 지식은 이제 새로운 관측을
기반으로 다시 쓰여야 하기에</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>스티브는 우주 만원경과 추진 로켓을 조립하여 발사하기로 계획하며 가장 좋은 지반을
고르고 다녔다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지구에서부터 가져온 에어보드는 활용도가 좋았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>가장 좋은 지반을 알아내기 위해서는 최대한 많은 곳을 여러 번 가보는 게 좋았고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>사막과도 같은 지형이라 로버 같은 걸 이용했으면 불편했을 테니까</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>오늘도 스티브는 다빈치를 에어보드에 태우고 모랫바닥
위를 서핑했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>그런데</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>퍽</span><span lang=EN-US style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>끝이 어딘지도 모를 만큼 수평선을 보이는 땅과 드넓은
하늘</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>듬성듬성 서 있는 선인장은 똑같은데 허공의 뭔가에 부딪혀서 땅으로 떨어졌다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>정신을 차리고 땅에서 몸을 일으켜 허공을 만져보니
눈에 보이지 않는 벽 같은 게 있었고 주먹으로 두들겨 보니 </span><span lang=EN-US style='line-height:
160%;color:white'>‘</span><span style='line-height:160%;color:white'>퉁퉁</span><span
lang=EN-US style='line-height:160%;color:white'>’ </span><span
style='line-height:160%;color:white'>같은 소리를 내었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>분명 뭔가가 있는 건 확실했고 소리로 가늠해봤을
때 두껍지는 않은 것 같은데 아무리 힘을 줘도 뒤로 밀려나지 않았으며</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>스티브는 그 자리에
그대로 서 있는데 묘하게 벽이 가까워지는 게 느껴졌다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>버티고 버티다가 한 발짝 뒤로 밀려나니 뒤에도 벽인
생긴 느낌이 들었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>당황한 스티브는 주위를 살폈고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>똑같이 사막과 하늘의 풍경을 띄고 있는 방향 말고 파란 하늘만이 있는 곳으로
한 발 내디뎠다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>절벽이라면 그대로 떨어질 위험도 있었지만 지금은
알 수 없는 벽 틈 사이에 갇히기 일보 직전이라 다른 선택지가 없다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>스티브는 두 눈을 질끈 감고 서둘러 다음 발을 내디뎠고
이내 경악을 금치 못했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>마치 하늘에 떠 있는 것과 같은 기분</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>분명 바닥이 없는데 발밑을 뭔가가 받쳐주는 느낌을
받았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>계속 걸어가니 마치 하늘을 걷고 있는 것과 같은
환상에 휩싸이기도 했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>오래 걸리지 않아 현실로 돌아왔지만</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>털썩</span><span lang=EN-US
style='line-height:160%;color:white'>- </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이전에 했던 것처럼 다음 발을 내디뎠는데 거긴 절벽이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>다행히도 에어보드에 블루투스를 연결해놨기에 망정이지</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>하마터면 절벽 밑에 있던 선인장에 그대로 박을 뻔했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지구의 선인장이었다면 우주복이 보호해주었겠지만 이곳의
선인장은 또 어떤 특이점을 가지고 있을지 모르니 되도록 주의하는 게 옳았다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>큰일을 넘긴 스티브는 자신이 떨어졌던 위를 올려다봤다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그리고 주위를 살펴본 결과 자신이 부딪혔던 무형의
벽과 직접 걸었던 하늘은 </span><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>판</span><span lang=EN-US style='line-height:
160%;color:white'>’</span><span style='line-height:160%;color:white'>이라는 걸 확인했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>간단 키트를 챙겨온 스티브는 판을 조사하기 시작했으며
반대편이 비출 만큼 깨끗하게 정제된 광물이라는 것을 알아냈고</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>이내 자신이 처했던
상황에 대해 파악했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지구에 카시미르 효과라는 게 있다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>진공인 공간에서 두 개의 판이 있을 때</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>원래는 아무런 힘도 없어야 하는데 두 판 사이에서 미세한 인력이나 척력이 발생한
것</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>너무 미미해서 측정이 안 되는 게 정상이지만 이
행성에서는 눈에 보일 만큼 움직였다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>전자기적인 인력을 극대화할 수 있는 뭔가가 있을 게 분명한데</span><span
lang=EN-US style='line-height:160%;color:white'>...’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>스티브의 눈이 호기심에 번뜩였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>대체 뭐가 판들을 급속도로 움직이게 한 건지</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>판의 광물을 어떻게 활용할 수 있을지</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>알아봐야 할 게 너무나 많았다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지구의 인류가 이주했을 때 주거 공간을 확보하려면
녀석들의 움직임을 파악해서 통제할 방법도 마련해야 하니 이는 매우 중요한 사안이었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>스티브는 곧장 에어보드를 작동해서 캡슐로 돌아갔으며
자신이 겪고 돌아온 일들에 대해 기록하기 시작했다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>논문의 이름은 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>새로운 행성의 카시미르 효과에 대한 이색 특징 정의</span><span lang=EN-US
style='line-height:160%;color:white'>’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>언젠가 이 행성으로 올 많은 과학자와 엔지니어에게
좋은 귀감이 될 게 분명했기에 가슴이 벅차올랐다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지금 가장 유력한 전자기적 인력은 주변에 있던 선인장이나
지구에서부터 가져온 에어보드였기에 스티브는 에어보드를 자세히 살펴보았다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그리고 등줄기에 빠르게 오른 서늘한 기운에 넋을
놓고 말았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>블루투스 기능이 켜진 게 아니라 버튼이 뜯겨 나간
것</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>동물의 이빨 모양 그대로</span><span
lang=EN-US style='line-height:160%;color:white'>... </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>뒤를 돌자 애완 로봇이자 두더지인 다빈치가 초점
없는 눈빛으로 뭔가를 아그작아그작 씹고 있는 게 보였다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>너</span><span lang=EN-US style='line-height:
160%;color:white'>... </span><span style='line-height:160%;color:white'>뭘 보고 있는
거야</span><span lang=EN-US style='line-height:160%;color:white'>?” </span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>`
}
export default staticFullStoryData_8

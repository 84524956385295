const staticFullStoryData_12 = 		{
		id : 12,
		title: "크리에이터-캐리 (55일차)",
		body: `<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white;font-weight:bold'>12화 #. 크리에이터 - 캐리 (55일 차)</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>현재 본부는 아무도 모르는 싸움을 하고 있다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>가장 기본적이고 중요한 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>통신기</span><span lang=EN-US style='line-height:160%;
color:white'>’</span><span style='line-height:160%;color:white'>에 문제가 생겨서 전부 끊겨
버렸으니</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>낯선 행성에서 홀로 당황하고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>지구로 돌아갈 수 있을까 걱정하고</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>불안한 생활을 할 밀코인들의 생각에 거버넌스의 가슴은 미어지도록 아팠다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그렇기에 하루라도 빨리 통신기를 고치기 위해 노력하였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그전까지는 임시로라도 밀코인의 안전을 확인할 수
있도록 애완 로봇에 부착된 카메라 기능을 작동시켰는데</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>이는 예전 버전에서 사용했다가
현재는 </span><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>시크릿 모드</span><span lang=EN-US
style='line-height:160%;color:white'>’</span><span style='line-height:160%;
color:white'>로 감춰놓은 기능이었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>처음에는 옛날 기능을 완전히 없애지 않고 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>시크릿 모드</span><span lang=EN-US style='line-height:160%;
color:white'>’</span><span style='line-height:160%;color:white'>로 감춰놓는 것에 만족하지 못했는데</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>지금 상황으로선 천운이었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>시크릿 모드</span><span lang=EN-US
style='line-height:160%;color:white'>’</span><span style='line-height:160%;
color:white'>가 없었다면 임시로나마 밀코인의 상황을 본부에서 볼 수 없었을 거다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그런데</span><span
lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>문제는 </span><span lang=EN-US
style='line-height:160%;color:white'>1</span><span style='line-height:160%;
color:white'>만 명의 밀코인 중 유일하게 시크릿 모드의 정체를 알고 있는 인물이 있다는 거다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>[</span><span
style='line-height:160%;color:white'>미래가 기대되는 청년 건축가 </span><span lang=EN-US
style='line-height:160%;color:white'>10</span><span style='line-height:160%;
color:white'>인</span><span lang=EN-US style='line-height:160%;color:white'>]</span><span
style='line-height:160%;color:white'>에 선정되었던 캐리</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>본부에서 훈련받는 </span><span
lang=EN-US style='line-height:160%;color:white'>5</span><span style='line-height:
160%;color:white'>년 동안 주어진 과제를 완벽하게 해내면서 책을 한 시도 놓지 않고 모든 것을 철저하게 계획해서 움직이는 성격이던
그녀의 모습이 거버넌스의 눈앞에 아른거렸다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>결국 시크릿 모드의 정체까지 알아버린 것인가</span><span
lang=EN-US style='line-height:160%;color:white'>...’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하지만 더 큰 문제는 그다음이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>통신기가 오작동하고 꺼지면서 전원 불빛 스위치가
이상해진 것이다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>원래는 작동하게 되면 빨간불이 켜지고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>작동하지 않으면 자연스럽게 불이 꺼져야 하는데 지금은 반대다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>본부에서 화면을 보기 위해 시크릿 모드를 켜면 캐리가
보기에 불이 꺼진 것으로 보이고</span><span lang=EN-US style='line-height:160%;color:white'>,
</span><span style='line-height:160%;color:white'>자신의 존재를 알리기 위해 캐리가 시크릿 모드를 켜서
빨간 불을 확인하면 본부에선 송신이 끊긴다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그렇게 본부와 캐리는 안전을 확인하기 위함과 본인의
존재를 알리기 위함의 아무도 모르는 싸움을 계속하게 되었다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>당연하게도 이상한 느낌을 받는 건 캐리도 마찬가지였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>카멜레온인 가우디가 빨간 불빛을 보고 호기심을 가지다가
자꾸만 끄는 것인지</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>그것도 아니면 이것도 틈틈이 오작동하게 되는 건지</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>알 수는 없지만 자꾸만 시크릿 모드의 빨간 불빛이 꺼진다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캐리는 하는 수 없이 예의주시하기로 생각하며 뒤를
돌았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>본부에 상황을 알리기 위해 시크릿 모드를 관리하는
것도 중요하지만</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>현재 날짜에 해야 하는 매뉴얼을 제대로 이행하는 것도 중요하니까</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>첫날 행성 주위를 둘러볼 때 발견했던 안개에 대해서는
임시로 가져온 키트들만으로는 파악하기가 어려웠다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>대기와 토양 데이터의 분석 결과로는 지구에서 배웠던
다양한 원소뿐 아니라 전혀 다른 원소가 있는 것처럼 보였는데</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>이건 본부로 보내면
본부의 전문가들이 밝혀낼 영역이다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>대신 </span><span
lang=EN-US style='line-height:160%;color:white'>20</span><span
style='line-height:160%;color:white'>시간 와중에 </span><span lang=EN-US
style='line-height:160%;color:white'>5~10</span><span style='line-height:160%;
color:white'>분 정도만 지속된다는 것을 알게 되었고</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>소독된 통에 안개의
일부를 담아서 단단히 밀봉해 놓았다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span><span style='line-height:160%;color:white'>훗날 지구로 돌아가서 자세하게 연구할 예정이다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>다음으로는 캡슐을 나와 금빛 씨앗을 심어 놓은 장소로
향했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>원래 계획대로라면 한옥 마을을 건설하려고 했던 위치인데</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>안개가 짙어지는 현상을 알아내기 위해 대지와 토양을 조사하다가 식물이 자라기
적합한 곳이라는 것을 알아내게 됐었다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>수치와 데이터는 거짓말을 하지 않아</span><span
lang=EN-US style='line-height:160%;color:white'>.’</span><span
style='line-height:160%;color:white'>라는 확고한 믿음이 있던 캐리는 </span><span lang=EN-US
style='line-height:160%;color:white'>50</span><span style='line-height:160%;
color:white'>일 차에 금빛 씨앗을 심었고 예상대로 빠른 성장세를 보였다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>오히려 생각보다 너무 빨리 자라서 당황할 정도였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>3</span><span
style='line-height:160%;color:white'>일 만에 넝쿨이 자라나고 </span><span lang=EN-US
style='line-height:160%;color:white'>5</span><span style='line-height:160%;
color:white'>일 만에 열매가 열려 포도라는 것을 확인했고</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>발효 또한 순식간에
완성 됐다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>이러다가는 </span><span lang=EN-US
style='line-height:160%;color:white'>75</span><span style='line-height:160%;
color:white'>일 차에 작품 만들기를 하기 전까지 할 일이 없게 돼버리는 게 아닌가</span><span lang=EN-US
style='line-height:160%;color:white'>...’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캐리는 기쁘기보다 걱정이 몰려왔다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아무것도 안 하는 순간을 자주 겪지 않아봐서 할
수 있을지 모르지만 그렇다고 뒤에 할 일을 먼저 하는 것은 계획에 안 맞았기에</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>계획을 많이 세우고 그대로 지키는 캐리였기에 여러
가지 생각을 돌렸다</span><span lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>그러면서도 포도를 밟고 있는 발은 멈추지 않았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그 옆에는 이미 발효돼서 연도까지 적혀 나열된 와인들이
있었고</span><span lang=EN-US style='line-height:160%;color:white'>, 2</span><span
style='line-height:160%;color:white'>일 차에는 시음해본 키트 또한 아직 남아 있었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캐리는 생각하며 주위를 둘러보다가 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>씨앗 심기</span><span lang=EN-US style='line-height:160%;
color:white'>’</span><span style='line-height:160%;color:white'>의 종류를 늘려보면 어떨까 떠올렸다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지금처럼 좋은 땅에서 빠르게 나는 포도마다 곧바로
밟아서 발효시키는 게 아니라</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>청포도와 적포도의 종을 섞거나 이틀 전에 자라난 포도와 지금 자란 포도를 섞어보는
등의 실험</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>'</span><span
style='line-height:160%;color:white'>이참에 나만의 와인을 개발해봐</span><span lang=EN-US
style='line-height:160%;color:white'>?' </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아무것도 하지 않고 있을 시간을 견디기 힘들 것
같아서 해본 이러저러한 생각이었지만</span><span lang=EN-US style='line-height:160%;color:white'>,
</span><span style='line-height:160%;color:white'>되새길수록 괜찮은 아이디어 같았다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그때 캐리의 마음을 응원이라도 하는 듯</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>한구석에서 자라던 넝쿨에서 청포도와 적포도와는 다른 색상의 포도가 자라났다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이런 색상의 과일이 있었나 싶은 정도로 화려한 색감으로
뒤 덮인 포도 알갱이</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캐리는 다른 포도와 다른 포도알을 들며 환희했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>변종은 가장 희소한 자원이지</span><span lang=EN-US
style='line-height:160%;color:white'>!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앞으로 변종 포도종을 여러 개 만들어볼 생각으로</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>캐리는 와인 보관 창고를 한가득 세울 작정으로 지대를 계산해 나아갔다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>
`
}
export default staticFullStoryData_12

const staticFullStoryData_10 = 		{
		id : 10,
		title: "이노베이터-스티브 (90일차)",
		body: `<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white;font-weight:bold'>10화 #. 이노베이터 – 스티브 (90일 차)</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>스티브는 얼굴에 흙먼지를 잔뜩 뒤집어쓴 채로 마지막
문장을 써 내려갔다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>[</span><span
style='line-height:160%;color:white'>비록 나는 존재하지 않을지라도 함께 동봉한 자료만은 필히 진실이었음을</span><span
lang=EN-US style='line-height:160%;color:white'>...] </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>새로운 행성에 와서 매뉴얼을 게을리하지 않고 지구의
인류를 이주시키기 위해 필요한 자료 수집을 위해 늘 노력했다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 행성에 도착한 첫날 본부와의 통신이 끊겼으며
같은 밀코인들끼리도 전혀 연락하지 못한 채 </span><span lang=EN-US style='line-height:160%;
color:white'>90</span><span style='line-height:160%;color:white'>일이라는 시간을 보냈다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>다른 밀코인들은 어땠는지 몰라도 스티브만은 애완
로봇의 특이점을 눈치채고 본부가 지켜보고 있다는 사실을 파악해내는데 성공했는데</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그뿐이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>달라진 건 아무것도 없다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>원래대로라면 지구로 복귀를 시도할 </span><span
lang=EN-US style='line-height:160%;color:white'>90</span><span
style='line-height:160%;color:white'>일이 다 되었음에도 통신기는 고쳐지지 않았으며 </span><span
lang=EN-US style='line-height:160%;color:white'>80</span><span
style='line-height:160%;color:white'>일 이후부터는 본부에서도 별다른 신호를 보내오지 않아서 정처 없는 시간만 보내고
있을 뿐이었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>스티브는 처음으로 무력감을 느꼈다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>늘 무언가를 알아내고 파악하기 바빴는데</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>자신이 아무리 알아내봤자 우주 속에 묻힐 보물이라는 걸 깨달아버렸기에</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>침대에서 움직이지 않은 채 </span><span
lang=EN-US style='line-height:160%;color:white'>3</span><span style='line-height:
160%;color:white'>일 정도를 보내다가 긴 한숨을 쉬었다</span><span lang=EN-US style='line-height:
160%;color:white'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그때 막혀 있던 뇌에 산소가 한 번에 들어가며 두뇌가
회전했고</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>이전에는 놓치고 갔던 가장 중요한 사실을 알아냈다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>내가 돌아가지 못하더라도 논문을 보내는 건 시도해볼 수 있잖아</span><span
lang=EN-US style='line-height:160%;color:white'>!’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>스티브는 지구에서 뛰어난 엔지니어였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>물론 가장 두각을 보인 분야는 메타 관련 인터페이스와
각종 부대 장비 개발이었지만</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>우주학에 단번에 매료돼서 이곳까지 온 거다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>언젠가 고대 점성술에 머무르던 시대부터 천문학을
거쳐 우주학으로 발전하기까지 호기심과 끈기로</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>남다른 관점으로 공헌해온 과학자들의
계보를 잇는 꿈 또한 잊지 않았다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>공부부터 시작해서 목표까지 잊어버린 것은 하나도
없다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>유일하게 달라진 점은 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>내가 지구로 돌아가지 못하게 된 상황</span><span lang=EN-US style='line-height:
160%;color:white'>’ </span><span style='line-height:160%;color:white'>뿐인데</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>이건 나의 지식과 목표에 티끌만큼의 영향도 주지 못한다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>가장 중요한 사실을 왜 잊어버렸던 건지</span><span
lang=EN-US style='line-height:160%;color:white'>...’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>스티브는 며칠 동안 낭비한 시간을 아까워하면 죄책감을
원동력 삼아 더욱 서둘러 캡슐 분해 및 크기별 재조립 설계도를 측정해 나갔다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>90</span><span
style='line-height:160%;color:white'>일째인 날까지 통신이 연결되지 않는다면 어차피 이 행성에서 평생 있어야 할 거</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>시간은 많았다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>90</span><span
style='line-height:160%;color:white'>일까지 기다려보다가 결국에 통신이 연결되지 않는다면</span><span
lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>지구에서부터 타고 온 캡슐을 분해해서 논문이 들어가고 블랙홀을 안전하게 통과할
만큼의 크기로 재조립한 뒤 혼자 떠나보낼 작정이었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>어차피 필요한 재료는 기존 캡슐에 다 있으니까</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>다시금 열정을 내보이며 바쁜 시간을 보냈다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그리고 </span><span
lang=EN-US style='line-height:160%;color:white'>90</span><span
style='line-height:160%;color:white'>일 당일</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>역시나 통신이 연결될 것과 같은 기미는 보이지 않았고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>원래 계획대로라면 </span><span lang=EN-US
style='line-height:160%;color:white'>24</span><span style='line-height:160%;
color:white'>시까지 기다려볼 작정이었지만 하루 먼저 작업을 시작하기로 결정했다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>너무 고대하며 기다린 탓에 더 일찍 지쳐버린 까닭도
있었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>스티브는 장비를 들고 캡슐의 가장 바깥 나사를 하나
돌려 뺐다</span><span lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>툭</span><span lang=EN-US style='line-height:
160%;color:white'>... </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>손바닥 위로 떨어진 작은 나사 하나</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>평범한 부품일 뿐인데 어쩐지 감회가 남달라서 다음
나사를 돌릴 수가 없었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그 순간</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>캡슐 안쪽에서부터 익숙하지만 너무 오랜만에 들어봐서 어색한 통신음이 들려왔다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>삐</span><span lang=EN-US
style='line-height:160%;color:white'>- </span><span style='line-height:160%;
color:white'>삐</span><span lang=EN-US style='line-height:160%;color:white'>- </span><span
style='line-height:160%;color:white'>삐</span><span lang=EN-US style='line-height:
160%;color:white'>- </span><span style='line-height:160%;color:white'>삐</span><span
lang=EN-US style='line-height:160%;color:white'>- </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>일정한 타이밍에 울리는 날선 소리를 알아차린 스티브는
곧장 캡슐 안으로 들어갔고</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>입장을 하는 것과 동시에 동시 입장하는 밀코인의 목소리를 만났다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>90</span><span
style='line-height:160%;color:white'>일 만에</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>극적으로 통신이 연결된 것이다</span><span lang=EN-US style='line-height:160%;
color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>조금만 더 빨리 캡슐을 분해했으면 통신이 돌아왔어도
자신은 지구를 향해 이륙하지 못했을 거란 생각에</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>스티브는 짠내나는 눈물을 머금었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그도 그럴 것이 전부 분해한 우주 캡슐을 사람의
힘으로 그것도 혼자서 완벽하게 복구하리라는 건 불가능한 일이 아닌가</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>만약 분해한 캡슐을 복구했다 하더라도 우주로 나가서
산산히 흩어질지 알수 없는 일이니</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>스티브는 이러한 상황까지 가지 않은 것에 대해 감사해하며
입을 열었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>다들</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>살아 있었구나</span><span lang=EN-US style='line-height:160%;color:white'>...
</span><span style='line-height:160%;color:white'>다행이야</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>다행</span><span lang=EN-US style='line-height:160%;color:white'>...!!!”
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>어쩌면 스티브는 애써 모른 척했지만</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>그 누구보다 지구로 돌아가고 싶었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>시원한 맥주 한 잔도 생각나고 아직 만들어보지 못한
기계들이 많이 생각나서 아쉬울 따름이었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>스티브는 뺏던 나사를 서둘러 꽉꽉 조이고 행성을
떠날 준비를 했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>정이 든 생물 두더지들을 각자 가족과 생이별시키고
싶지 않았기에 한 마리도 못 데려가는 건 아쉬웠지만</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>언젠가 다시 이 행성에 온다면 더 많은 가족을 이루고
있길 축복하며</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>스티브는 캡슐에 올라탔다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>그럼 제군들</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>언젠가 다시 만날 그날을 위해</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>아디오스</span><span
lang=EN-US style='line-height:160%;color:white'>!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>척</span><span lang=EN-US
style='line-height:160%;color:white'>-! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>스티브는 생물 두더지들의 배웅을 받으며 행성을 떠났다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>
`
}
export default staticFullStoryData_10

const staticFullStoryData_13 = 		{
		id : 13,
		title: "크리에이터-캐리 (75일차)",
		body: `<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white;font-weight:bold'>13화 #. 크리에이터 - 캐리 (75일 차)</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>쾅쾅</span><span lang=EN-US
style='line-height:160%;color:white'>!! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>누군가의 힘으로 캡슐이 사정없이 흔들렸다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>통신기가 끊긴 것을 알고 있던 캐리였지만 위급한
순간이 되니 신에게 바라는 마음으로 손이 제멋대로 나아갔다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>제발</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>제발 살려줘</span><span lang=EN-US style='line-height:160%;color:white'>!!’
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하지만 캐리의 간절한 손길에 통신기의 송신 버튼을
누르기 전</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>캡슐의 문이 강한 굉음과 함께 열렸다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>문을 열고 들어온 자들은 징그러울 정도로 얼굴이
함몰된 모습이었는데 자세히 보니 밀코인과 비슷한 체형에 변종 와인과 같은 색깔을 지니고 있었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그들은 통신기 앞에서 벌벌 떠는 캐리에게로 서서히
다가왔고</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>하나둘씩 자기 머리 위로 드리워지는 그림자를 보며 캐리는 식은땀을 흘렸다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>나 말고 지구에서 온 밀코인이 더 있었던 거야</span><span
lang=EN-US style='line-height:160%;color:white'>...?’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>3</span><span
style='line-height:160%;color:white'>시간 전</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>캐리는 밀코인 이주 프로젝트의 거의 마지막 단계인 작품 만들기에 열중하고 있었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>크리에이터이자 건축가로 활동하던 그녀는 이 행성에서
어떤 작품을 만들면 좋을까 고민했고 행성의 특성을 차분하게 나열해보았다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하얀 바위와 모래로 이루어진 포근한 행성</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>보기에는 추운 겨울처럼 보이지만</span><span
lang=EN-US style='line-height:160%;color:white'>, 75</span><span
style='line-height:160%;color:white'>일 내내 미국의 캘리포니아와 같은 날씨</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하얀 바위는 대리석처럼 보이지만 생김새와 달리 가볍고
유연성이 좋아서 건축에 최적화된 재료였기에 어떤 작품을 만들던 활용하기가 좋았다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>할 수 있는 게 많다 보니 캐리의 고민이 길어졌다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그렇게 몇 날 며칠을 고민하던 캐리는 임시 설계도로
여러 구상을 해본 뒤 마침내 외쳤다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>집을 만들자</span><span lang=EN-US
style='line-height:160%;color:white'>!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>어떻게 보면 집을 만들자는 건 생리현상만큼이나 아주
자연스러운 생각이었다</span><span lang=EN-US style='line-height:160%;color:white'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>집이라는 건물이 가진 의미는 아주 크니까</span><span
lang=EN-US style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캐리는 즉시 로버에 올라타서 건축에 필요한 자재들을
모으고 다녔다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그렇게 하루하루 지나다 보니 어느새 끝에 다 달았고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이번 주면 작품을 만들어보는 매뉴얼도 마무리될 것
같다는 생각에 의욕이 절로 뿜어져 나왔다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>흐음</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>이참에 더 서둘러 볼까</span><span lang=EN-US style='line-height:160%;
color:white'>?’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>씨앗 심기를 할 때 과하게 양지바른 땅을 발견해서
해야 하는 일을 순식간에 마무리 한 적이 있었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span><span style='line-height:160%;color:white'>그때도 뒤의 할 일을 너무
일찍 시작할 순 없다는 생각에 스스로 </span><span lang=EN-US style='line-height:160%;
color:white'>‘</span><span style='line-height:160%;color:white'>씨앗 심기의 심화 과정</span><span
lang=EN-US style='line-height:160%;color:white'>’</span><span style='line-height:
160%;color:white'>을 만들어가며 시간을 보냈으니</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>이번에도 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>작품 만들기</span><span lang=EN-US style='line-height:160%;
color:white'>’</span><span style='line-height:160%;color:white'>의 시간이 남는다면 여러 개의
작품을 만들면 된다 생각한 것이다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>밀코메다은하로 함께 날아온 </span><span
lang=EN-US style='line-height:160%;color:white'>1</span><span style='line-height:
160%;color:white'>만 명의 밀코인은 모두 소중한 동료이지만 동시에 경쟁자이기도 하니까</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>남들이 하나의 작품만을 완성했을 때 두 개의 작품을
만든다면 더 돋보일 거다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>결정을 한 캐리는 원래대로라면 이대로 마무리했을
하루를 더 길게 끌었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지붕 위로 올라가서 필요한 작업을 완벽하게 끝내고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>집 안으로 들어가 페인트칠을 시작했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이 행성에서 발견한 물질을 갈아서 만든 푸른색 페인트는
발색이 아주 마음에 들었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그런데 콧노래를 흥얼거리며 페인트를 칠하던 캐리의
손이 순간 멈췄다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>이게</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>뭐지</span><span lang=EN-US style='line-height:160%;color:white'>?’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>부엌 벽에 사용된 자재는 로버를 타고 숲 깊숙한
곳에 들어가 발견한 것이었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이 행성에 얼마 없는 나무이기에 옆으로 쓰러져서
죽은 나무를 활용했는데</span><span lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>페인트를 칠하자 자재의 위로 기이한 글자가 보이기 시작했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>마치 종이 위에 펜으로 꾹꾹 눌러 쓰고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>그 위에 종이를 대고 연필을 슥슥 그었을 때 보이는 흔적처럼</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>영어의 알파벳과 한자</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>그리고 한글의 자음과 모음이 이상하게 섞인 글자가 나타났다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>여행을 자주 다녀 영어에 능숙하고 한국에서 </span><span
lang=EN-US style='line-height:160%;color:white'>15</span><span
style='line-height:160%;color:white'>년을 살았던 캐리는 글자를 단박에 알아봤다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>그런데 대체 누가 이런걸</span><span lang=EN-US
style='line-height:160%;color:white'>...?’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캐리는 곧바로 애완 로봇인 가우디를 자기 어깨 위에
얹혀놓고 로버에 올라탔고 자재를 주웠던 장소로 향했다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하나의 동굴과 그 주위를 둘러싼 나무들이 있는 곳</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<span lang=EN-US style='font-size:10.0pt;line-height:107%;font-family:"맑은 고딕"'><br
clear=all style='page-break-before:always'>
</span>

<p class=MsoNormal style='text-autospace:ideograph-numeric ideograph-other;
word-break:keep-all'><span lang=EN-US>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'><img width=339
height=240 id="그림 2" src="/image/story13/image001.jpg"></span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>분명 부엌에 사용한 자재를 주웠던 위치가 확실한데</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>그땐 보이지 않았던 여러 물건이 눈에 밟혔다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그리스</span><span
lang=EN-US style='line-height:160%;color:white'>.. </span><span
style='line-height:160%;color:white'>로마</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>비잔틴</span><span lang=EN-US style='line-height:160%;color:white'>...
</span><span style='line-height:160%;color:white'>심지어 로마네스크까지</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>대부분 형태를 알아볼 수 없을 정도로 부식되거나
부서져 있었지만</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>신중하게 살펴본 결과 건축 연도를 추정할 수 있었고 이를 본 캐리는 놀라서 입을
다물 수 없었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>은하 너머의 행성에서</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>지구에서 유행했던 건축 양식을 발견하다니</span><span lang=EN-US style='line-height:
160%;color:white'>!’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>건축가로서 매우 흥미롭게 느낄만한 일이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캐리는 나중에 거버넌스에 보여주기 위해 건축 양식의
특징이 잘 보이는 것들을 중심으로 로버에 차곡차곡 쌓았다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그런데</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>두어 개쯤 주웠을까 싶었을 때 동굴에서 뭔가가 빛나기 시작했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하나</span><span lang=EN-US
style='line-height:160%;color:white'>.. </span><span style='line-height:160%;
color:white'>둘</span><span lang=EN-US style='line-height:160%;color:white'>.. </span><span
style='line-height:160%;color:white'>셋</span><span lang=EN-US style='line-height:
160%;color:white'>.. </span><span style='line-height:160%;color:white'>네</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>다섯</span><span lang=EN-US
style='line-height:160%;color:white'>? </span><span style='line-height:160%;
color:white'>계속 나온다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>와중에 밖으로 모습을 드러낸 이들은 밀코인과 비슷한
체형을 가지고 있었는데</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>온 피부가 노란색인 밀코메다</span><span lang=EN-US
style='line-height:160%;color:white'>N</span><span style='line-height:160%;
color:white'>과 다르게 동굴 안에서 나타난 녀석들은 화려한 색상으로 뒤덮여 있다는 것이다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>마치</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>변종 포도처럼</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캐리와 변종 밀코인 사이에는 묘한 긴장감이 흘렀고</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>불안이 엄습한 캐리는 녀석들을 주시하다가 빠르게 로버에 올라탔다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그리고는 일단은 가장 안전한 캡슐로 가야 한다는
생각에 무작정 속도를 내었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하지만 문제는 변종인 녀석들이 로버와 맘먹는 속도로
내달렸다는 것</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>그게</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>내가 기억하는 마지막 순간이다</span><span lang=EN-US style='line-height:160%;
color:white'>.’ </span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>`
}
export default staticFullStoryData_13

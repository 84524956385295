import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
dayjs.extend(isBetween)
dayjs.extend(isTomorrow)

export const useMintingStore = defineStore('minting', {
  state: () => ({
    detail: null,
    episodeList: [],
  }),
  getters: {
    currentEpisode: (state) => {
      const found = state.episodeList
        .sort((epA, epB) => epA.EPISODE_START_DTM - epB.EPISODE_START_DTM)
        .find((ep) => {
          if (!ep) return
          const start = dayjs(ep.EPISODE_START_DTM, 'YYYY-MM-DD HH:mm')
          const end = dayjs(ep.EPISODE_END_DTM, 'YYYY-MM-DD HH:mm')

          return dayjs(getNewDate()).isBetween(start, end)
        })

      return found
    },

    // isNowMintable: (state) => {
    //   if (this.currentEpisode && this.currentEpisode.EVENT_YN === 'y') {
    //     return !!this.currentEpisode
    //   }
    //   return false
    // },

    upcomingEpisode: (state) => {
      const sorted = state.episodeList.sort(
        (epA, epB) => epA.EPISODE_START_DTM - epB.EPISODE_START_DTM,
      )

      const futures = sorted.filter((ep) => {
        const start = dayjs(ep.EPISODE_START_DTM, 'YYYY-MM-DD HH:mm')
        return start.isAfter(getNewDate())
      })

      if (futures) {
        return futures[0]
      }

      return null
    },
  },
  actions: {
    setEpisodeList(list) {
      this.episodeList = list
    },
    setDetail(detailData) {
      this.detail = detailData
    },
  },
})



function getNewDate(){
  // 1. 현재 시간(Locale)
  const curr = new Date();

// 2. UTC 시간 계산
  const utc =
    curr.getTime() +
    (curr.getTimezoneOffset() * 60 * 1000);

// 3. UTC to KST (UTC + 9시간)
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const kr_curr = new Date(utc + (KR_TIME_DIFF));
  return kr_curr;
}

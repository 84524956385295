<template>
  <div class="user-avatar">
    <div v-html="identicon" v-if="!avatarImageUrl" />
    <img
      v-else
      :src="avatarImageUrl"
      alt="user avatar"
      :width="diameter"
      :height="diameter"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useWeb3Store } from "@/store/web3Store";
import { useAuthStore } from "@/store/authStore";
import { storeToRefs } from "pinia";

const props = defineProps({
  diameter: { type: Number, default: 35 },
});

const web3Store = useWeb3Store();
const authStore = useAuthStore();

const { avatarImageUrl } = storeToRefs(authStore);

const identicon = computed(() => {
  return jdenticon.toSvg(authStore.emailId, props.diameter);
});

// watchEffect(async () => {
//   if (authStore.MEMBER_SEQ) {
//     const resp = await getMemberInfo({ memberSeq: authStore.MEMBER_SEQ });
//     if (resp.data.resultCode === 200) {
//       if (resp.data.data.profileMap) {
//         userAvatarImageUrl.value = resp.data.data.profileMap.imgPath;
//       }
//     }
//   }
// });
</script>

<style lang="scss" scoped>
.user-avatar {
  background-color: white;
}
</style>

const userRoutes = [
  {
    path: 'mypage/profile',
    name: 'MyProfile',
    meta: { authRequired: true },
    component: () =>
      import(/* webpackChunkName: "MyProfile" */ '@/views/user/MyProfile.vue'),
  },

  // TODO: 개발 완료 후 Release
  {
    path: 'mypage/collections',
    name: 'MyCollections',
    meta: { authRequired: true },
    component: () =>
      import(
        /* webpackChunkName: "MyCollections" */ '@/views/user/MyCollectionView.vue'
      ),
  },
  {
    path: 'mypage/wallets',
    name: 'MyWallets',
    meta: { authRequired: true },
    component: () =>
      import(
        /* webpackChunkName: "MyWallets" */ '@/views/user/MyWalletsView.vue'
      ),
  },
]

export default userRoutes

import { useWeb3Store } from '@/store/web3Store'

let isConnected = false

export function shortenAddress(address) {
  return address.slice(0, 8) + '...' + address.slice(-4)
}

export async function checkAddressByInterval() {
  return await getAddressByInterval()
}

/**
 * 추후에 수정이 필요할 수 있음
 */
// async function getAddressByInterval() {
//   let count = 10
//   const web3Store = useWeb3Store()

//   return await new Promise((resolve) => {
//     const intervalId = setInterval(() => {
//       isConnected =
//         web3Store.isWalletConnected || !!sessionStorage.getItem('address')
//       if (isConnected || count <= 0) {
//         resolve(isConnected)
//         clearInterval(intervalId)
//       }
//       count--
//     }, 200)
//   })
// }

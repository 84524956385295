import { useAuthStore } from '@/store/authStore'
import { useWeb3Store } from '@/store/web3Store'
import { checkAddressByInterval } from '@/utils/wallet'
import { useToast } from 'vue-toastification'

export default async (to, from, next) => {
  const toast = useToast()
  const authStore = useAuthStore()
  const web3Store = useWeb3Store()

  /**
   * 로그인 상태가 아니라면
   * 특정 route 접근 거부
   */
  if (to.meta.authRequired && !authStore.isLoggedIn) {
    next({ name: 'Login', query: { redirect: to.name } })
    toast.error('로그인이 필요합니다.')
    return
  }
  /**
   * 로그인 상태라면
   * authRoutes 접근 거부
   */
  if (to.meta.authRequired === false && authStore.isLoggedIn) {
    next({ name: 'Home' })
    return
  }

  /**
   * 로그인 여부와 지갑 연결 여부 확인
   * 민팅, 나의 행성 스토리
   */
  if (to.meta.authRequired && to.meta.walletRequired) {
    if (!authStore.isLoggedIn) {
      next({ name: 'Login', query: { redirect: to.name } })
      toast.error('로그인이 필요합니다.')
      return
    }

    const isWalletConnected =
      web3Store.isWalletConnected || sessionStorage.getItem('ADDRESS')

    if (!isWalletConnected) {
      next({ name: 'Home' })
      toast.warning('지갑을 연결해주세요.')
      return
    }
  }

  next()
}

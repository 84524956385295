import {getInstance} from '@/api'

const instance = getInstance()
const artworkModule = '/artwork'

export const getEpisodeList = () => {
		return instance.post(`${artworkModule}/episode-list`, {
				EVENT_SEQ: process.env.VUE_APP_EVENT_SEQ,
		});
}

export const getMintView = (request) => {
		/* Response 에서 SALE_TYPE_CD가
				10 : airdrop
				20 : whitelist
				30 : public
		*/
		return instance.post(`${artworkModule}/mint-view`, request)
}

export const transmitTransactionStatus = (request) => {
		return instance.post(`${artworkModule}/payment-coin`, request)
}

export const checkUserWhiteListed = (request) => {
		return instance.post(`${artworkModule}/white-list`, request)
}

const staticFullStoryData_14 = 		{
		id : 14,
		title: "아티스트-앤디 (35일차)",
		body: `<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'><img width=374
height=291 id="그림 1" src="/image/story14/image001.jpg"></span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white;font-weight:bold'>14화 #. 아티스트 - 앤디 (35일 차)</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 화가가 되지 않았다면 와이너리의 주인이 되었을
거다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>몇백 년간 대대로 와이너리를 운영하는 집안에서 태어났기에
그에게 와인은 물과 같은 생존 식량이고 포도나무는 친구나 마찬가지였다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>와인이 없는 삶을 생각조차 할 수 없었기에</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>블루버진 스페이스호를 탑승할 때 가장 아끼던 피노누아 품종 씨앗을 가지고 왔다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>첫날에 행성에 착륙하면서부터 해야 하는 일은 많았지만
앤디는 아끼는 피노누아 품종 씨앗을 가장 먼저 곳곳에 심었다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘90</span><span
style='line-height:160%;color:white'>일 동안이나 이 행성에서 지내야 하는데 한 모금 축일 와인이 없다는 건 끔찍한
일이지</span><span lang=EN-US style='line-height:160%;color:white'>.’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>포도나무 중에서도 피노누아 품종은 재배하기 까다롭기로
유명했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>다행히도 앤디의 집안 와이너리의 주력 품종이자 그가
가장 좋아하는 와인 품종이었기에 잘 키울 자신은 있었지만</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>지구와 다른 행성을 시행착오
없이 완벽하게 완성할 수는 없었다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그래서 실패를 하더라도 빨리 한 뒤 맛있게 숙성된
와인을 하루라도 먼저 마시기 위해 서두른 것이다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>더구나 앤디가 착륙하게 된 행성은 온통 안개로 뒤
덥혀서 수분을 조절하기 어려웠다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>낮과 밤조차 제대로 구별이 되지 않았으며 주변을
온통 바위나 돌산으로만 가득했고 돌산 사이를 지나치는 바람은 기괴한 소리까지 내었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>지구보다 아름다운 행성으로 이주하기 위한 프로젝트라고 들었는데 이미 이 행성은
모습조차 제외당한 게 아닐까</span><span lang=EN-US style='line-height:160%;color:white'>...’
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디가 이처럼 생각하는 것과 어찌 보면 당연한 일이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 만약 처음부터 제외할 곳 같았으면 본부에서
탐사 행성으로 선정하지도 않았을 거라는 생각과 함께 오늘도 탐사를 떠나기 위한 짐을 채비했다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>옛날에 어떤 예능인이 말한 하루살이 동화가 있었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하루살이가 겨울에 눈을 떠서 겨울만 보고 죽었다고
지구가 겨울밖에 없는 행성인가에 대한 이야기였다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이처럼 이 행성 또한 어딘가에는 진흙 속의 진주를
숨기고 있을 수 있으니까</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>비록 탐사 일정에 따라 </span><span
lang=EN-US style='line-height:160%;color:white'>5</span><span style='line-height:
160%;color:white'>일 전부터 주변을 돌아본 결과 아직 파악한 건 역시나 돌산과 안개만이 전부였지만 아직 꿈을 버리기엔 이르다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 </span><span
lang=EN-US style='line-height:160%;color:white'>A1</span><span
style='line-height:160%;color:white'>을 경비견 모드로 바꾸고 캡슐 밖으로 나섰다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>문을 열자 강한 바람과 함께 안개가 쑤욱</span><span
lang=EN-US style='line-height:160%;color:white'>- </span><span
style='line-height:160%;color:white'>들어왔는데</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>이 행성에 도착한 지 벌써 </span><span lang=EN-US style='line-height:160%;
color:white'>35</span><span style='line-height:160%;color:white'>일째로 이 광경 또한 수없이
보았지만 좀처럼 익숙해지지 않았다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>발걸음은 항상 로봇 강아지의 뒤를 따라 한발 한
발 조심스럽게 내디뎠고</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>너무 깊숙한 곳이나 안개가 자욱해 한 치 앞도 보이지 않는 곳은 따로 체크만
해두고 들어가지 않았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>슥슥</span><span lang=EN-US
style='line-height:160%;color:white'>- </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>덕분에 앤디의 탐사 지도에는 </span><span
lang=EN-US style='line-height:160%;color:white'>O</span><span style='line-height:
160%;color:white'>표시보다 </span><span lang=EN-US style='line-height:160%;
color:white'>X</span><span style='line-height:160%;color:white'>표시가 가득하게 되었지만 앤디는
이러한 탐사 방식을 당분간은 바꿀 생각이 없었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>본부에서 지시한 탐사 매뉴얼도 매우 중요하다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 통신기에 문제가 생긴 이 시점에서는 대원의
안전 또한 중요하다고 판단했기에 달라질 건 없을 거다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>대원이 목숨을 잃어 구조대의 연락도 받지 못한다면
이 행성은 우주에 버려지게 될 테니</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>대원을 위해서도</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>막대한 돈을 투자해 프로젝트를 진행한 본부를 위해서도</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>대원이 없다면 우주에 버려지게 될 행성을 위해서도</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>대원의 생존은 중요한 요소라는 걸 앤디는 잘 알고 있었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그런데</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>왕</span><span lang=EN-US style='line-height:
160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>늠름한 자태로 위풍당당하게 앞장서서 가던 </span><span
lang=EN-US style='line-height:160%;color:white'>A1</span><span
style='line-height:160%;color:white'>의 목청이 등 뒤에서 들렸다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>언제 저기로 갔지</span><span lang=EN-US
style='line-height:160%;color:white'>?’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>잠시 다른 생각을 하다가 안개 속에서 엇갈린 건지
다른 곳으로 가 있는 </span><span lang=EN-US style='line-height:160%;color:white'>A1</span><span
style='line-height:160%;color:white'>을</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>앤디는 기기 조작을
통해 옆으로 불렀다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그때</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>안개 속에서 나타난 </span><span lang=EN-US style='line-height:160%;
color:white'>A1</span><span style='line-height:160%;color:white'>의 모습을 본 앤디는 놀라
경악을 금치 못했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>정확히는 </span><span
lang=EN-US style='line-height:160%;color:white'>A1</span><span
style='line-height:160%;color:white'>이 입에 물고 있는 금빛 색상 때문에</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 탐사하면서 자신의 피노누아 포도나무 씨앗과
본부에서 심으라고 지시한 금빛 씨앗에게 알맞은 터를 찾으러</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>씨앗 주머니를 가지고
나왔었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“A1. </span><span
style='line-height:160%;color:white'>그건 장난감이 아닙니다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>돌려주세요</span><span lang=EN-US style='line-height:160%;color:white'>.”
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>강아지이긴 하지만 일단은 로봇이고 낯선 행성을 간
밀코인을 보조하기 위해 시스템화 되어 있는 애완 로봇이다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이 정도라면 알아들을 거라 생각하며 목소리를 낮게
깐 앤디였지만</span><span lang=EN-US style='line-height:160%;color:white'>, A1</span><span
style='line-height:160%;color:white'>은 뒷걸음질을 치며 앤디를 주시했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>히잉</span><span lang=EN-US
style='line-height:160%;color:white'>...” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>A1</span><span
style='line-height:160%;color:white'>은 심통이 난 강아지처럼 투정 부렸다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>주인과 소통하는 애완 기능과 업무를 수월하게 해주는
시스템 기능 사이의 부딪힘이 생긴 듯한데</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>다행히도 업무의 시스템으로
기능이 확정됐는지 서서히 다가왔다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그 모습에 앤디는 안도했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하지만 그것도 잠시</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>녀석의 입에서 떨어진 금빛 씨앗이 데구르르 굴러가더니 안개 너머로 쏙 들어가
버렸다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>깜짝 놀란 앤디는 </span><span
lang=EN-US style='line-height:160%;color:white'>A1</span><span
style='line-height:160%;color:white'>을 지나쳐서 금빛 씨앗이 굴러간 곳으로 허겁지겁 달려갔다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>다행히도 금빛 씨앗은 아직 눈에 보이는 곳에서 빛을
반짝이고 있었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>안개가 자욱해서 우중충한 주변 덕분에 더 잘 보인
거였기에</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>앤디는 이 행성에 도착하고 처음으로 환경에 대해 감사한 마음을 가졌다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>다만 금빛 씨앗을 향해 간단히 손을 뻗을 수는 없었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>가만히 멈춰 있는 주인을 보며 자신이 뭔가를 잘못
했다고 느낌 </span><span lang=EN-US style='line-height:160%;color:white'>A1</span><span
style='line-height:160%;color:white'>이 직접 입에 물고 가져오려 했지만</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>앤디는 </span><span lang=EN-US
style='line-height:160%;color:white'>A1 </span><span style='line-height:160%;
color:white'>마저도 품 안에 들어 안으며 바닥에 떨어진 금빛 씨앗을 주시할 뿐이었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>금빛 씨앗은 안개 안으로 들어간 게 아니었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>안개가 가득한 사이에 있긴 하지만</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>정확히는 그 너머의 어딘가에 들어간 것처럼 보였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그 예로 금빛 씨앗이 떨어져 있는 곳 주변은 묘하게
더 어두워 보였고 허공에는 일그러진 현상이 보였다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>너머</span><span lang=EN-US
style='line-height:160%;color:white'>... </span><span style='line-height:160%;
color:white'>그 너머의 어딘가에 떨어진</span><span lang=EN-US style='line-height:160%;
color:white'>...’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 한참 생각했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그리고 스쳐 들은 지식을 더듬으며 이 현상이 블랙홀
같은 게 아닐까 떠올렸다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>밀코메다 이주 프로젝트에 참여하기 위해 본부에서
</span><span lang=EN-US style='line-height:160%;color:white'>5</span><span
style='line-height:160%;color:white'>년 동안 우주학에 관해 수업받은 </span><span lang=EN-US
style='line-height:160%;color:white'>1</span><span style='line-height:160%;
color:white'>만 명의 밀코인 중 한 명이다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 심화 교육은 기초가 있는 사람들이 더 잘
알아듣듯</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>과학의 전문 지식이 거의 없던 화가 앤디에겐 수박 겉핥기식의 교육이었을 뿐이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그렇기에 현재 앞에 일어난 현상이 블랙홀인지 아닌지조차
확신할 수 없었는데</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>다행히도 블랙홀은 행성이나 별의 소멸 단계에서 생성되고 질량이 어마어마하게 크기
때문에 그와 같은 엄청난 중력으로 끌어당긴다고 한 내용이 기억났다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>위의 내용을 토대로 살펴보자면 현재 일렁거리는 현상과
앤디의 거리는 그다지 멀지 않았음에도 불구하고 끌어당겨지는 느낌은 없으니</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>블랙홀이란 가능성은 조심스럽게 제거했다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>다음으로 의심이 가는 건 </span><span
lang=EN-US style='line-height:160%;color:white'>‘</span><span style='line-height:
160%;color:white'>웜홀</span><span lang=EN-US style='line-height:160%;color:white'>’
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>처음 세상 사람들에게 알려진 지 시간이 꽤 지났지만
아직 확실하게 파악된 건 없었다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>위험한 걸 즐기는 성격은 아니었지만 앤디는 슬슬
결정을 해야만 했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이대로라면 언제 닫힐지 모르는 정체 모를 공간에
금빛 씨앗을 빼앗겨버리고 말 테니</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>금빛 씨앗을 </span><span
lang=EN-US style='line-height:160%;color:white'>50</span><span
style='line-height:160%;color:white'>일 때에 심지 못하면 앤디는 그로써 이주 프로젝트의 탐사원으로서 완벽하게 실패하게
되는 것이다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그런 수식만은 결코 허락할 수 없었기에 앤디는 처음으로
모험하기로 했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>만약 이게 끌어당기는 힘이 없는 변형된 블랙홀이라면 죽음뿐이겠지만</span><span
lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>웜홀이라면 이전의 실수를 최소화하고 생존할 수 있어</span><span
lang=EN-US style='line-height:160%;color:white'>.’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 </span><span
lang=EN-US style='line-height:160%;color:white'>A1</span><span
style='line-height:160%;color:white'>을 품 안에 꼭 안고 일렁거림 안으로 들어갔다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>묵직한 무언가가 짓누르는 느낌에 제대로 서 있기도
힘들었지만 머지않아 가볍게 사라졌다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>스윽</span><span lang=EN-US
style='line-height:160%;color:white'>- </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하지만 사라진 것은 온몸을 짓누르는 중력뿐만 아니었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디가 금빛 씨앗을 집어 들고 뒤로 돌았을 때는
이미 웜홀의 크기가 줄어들고 있었다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘...</span><span
style='line-height:160%;color:white'>큰일 났네</span><span lang=EN-US
style='line-height:160%;color:white'>. </span><span style='line-height:160%;
color:white'>이거 들어가도 되는 건가</span><span lang=EN-US style='line-height:160%;
color:white'>? </span><span style='line-height:160%;color:white'>이미 너무 작아져 있는데 팔
하나만 잘리진 않을까</span><span lang=EN-US style='line-height:160%;color:white'>?’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>괜히 급하게 몸을 구겨 넣었다가 두 동강 나진 않을지
싶었던 탓에</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>앤디는 용기 내지 못하고 발만 동동 굴렸다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러다가 결국 헬멧 하나도 들어가지 못할 크기로
웜홀이 작아졌고 이내 완전히 모습을 감추었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>기후가 엉망인 행성</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>제대로 해보지도 못한 탐사</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>이를 보완하기 위해 잘 심어 보려 했던 금빛 씨앗을 잃어버리고</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>찾은 곳에서는 미아가 됐다라</span><span lang=EN-US style='line-height:160%;
color:white'>... </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>자신도 순식간에 벌어진 상황에 어이가 없어서 웃음이
날 지경인데 본부에서 이 사실을 알게 된다면 얼마나 한심해 보일까 걱정이 됐다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>더구나 웜홀을 지난 후유증이 뒤늦게 나타난 건지
순간 어지러워진 정신에 앤디는 온몸을 휘청거렸고</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>이내 자신이 있는 위치를 확인했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>실로 놀라운 광경이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>우중충하기만 했던 행성 착륙지와 다르게 웜홀을 통해
나온 것은 울창한 숲과 높은 지대</span><span lang=EN-US style='line-height:160%;color:white'>,
</span><span style='line-height:160%;color:white'>그곳에서 밑으로 쉴 새 없이 떨어지는 거대한 폭포의 물</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>물이 바닥과 마찰하며 만들어낸 안개는 산신령의 집과 같은 비현실적인 풍경을 자랑했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>착륙지보다 훨씬 좋잖아</span><span lang=EN-US
style='line-height:160%;color:white'>...? </span><span style='line-height:160%;
color:white'>이거</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>좋아해야 하나</span><span lang=EN-US
style='line-height:160%;color:white'>?’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>앤디는 진지하게 고민했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>`
}
export default staticFullStoryData_14

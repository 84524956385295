<template>
  <div class="connect-error">
    <div class="alert alert-danger alert-dismissible fade show" role="alert">
      <div class="alert-message">
        <div>{{ networkName }} 로 접속하세요</div>

        <button type="button" class="change-network" @click="changeNetwork">
          네트워크 전환
        </button>
      </div>

      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import getChainNameById from "@/utils/chainIdNameMapper";

const emit = defineEmits(["change-network"]);

const changeNetwork = () => {
  return emit("change-network");
};

const networkName = computed(() => {
  return getChainNameById(process.env.VUE_APP_TARGET_NETWORK_CHANNEL_ID);
});
</script>

<style lang="scss" scoped>
.connect-error {
  width: 100%;
  position: absolute;
  z-index: 1000;
}

.connect-error .alert {
  margin: 0 auto;
  border-radius: 0px 0px 5px 5px;
  height: 82px;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #dc3545;
  color: #ffffff;
  border: 0px;
}
.alert-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.change-network {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 3px solid #fff;
  text-align: center;
  padding: 4px 15px;
  margin-left: 15px;
  margin-right: 15px;
}
</style>
const staticFullStoryData_4 = {
		id : 4,
		title: "엔터테이너-지수 (80일차)",
		body: `<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white;font-weight:bold'>4화 #. 엔터테이너 – 지수 (80일 차)</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>또 다른 밀코인을 만나게 된 건 천운이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>미치기 일보 직전이었던 지수와 끊임없이 대화를 해나가
주었고</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>엉망으로 해부돼서 본래의 모습을 잃어버린 통신기 또한 조립해주었으니</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>센트</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>아스거스</span><span lang=EN-US style='line-height:160%;color:white'>,
</span><span style='line-height:160%;color:white'>멜린은 처음 보는 기계의 모습에 조금 당황하는 듯 보였지만
금세 감을 잡았고 </span><span lang=EN-US style='line-height:160%;color:white'>10</span><span
style='line-height:160%;color:white'>분도 안 돼서 조립에 성공했다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>통신기 내부를 봤을 때 부식되거나 달라진 부분은
없어서 따로 고친 것은 없고 통신도 연결되진 않을 거라 말하였지만</span><span lang=EN-US style='line-height:
160%;color:white'>, </span><span style='line-height:160%;color:white'>지수는 조립이 다시
된 것만으로도 크게 안도했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이대로라면 본부에서 비상 연락을 취하거나 구조대를
보내려 사인을 주더라도 알아차리지 못했을 테니까</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 자신이 반쯤 미쳤던 이유를 무작정 통신기를
분해해서 조립하지 못하면서부터 스트레스가 극도로 높아졌고</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>끝내 통신기를 배키라 부르고
애원 복걸하는 상황까지 간 것이라 생각했다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>도저히 혼자서는 헤어 나올 수 없던 악몽 같은 굴레에서
벗어날 수 있게 도와준 센트</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>아스거스</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>멜린에게 고마울 따름이었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 친구들에게 최선과 정성을 다해 저녁 만찬을
대접했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>비록 우주식이긴 하지만 많이들 드세요</span><span
lang=EN-US style='line-height:160%;color:white'>!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>80</span><span
style='line-height:160%;color:white'>일 차이지만 여러 가지 환경으로 정신을 반쯤 놨던 지수는 아직도 캡슐 안에서
생활하였고 그마저도 본인의 이동 동선을 제외하고는 쓰레기가 가득했다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>음식에 올려진 테이블 위에도 이따금 날아다니는 먼지가
보이고</span><span lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>위생적이라고 볼 순 없는 환경에 센트와 멜린은 서로 눈을 마주치며 눈치를 살폈다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그때</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>아스거스가 가장 먼저 식기를 들며 말했다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>비록이라니요</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>저희 때보다 훨씬 먹음직스러운걸요</span><span lang=EN-US style='line-height:160%;
color:white'>? </span><span style='line-height:160%;color:white'>신께서 주신 일용한 양식에
감사하며 잘 먹겠습니다</span><span lang=EN-US style='line-height:160%;color:white'>.” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아스거스의 말이 끝나자 센트와 멜린도 짧은 묵념을
하고서 음식을 먹기 시작했고 처음에는 느릿하게 움직이던 손과 식기가 점점 속도 붙었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>배가 고팠던 모양일까</span><span lang=EN-US
style='line-height:160%;color:white'>? </span><span style='line-height:160%;
color:white'>코델리아 행성에서 식용이 가능한 생물이 있는 건 못 봤는데 그동안 뭘 먹으며 지내온 걸까</span><span
lang=EN-US style='line-height:160%;color:white'>?’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 궁금한 것이 한둘이 아니었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>사실 두 번째 질문은</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>자신이 깊은 우울감에 빠진 탓에 행성 탐사를 게을리 하느라 파악하지 못한 부분이었을
테지만</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그냥 누구라도 대화하고 싶었다</span><span
lang=EN-US style='line-height:160%;color:white'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>아까 신이라고 하시던데</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>종교가 있으신 건가요</span><span lang=EN-US style='line-height:160%;
color:white'>?! </span><span style='line-height:160%;color:white'>어쩌다 우주에 오게 된 겁니까</span><span
lang=EN-US style='line-height:160%;color:white'>?! </span><span
style='line-height:160%;color:white'>나이는요</span><span lang=EN-US
style='line-height:160%;color:white'>!? </span><span style='line-height:160%;
color:white'>지구에선 어느 지역에 사셨어요</span><span lang=EN-US style='line-height:160%;
color:white'>? </span><span style='line-height:160%;color:white'>혹시 저 아세요</span><span
lang=EN-US style='line-height:160%;color:white'>? </span><span
style='line-height:160%;color:white'>저 지수라고 완전 킹왕짱 엔터테이너</span><span
lang=EN-US style='line-height:160%;color:white'>...!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그동안 밥을 잘 챙겨 먹지 않았던 건 지수도 마찬가지였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 지수는 배를 채우기보다 공허했던 마음을 채우는
일이 시급했기에 밥을 먹지 않았으며</span><span lang=EN-US style='line-height:160%;color:white'>,
</span><span style='line-height:160%;color:white'>이러한 행동은 식사는 다른 사람들에게 계속해서 말을 거는
것으로 이어졌다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아스거스는 길게 내려진 곱슬곱슬한 머리카락에 가려져
지수의 물음이 들리지 않은 건지 눈 한 번을 올려보지도 않았고</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>결국 입을 연 건 센트였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>센트는 입에 있는 음식을 꼭꼭 씹어 크게 삼키고는
말을 이었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>첫 번째 질문부터 대답하자면 저희는 모두 신자입니다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>신의 부름을 따라 이곳에 오게 되었죠</span><span
lang=EN-US style='line-height:160%;color:white'>.” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>밀코메다</span><span lang=EN-US
style='line-height:160%;color:white'>N </span><span style='line-height:160%;
color:white'>말고도 밀코메다 이주 프로젝트에 참여한 사람들이 더 있는 줄 상상도 못 했어요</span><span
lang=EN-US style='line-height:160%;color:white'>!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>처음엔 다들 그러죠</span><span lang=EN-US
style='line-height:160%;color:white'>.” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>처음</span><span lang=EN-US
style='line-height:160%;color:white'>? </span><span style='line-height:160%;
color:white'>다들</span><span lang=EN-US style='line-height:160%;color:white'>...?”
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>센트의 입에서 나오는 모든 말은 지수의 호기심을
자극했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 몇 마디 나눠보지도 않은 상태인데 아스거스가
눈치를 주며 말리는 바람에 더 이상 대화를 지속할 순 없었다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>고고하다는 단어가 사람이 된다면 아스거스 같을까
싶은 생각이 들 정도로 예의를 지키는 모습이었기에</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>식사를 하던 도중에 말을 하는
게 마음에 들지 않았으리라 예상한다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 밥을 다 먹고 다시 물어볼 생각으로 아주
잠시 말을 멈췄다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>이 친구들이 화가 나서 떠나버리면 다시 혼자 남게 되니까</span><span
lang=EN-US style='line-height:160%;color:white'>...’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 주체할 수 없는 말과 호기심을 대신해서 팔과
다리를 쉴 새 없이 떨었고 다른 사람들의 식사가 끝나기 만을 기다렸다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그리고 아스거스와 멜린이 식기를 놓자마자 환한 미소를
지었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하지만 질문할 타이밍은 아스거스가 자연스럽게 가져가
버렸다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>보아하니 아직 씨앗을 안 심은 것 같군요</span><span
lang=EN-US style='line-height:160%;color:white'>.” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아스거스는 씨앗의 존재에 대해 알고 있었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>식사를 하던 내내 고개를 식탁에 박은 것처럼 시선을
전혀 들지 않았는데 언제 본 건지는 모르겠지만 그의 말엔 틀린 게 없다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캡슐 안에서만 생활한 흔적</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그럼에도 어디에서도 보이지 않은 작품이라고 부를
수 있는 것</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>향기 나지 않는 금빛 씨앗의 존재</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아스거스는 손을 걷고 일어나며 지수를 도와주겠다고
말했다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그의 말마따나 매뉴얼을 이행하는 건 기본 중의 기본이었기에</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>지수는 아스거스가 말하는 게 어떤 것인지 빠르게 파악할 수 있었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>또한 아스거스가 한 마디를 덧붙였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>신께서는 과거의 산물이 와서 우리를 구원해줄 것이라 하였습니다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span><span
style='line-height:160%;color:white'>지수</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>당신을 도와주는 일이 우리가 구원받을 일이며 우리는 함께입니다</span><span lang=EN-US
style='line-height:160%;color:white'>!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아스거스가 말할 때면 묘하게 시선이 집중되었고 거대한
단상 위에 올라간 교주를 바라보는 것처럼 고개가 위로 들려졌다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 코델리아 행성에 온 지 </span><span
lang=EN-US style='line-height:160%;color:white'>80</span><span
style='line-height:160%;color:white'>일 정도밖에 되지 않았지만</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>아스거스와 센트</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>멜린은 지구 시간으로 </span><span
lang=EN-US style='line-height:160%;color:white'>1500</span><span
style='line-height:160%;color:white'>년 정도</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그들은 코델리아 행성에 대해서면 빠삭했고 금빛 씨앗을
심기에 적합한 장소 또한 파악하고 있었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>아스거스의 지시에 따라 지수의 걸음을 이끈 멜린은
금빛 씨앗을 심을 위치를 알려주었다</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수가 보기엔 주변의 다른 숲과 다를 바 없는 모습이지만
이곳의 땅은 정말이지 다른 곳보다 비옥한지</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>며칠 만에 덩굴을 내기 시작했고</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>금빛 씨앗이 포도 종의 일종이었다는 것을 알기까지
</span><span lang=EN-US style='line-height:160%;color:white'>3</span><span
style='line-height:160%;color:white'>일이 채 걸리지 않았다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이번에는 포도를 밟아서 발효시키기에 가장 알맞은
습도와 온도가 있는 곳으로 향했고</span><span lang=EN-US style='line-height:160%;color:white'>,
5</span><span style='line-height:160%;color:white'>일 만에 맛 좋은 와인이 만들어졌다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>놀라운 성과였지만 시간이 여유롭지 않은 만큼 아스거스는
쉬지 않았다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그는 매뉴얼을 전부 외우고 있는 사람처럼 다음으로는
작품 만들기를 추진했다</span><span lang=EN-US style='line-height:160%;color:white'>.</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>다만 이 작품 만들기만큼은 아스거스와 센트</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>그리고 멜린을 처음 만나 식사를 하던 날부터 지수가 생각해둔 것이 있었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>바로 오페라</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그들의 목소리는 너무나 아름다웠고 지수의 창작자로서의
마음을 연신 끌어냈다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 노래를 부르는 것도</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>춤을 추는 것도</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>랩을 하는 것도</span><span lang=EN-US style='line-height:160%;
color:white'>, </span><span style='line-height:160%;color:white'>전부 잘하고 좋아했지만 그
중에서도 가장 좋아하던 것은 작사</span><span lang=EN-US style='line-height:160%;color:white'>.
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>가장 잘하는 것은 작곡이라는 사실을 팬들은 알고
있다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 아스거스의 부드러운 목소리와 멜린의 아름다운
미성</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>그리고 센트의 무게감 있는 중저음에 어울릴만한 선율을 생각했고</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>자신들을 신자라고 소개한 그들에게 맞게 오페라를
만들어냈다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그렇게 만들어진 곡은 원래 공연할 때 사용하려던
캡슐 앞에서 노래하게 되었고 이러한 모습은 로봇 고양이의 화면에 전부 꼼꼼하게 담았다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>완창을 뒤로 하고 비디오를 뒤로 돌리며 몇 번이고
노래를 반복 재생한 지수는 친구들을 만나서 다행이라 생각했다</span><span lang=EN-US style='line-height:
160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>이 친구들이 없었다면 낯선 행성에서 혼자 어떻게
지냈을까</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>아름다운 코델리아 행성을 전부 잊어버리고 무얼 했을까</span><span
lang=EN-US style='line-height:160%;color:white'>... </span><span
style='line-height:160%;color:white'>생각만 해도 끔찍하다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>처음에는 한눈에 반한 코델리아 행성이지만 두렵고
외로움에 사무치다 보니 그저 낯선 행성으로 전락했었다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하지만 아스거스와 센트</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>멜린을 만나고 나서 함께 씨앗을 심고 재배를 한 뒤 작품도 만들고 나니까 다시금
주변의 아름다운 환경이 마음에 담기기 시작했다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그중에서 가장 다행인 것은</span><span
lang=EN-US style='line-height:160%;color:white'>, </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>친구들이 고쳐 준 통신기 덕분에 </span><span
lang=EN-US style='line-height:160%;color:white'>90</span><span
style='line-height:160%;color:white'>일 차에 가까스로 본부와 연락이 닿았다는 거다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>삐</span><span lang=EN-US
style='line-height:160%;color:white'>-</span><span style='line-height:160%;
color:white'>삐</span><span lang=EN-US style='line-height:160%;color:white'>-</span><span
style='line-height:160%;color:white'>삐</span><span lang=EN-US style='line-height:
160%;color:white'>---</span><span style='line-height:160%;color:white'>삐</span><span
lang=EN-US style='line-height:160%;color:white'>! </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그동안 연락이 없던 통신기에서 아주 짧게 일정한
소리를 내더니</span><span lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>이내 강하고 높은 소리를 내었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>캡슐 앞에서 자기 작품을 재생해 듣던 지수도</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>호숫가에서 놀고 있던 멜린과 센트도</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>거대한 돌 위에 앉아 기도하던 아스거스도</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>한 번에 듣고 캡슐 안으로 달려올 만큼 놓칠 수 없는 높이의 소리였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그중에서도 지수는 가장 격하게 반응하며 거버넌스를
불렀다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>1</span><span
style='line-height:160%;color:white'>만 명의 사람과 소통의 장은 지수에게 물 만난 고기였고 지금 당장 꼭 소개해주고
싶은 나의 인연과 영웅들이 있으니까</span><span lang=EN-US style='line-height:160%;color:white'>!
</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>거버넌스</span><span lang=EN-US
style='line-height:160%;color:white'>! </span><span style='line-height:160%;
color:white'>소개해줄 친구들이 있어요</span><span lang=EN-US style='line-height:160%;
color:white'>! </span><span style='line-height:160%;color:white'>애길 들어보니까 다른 밀코인은
행성에 있던 생물을 발견한 게 전부인가 본데</span><span lang=EN-US style='line-height:160%;
color:white'>~ </span><span style='line-height:160%;color:white'>아</span><span
lang=EN-US style='line-height:160%;color:white'>! </span><span
style='line-height:160%;color:white'>그렇다고 너무 속상해하지는 마세요</span><span lang=EN-US
style='line-height:160%;color:white'>! </span><span style='line-height:160%;
color:white'>제가 또 최초가 습관이지 않습니까</span><span lang=EN-US style='line-height:160%;
color:white'>?” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>갑자기 목청을 높이며 은근슬쩍 자신들을 까 내리는
지수의 모습에 대부분의 밀코인은 </span><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>얼마나 대단한 걸 알아 왔는지 보자</span><span
lang=EN-US style='line-height:160%;color:white'>’ </span><span
style='line-height:160%;color:white'>싶은 심정으로 주시했다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>보통 사람이라면 </span><span
lang=EN-US style='line-height:160%;color:white'>1</span><span style='line-height:
160%;color:white'>만 명이 넘는 사람이 자신을 쳐다보고 있으니 부담스러울 만도 했지만 이러한 상황은 지수에게 설렘만 줄 뿐이었다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>지수는 계속해서 뜸을 들이다가 중대 발표라도 하듯이
화면을 휙</span><span lang=EN-US style='line-height:160%;color:white'>- </span><span
style='line-height:160%;color:white'>돌렸고</span><span lang=EN-US
style='line-height:160%;color:white'>, </span><span style='line-height:160%;
color:white'>화면은 지수의 친구가 된 밀코인 세 명을 비추었다</span><span lang=EN-US
style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>“</span><span
style='line-height:160%;color:white'>보세요</span><span lang=EN-US
style='line-height:160%;color:white'>! </span><span style='line-height:160%;
color:white'>저의 코델리아 행성에서 만난 또 다른 밀코인</span><span lang=EN-US style='line-height:
160%;color:white'>...!” </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>뚝</span><span lang=EN-US
style='line-height:160%;color:white'>- </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>그러나 장대하게 시작했던 소개는 초라하기 그지없게
막을 내렸다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>본부 측에서 지수의 통신을 끊어낸 것이다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>‘</span><span
style='line-height:160%;color:white'>어라</span><span lang=EN-US
style='line-height:160%;color:white'>?’ </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>갑자기 끊긴 통신에 지수는 맥 빠지는 의성어밖에
낼 수 없었다</span><span lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>하지만 정확히는</span><span
lang=EN-US style='line-height:160%;color:white'>, </span><span
style='line-height:160%;color:white'>통신이 끊기고 그 뒤에 온 메시지에 의해 난 소리였다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>'</span><span
style='line-height:160%;color:white'>역대에 이런 케이스가 있었나</span><span lang=EN-US
style='line-height:160%;color:white'>? </span><span style='line-height:160%;
color:white'>역시 내가 최초</span><span lang=EN-US style='line-height:160%;
color:white'>!?'</span><span style='line-height:160%;color:white'>라는 생각과 함께 글자를
찬찬히 읽어보던 지수는 메시지를 수락했다</span><span lang=EN-US style='line-height:160%;
color:white'>. </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>[</span><span
style='line-height:160%;color:white'>거버넌스</span><span lang=EN-US
style='line-height:160%;color:white'>(</span><span style='line-height:160%;
color:white'>이름 설정</span><span lang=EN-US style='line-height:160%;color:white'>)
</span><span style='line-height:160%;color:white'>님이 지수 님에게 </span><span
lang=EN-US style='line-height:160%;color:white'>1:1 </span><span
style='line-height:160%;color:white'>면담을 요청하셨습니다</span><span lang=EN-US
style='line-height:160%;color:white'>.] </span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span lang=EN-US style='line-height:160%;color:white'>&nbsp;</span></p>

<p class=MsoNormal style='margin-bottom:0cm;line-height:160%;vertical-align:
baseline'><span style='line-height:160%;color:white'>처음부터 순탄하진 않았지만 궤도 안에 있던 밀코메다</span><span
lang=EN-US style='line-height:160%;color:white'>N </span><span
style='line-height:160%;color:white'>프로젝트 </span><span lang=EN-US
style='line-height:160%;color:white'>90</span><span style='line-height:160%;
color:white'>일간의 여정이</span><span lang=EN-US style='line-height:160%;color:white'>,
</span><span style='line-height:160%;color:white'>전혀 색다른 궤도에 휩쓸리기 시작했다</span><span
lang=EN-US style='line-height:160%;color:white'>. </span></p>

<p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

</div>
`
}
export default staticFullStoryData_4
